import React from 'react';
import { Card } from 'react-bootstrap';
import { WeatherStationDetailsCardProps } from '../WeatherStationTypes';
import WeatherStationDetails from './WeatherStationDetails';

/**
 * This component wraps the WeatherStationDetails component. This provides the
 * outer Card component and headers, as well as functionality for interacting
 * with the WeatherStationManager page.
 *
 * @param props Props for WeatherStationDetails and editing state from manager.
 */
export default function WeatherStationDetailsCard(
  props: WeatherStationDetailsCardProps
) {
  return (
    <Card className='m-2'>
      <Card.Header>
        <span>Station Details</span>
        <button
          type='button'
          disabled={props.wsdProps.station === undefined ? true : false}
          className='btn btn-primary btn-sm'
          style={{ float: 'right', margin: -3 }}
          onClick={() => props.setEditMode(!props.editMode)}
        >
          Edit
        </button>
      </Card.Header>
      <WeatherStationDetails station={props.wsdProps.station} />
    </Card>
  );
}
