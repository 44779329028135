/*
 * This file defines types for use in the WeatherStation related components.
 * Not to be confused with StationTypes - these are types related to the
 * station data store.
 */

import { FormikValues } from 'formik';
import { ReactNode } from 'react';
import { Station } from '../../../store/stations/StationTypes';

/**
 * Defines the props to be passed into the WeatherStationTable component.
 * This component takes a list of stations to render in a table.
 */
export interface WeatherStationTableProps {
  data: Station[];
  currentStation: Station | undefined;
  loading: boolean;
}
/**
 * Defines the props to be passed into the GlobalFilter component.
 * This component filters entries in the WeatherStationTable.
 */
export interface GlobalFilterProps {
  // preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}

/**
 * Defines the props to be passed into the WeatherStationDetails component.
 * This component takes a single station to display the details for.
 */
export interface WeatherStationDetailsProps {
  station: Station | undefined;
}

/**
 * Defines the props to be passed into the WeatherStationDetails component.
 * This component takes a single station to display the details for.
 */
export interface StationFormProps {
  formikProps: FormikValues;
  children?: ReactNode;
  removed?: Record<string, boolean>;
}

/**
 * Defines the props to be passed into the WSDCard component.
 * This takes the props for WSD, as well as fields for managing edit state.
 */
export interface WeatherStationDetailsCardProps {
  editMode: boolean;
  setEditMode: Function;
  wsdProps: WeatherStationDetailsProps;
}

/**
 * Defines the props to be passed into the StationCreateCard component.
 * This takes information to display in the form and a function to handle
 * form submission/discarding.
 */
export interface StationEditCardProps {
  station: Station;
  onSubmit(stationId: string, station: Station): any;
  onDiscard(): void;
}

/**
 * Defines the props to be passed into the StationCreateCard component.
 * This takes information to display in the form and a function to handle
 * form submission/discarding, as well as visibility state.
 */
export interface StationCreateCardProps {
  visible: boolean;
  onSubmit(station: Station): any;
  onDiscard(): void;
}

/**
 * Defines the required fields for a Station object used in the context of the
 * StationForm.
 */
export interface FormStation {
  Station_ID: string;
  // TODO: Get display order.
  Logger_Name: string;
  Region: string;
  Logger_Type: string;
  Start_Date: string;
  End_Date: string | null;
  Latitude: number;
  Longitude: number;
  Altitude: number;
  Last_Calibration: string | null;
  WMONumber: string;
  Backup_Station_ID: string;
  ET_Station: string;
  Active: boolean;
  Calc_Wetness: boolean;
  DST: boolean;
  Logger_Description: string;
  DisplayOrder: number;
  timezone: string;
  // Allows any other fields to be passed in without encapsulating in an object.
  [propName: string]: any;
}

/**
 * Returns an empty FormStation object, for use in station creation.
 */
export function getEmptyFormStation(): Station {
  return {
    Station_ID: '',
    Logger_Name: '',
    Logger_Description: '',
    Region: '',
    Logger_Type: '',
    Start_Date: '',
    End_Date: '',
    Latitude: 0,
    Longitude: 0,
    Altitude: 0,
    Last_Calibration: '',
    WMONumber: '',
    Backup_Station_ID: '',
    ET_Station: '',
    Active: 'N',
    Calc_Wetness: 'Y',
    DST: 'N',
    DisplayOrder: 1,
    metloads: [],
    status_text_override: '',
    status_id: 1,
    timezone: '',
    region_id: 0,
    region: null,
    is_utc: 'N'
  };
}

