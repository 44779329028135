import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { MetserviceStationsContext } from '../../../../store/StoreContexts';
import { getVariableName } from '../../../../store/metservice_stations/MetserviceUtils';
import VariableRow from './VariableRow';

const VariablesTable = () => {
  const metserviceStore = useContext(MetserviceStationsContext);
  const station = metserviceStore.selectedStation;
  const variables = metserviceStore.selectedStationVariables;

  const hasData = () => {
    return station && variables && station.station && station.site;
  };

  return hasData() ? (
    <Table size='sm' bordered striped hover>
      <thead>
        <tr>
          <th style={{ fontWeight: 'bold' }}>Name</th>
        </tr>
      </thead>
      <tbody>
        {variables?.map((v) => {
          const name = getVariableName(v.name);
          const description = v.description;
          const units = v.units;
          return (
            <VariableRow
              key={name + description}
              name={name}
              description={description}
              units={units}
            />
          );
        })}
      </tbody>
    </Table>
  ) : null;
};

export default observer(VariablesTable);

