import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { HilltopStationsContext } from '../../../../../store/StoreContexts';
import { MatchedStationSite } from '../../../../../store/hilltop_stations/HilltopUtils';

export const useHilltopStationsTable = (data: MatchedStationSite[]) => {
  const hilltopStore = useContext(HilltopStationsContext);

  // Filter 'data' to only contain MatchStationSites that has a site
  // that is contained in the HilltopStore 'hilltopRelevantStations' array.
  const dataRelevantStations = useMemo(
    () =>
      data
        .filter((matched) => {
          const site = matched.site;
          const relevantStations = hilltopStore.hilltopRelevantStations;
          return relevantStations.some((rs) => rs.name === site.name);
        })
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.site.name === value.site.name)
        ),
    [data, hilltopStore.hilltopRelevantStations]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Hilltop Info',
        canSort: false,
        columns: [
          {
            Header: 'Site Name',
            accessor: 'site.name',
            Cell: (props: any) => {
              return props.cell?.value;
            }
          },
          {
            Header: 'Latitude',
            accessor: 'site.latitude',
            Cell: (props: any) => {
              return props.cell?.value || '-';
            }
          },
          {
            Header: 'Longitude',
            accessor: 'site.longitude',
            Cell: (props: any) => {
              return props.cell?.value || '-';
            }
          }
        ]
      },
      {
        Header: 'HortPlus Info',
        columns: [
          {
            Header: 'Station ID',
            accessor: 'station.station_id',
            Cell: (props: any) => (
              <Link to={'/?id=' + props.cell?.value}>{props.cell?.value}</Link>
            )
          },
          {
            Header: 'Logger Name',
            accessor: 'station.station.Logger_Name'
          }
        ]
      },
      {
        Header: 'Misc',
        columns: [
          {
            Header: 'Status',
            Cell: (props: any) => {
              const original = props.cell.row.original;
              return original.site && original.station ? (
                original.station.setup === 1 ? (
                  <span className='text-success'>Setup Complete</span>
                ) : (
                  <span className='text-warning'>Setup Incomplete</span>
                )
              ) : original.site && !original.station ? (
                <span className='text-danger'>Unlinked Site</span>
              ) : !original.site && original.station ? (
                <span className='text-danger'>Invalid site_id</span>
              ) : (
                <span className='text-danger'>Invalid record</span>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const table = useTable(
    {
      columns,
      data: dataRelevantStations,
      initialState: { pageIndex: 0, pageSize: 50 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return table;
};

