import React from 'react';

interface Props {
  name: string;
  unit: string;
}

const ElementsRow = ({ name, unit }: Props) => {
  return (
    <tr style={{ position: 'relative' }}>
      <td>
        {name} {unit ? `(${unit})` : ''}
      </td>
    </tr>
  );
};

export default ElementsRow;

