import { FormikProps } from 'formik';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import { AllUsersStore } from '../allusers/AllUsersStore';
import { UnproccessableEntityError } from '../stations/errors/UnprocessableEntityError';
import UserStore from '../user/UserStore';
import { UserAuthToken, UserDetails } from '../user/UserTypes';
import * as Yup from 'yup';
import cron from 'cron-validate';

/**
 * Properties common to all Report related objects.
 */
type ReportBase = {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  cron_expression: string;
};

const CronSchema = Yup.string()
  .test({
    name: 'is-cron',
    message: 'Please enter a valid cron expression',
    test: ((value: string) => cron(value).isValid()) as Yup.TestFunction,
    exclusive: false
  })
  .required();

/**
 * Yup schema for report base object.
 */
const ReportBaseSchema = Yup.object({
  id: Yup.number().required(),
  created_at: Yup.date().nullable(),
  updated_at: Yup.date().nullable(),
  deleted_at: Yup.date().nullable(),
  cron_expression: CronSchema
});

/**
 * Subscription object returned from the MDR API.
 */
export type Subscription = ReportBase & {
  suspended_at: string | null;
  user_id: number;
  user_type: string;
  sub_type: string;
  start_date: string;
  stop_date: string;
  alternate_email: string | null;
  comments: string;
};

/**
 * Yup schema for subscription object.
 */
export const SubscriptionSchema = ReportBaseSchema.shape({
  suspended_at: Yup.string().nullable(),
  user_id: Yup.number(),
  user_type: Yup.string(),
  sub_type: Yup.string(),
  start_date: Yup.string().required('Start date is required'),
  stop_date: Yup.string().required('Start date is required'),
  alternate_email: Yup.string()
    .email('Alternative email must be a valid email address')
    .nullable(),
  comments: Yup.string()
});

/**
 * Subscription object after foreign key references are resolved.
 */
export type UserSubscription = Subscription & {
  user: UserDetails | undefined;
};

/**
 * New Subscription type. Note the omission of report base properties
 * (except for cron_expression).
 */
export type NewSubscription = Omit<
  Subscription,
  keyof Omit<ReportBase, 'cron_expression'>
>;

/**
 * Schema for creating a new subscription. Note the ommission of ID.
 */
export const NewSubscriptionSchema = Yup.object().shape({
  suspended_at: Yup.string().nullable(),
  user_id: Yup.number(),
  user_type: Yup.string(),
  sub_type: Yup.string(),
  start_date: Yup.date().required('Start date is required'),
  stop_date: Yup.date().required('Stop date is required'),
  alternate_email: Yup.string().email().nullable(),
  comments: Yup.string(),
  cron_expression: CronSchema
});

/**s
 * Report object returned from the MDR API. Represents an individual report.
 */
export type Report = ReportBase & {
  suspended_at: string | null;
  subscription_id: number;
  report_type_id: number;
  station_id: string;
  forecast_id: number;
  settings: string | null;
};

export interface AddReportFormProps {
  formikProps: FormikProps<NewReport>;
}

export interface NewReport {
  suspended_at: string;
  subscription_id: number;
  report_type_id: number | null;
  station_id: string;
  forecast_id: string;
  settings: string;
  cron_expression: string;
}

export type NewReportRequest = Omit<NewReport, 'suspended_at'> & {
  suspended_at: NewReport['suspended_at'] | null;
};

export const ReportSchema = Yup.object().shape({
  suspended_at: Yup.string().nullable(),
  subscription_id: Yup.number(),
  report_type_id: Yup.number().typeError('Please select report type'),
  station_id: Yup.string().required('Please select station'),
  forecast_id: Yup.string(),
  settings: Yup.mixed(),
  cron_expression: CronSchema
});

export interface EditReportFormProps {
  formikProps: FormikProps<EditCurrentReport>;
}

export type EditCurrentReport = ReportBase &
  NewReport & {
    report_type_name: string;
  };

export type EditReportRequest = Omit<EditCurrentReport, 'suspended_at'> & {
  suspended_at: EditCurrentReport['suspended_at'] | null;
};

/**
 * Report type object returned from the MDR API. Represents a type that an
 * individual report may be of.
 */
export type ReportType = ReportBase & {
  name: string;
  code: string;
  defaults: any;
};

/**
 * Stores the provided new subscription in the database.
 * @param userStore Current admin user UserStore object.
 * @param subscription New subscription to add.
 * @returns Returns the new subscription. Note that it is important to add this
 *          subscription back to the store, as it will contain the subscription ID.
 */
export async function createSubscription(
  userStore: UserStore,
  subscription: NewSubscription
) {
  return await fetchWithRefresh(
    userStore,
    process.env.REACT_APP_MDR_API_URL + 'api/admin/subscriptions',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer
      },
      body: JSON.stringify(subscription)
    }
  ).then((response) => {
    if (response!.ok) return response!.json();
    else if (response!.status === 422)
      throw new UnproccessableEntityError(
        response!.statusText,
        response!.json()
      );
    else throw Error(response!.statusText);
  });
}

/**
 * Returns all MDR subscriptions permitted by the provided bearer token.
 * @param userStore Current admin user UserStore.
 */
export const getSubscriptions = async (
  userStore: UserStore
): Promise<Subscription[]> => {
  // fetchWithRefresh provides automatic JWT refreshing.
  return await fetchWithRefresh(
    userStore,
    process.env.REACT_APP_MDR_API_URL + 'api/admin/subscriptions',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer
      }
    }
  ).then((response) => {
    if (response!.ok) return response!.json();
    else throw Error(response!.statusText);
  });
};
/**
 * Returns a single MDR subscription specified by the subId.
 * @param token UserAuthToken containing JWT.
 * @param subId Integer subscription ID.
 */
export const getSubscription = async (
  userStore: UserStore,
  subId: string
): Promise<Subscription> => {
  return await fetchWithRefresh(
    userStore,
    process.env.REACT_APP_MDR_API_URL + 'api/admin/subscriptions/' + subId,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer,
        service: 'metwatch'
      }
    }
  ).then((response) => {
    if (response!.ok) return response!.json();
    else throw Error(response!.statusText);
  });
};

/**
 * Updates a single subscription record with the subscription in body.
 * @param token UserAuthToken containing JWT.
 * @param subId Integer subscription ID.
 * @param subscription New subscription object to replace subId.
 */
export const updateSubscription = (
  token: UserAuthToken,
  subId: number,
  subscription: Subscription
) => {
  return fetch(
    process.env.REACT_APP_MDR_API_URL + 'api/admin/subscriptions/' + subId,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      },
      body: JSON.stringify(subscription)
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else if (response.status === 422)
      throw new UnproccessableEntityError(response.statusText, response.json());
    else throw Error(response.statusText);
  });
};

/**
 * Takes the subscriptions obtained from the MDR API and associates the foreign
 * keys with the users in the allUsers store. This will return a single object
 * with all references (e.g. user ID to email) resolved.
 * @param subscriptions Subscriptions returned from MDR API call.
 * @param allUsers AllUsersStore containing all users in auth API.
 */
export const constructUserSubscriptions = (
  subscriptions: Subscription[],
  allUsers: AllUsersStore
): UserSubscription[] => {
  return subscriptions.map((sub) => {
    return {
      ...sub,
      user: allUsers.findUser(sub.user_id, sub.user_type)
    };
  });
};

/**Create a new report.
 * @param userStore Instance of global user store to obtain token.
 * @param report New report to add.
 */

export const createReportRequest = (
  userStore: UserStore,
  report: NewReportRequest
) => {
  return fetchWithRefresh(
    userStore,
    process.env.REACT_APP_MDR_API_URL + 'api/admin/reports',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer
      },
      body: JSON.stringify(report)
    }
  ).then((response) => {
    if (response!.ok) return response!.json();
    else if (response!.status === 422)
      throw new UnproccessableEntityError(
        response!.statusText,
        response!.json()
      );
    else throw Error(response!.statusText);
  });
};

export const createEditReportRequest = (
  userStore: UserStore,
  reportId: number,
  report: EditReportRequest
) => {
  return fetchWithRefresh(
    userStore,
    process.env.REACT_APP_MDR_API_URL + 'api/admin/reports/' + reportId,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer
      },
      body: JSON.stringify(report)
    }
  ).then((response) => {
    if (response!.ok) {
      return response!.json();
    } else if (response!.status === 422)
      throw new UnproccessableEntityError(
        response!.statusText,
        response!.json()
      );
    else throw Error(response!.statusText);
  });
};

/**
 * Updates a single report record with the report in body.
 * @param userStore Instance of global user store to obtain token.
 * @param reportId Integer report ID.
 * @param report New report object to replace report at reportId.
 */
export const updateReport = async (
  userStore: UserStore,
  reportId: number,
  report: Report
) => {
  return await fetchWithRefresh(
    userStore,
    process.env.REACT_APP_MDR_API_URL + 'api/admin/reports/' + reportId,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer
      },
      body: JSON.stringify(report)
    }
  ).then((response) => {
    if (response!.ok) {
      return response!.json();
    } else if (response!.status === 422)
      throw new UnproccessableEntityError(
        response!.statusText,
        response!.json()
      );
    else throw Error(response!.statusText);
  });
};

export function getReports(userStore: UserStore): Promise<Report[]> {
  return fetchWithRefresh(
    userStore,
    process.env.REACT_APP_MDR_API_URL + 'api/admin/reports',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer,
        service: 'metwatch'
      }
    }
  ).then((response) => {
    if (response!.ok) return response!.json();
    else throw Error(response!.statusText);
  });
}

export function getReportTypes(userStore: UserStore): Promise<ReportType[]> {
  return fetchWithRefresh(
    userStore,
    process.env.REACT_APP_MDR_API_URL + 'api/admin/reporttypes',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer,
        service: 'metwatch'
      }
    }
  ).then((response) => {
    if (response!.ok) {
      return response!.json();
    } else throw Error(response!.statusText);
  });
}
