import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useState } from 'react';
import { Button, ButtonProps, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { HilltopStore } from '../../../../../store/hilltop_stations/HilltopStore';
import { UnproccessableEntityError } from '../../../../../store/stations/errors/UnprocessableEntityError';
import { HilltopStationsContext } from '../../../../../store/StoreContexts';
import { errorToast, successToast } from '../../../../utils/ToastContainer';
import MeasurementForm from './MeasurementForm/MeasurementForm';
import { FormValues, useAddMeasurement } from './utils';

type Props = ButtonProps;

const AddMeasurementModal = (buttonProps: Props) => {
  const hilltopStore = useContext<HilltopStore>(HilltopStationsContext);
  const [show, setShow] = useState(false);

  const { addMeasurement } = useAddMeasurement();

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      actions.setSubmitting(true);
      try {
        const data = await addMeasurement(values);
        if (hilltopStore.selectedStation) {
          const station = { ...hilltopStore.selectedStation };
          station.station = data;
          await hilltopStore.getMetloadsMeasurements(station);
          hilltopStore.updateStation(station);
          setShow(false);
          successToast('Successfully added the measurement');
        }
      } catch (error: any) {
        if (process.env.NODE_ENV === 'development') console.error(error);
        if (error instanceof UnproccessableEntityError) {
          const errors = error.errors.errors;
          errors.forEach((err: string) =>
            actions.setFieldError(err, errors[err][0])
          );
        } else {
          errorToast('Something went wrong adding the measurement');
        }
        actions.setSubmitting(false);
      }
    },
    [hilltopStore, addMeasurement]
  );

  const initialValues: FormValues = {
    measurement_id: '',
    variable_id: '',
    measurement_type_id: '',
    multiplier: 1,
    offset: 0
  };

  const validationSchema = Yup.object().shape({
    measurement_id: Yup.string().required(),
    variable_id: Yup.string().required(),
    measurement_type_id: Yup.string().required(),
    multiplier: Yup.number().required(),
    offset: Yup.number().required()
  });

  return (
    <>
      <Button {...buttonProps} onClick={() => setShow(true)}>
        Add Measurement
      </Button>
      <Modal
        show={
          show &&
          hilltopStore.selectedStation &&
          hilltopStore.selectedStation.site &&
          hilltopStore.selectedStation.station
            ? true
            : false
        }
        onHide={() => setShow(false)}
      >
        <Modal.Header>Add New Measurement</Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) =>
              await onSubmit(values, actions)
            }
          >
            {(formikProps) => {
              return <MeasurementForm formikProps={formikProps} />;
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default observer(AddMeasurementModal);

