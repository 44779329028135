import { makeAutoObservable, runInAction } from 'mobx';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import StationStore from '../stations/StationStore';
import UserStore from '../user/UserStore';
import {
  GetStationSensorsResponse,
  GetStationsResponse,
  Sensor
} from './model/WinetAPI';
import { WinetSensor } from './model/WinetSensor';
import { WinetStation } from './model/WinetStation';
import {
  DisplaySensor,
  MatchedWinetStation,
  Station,
  translateSensor,
  WinetData,
  WinetMetloads
} from './WinetUtils';

export class WinetStore {
  // Stores.
  userStore: UserStore;
  stationStore: StationStore;

  winetStations: WinetStation[] = [];
  winetData: WinetData = {
    stations: []
  };
  winetStationMetloads: WinetMetloads[] = [];
  winetMatchedStations: MatchedWinetStation[] = [];

  selectedStationIndex: number | undefined;

  loadingWinetStations = false;
  loadingAPIStations = false;
  loadingStationSensors = false;
  loadingWinetStationMetloads = false;
  loadingRemoveSensor = false;
  loadingAddSensor = false;
  loadingSetupWinetStation = false;

  constructor(userStore: UserStore, stationStore: StationStore) {
    makeAutoObservable(this);
    this.userStore = userStore;
    this.stationStore = stationStore;
  }

  async loadStations() {
    runInAction(() => {
      this.loadingWinetStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/winet/winet-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as WinetStation[];
      runInAction(() => {
        this.winetStations = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingWinetStations = false;
    });
  }

  async loadAPIStations() {
    runInAction(() => {
      this.loadingAPIStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/winet/api/get-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetStationsResponse;
      runInAction(() => {
        const stations: Station[] = data.dataValue.netsList.map((station) => ({
          ...station,
          sensors: [],
          latitude: 0,
          longitude: 0,
          elevation: 0
        }));
        this.winetData.stations = stations;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAPIStations = false;
    });
  }

  async loadStationSensors(stationId: number) {
    runInAction(() => {
      this.loadingStationSensors = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/winet/api/get-station-sensors?station_id=${stationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetStationSensorsResponse;
      const dataSensors = data.dataValue.netDetail;
      runInAction(() => {
        const station = this.winetData.stations.find(
          (station) => station.netId === stationId
        );
        if (station) {
          station.latitude = dataSensors[0].nodeLatitude;
          station.longitude = dataSensors[0].nodeLongitude;
          station.elevation = 0;
        }
      });
      runInAction(() => {
        const stationIndex = this.winetData.stations.findIndex(
          (station) => station.netId === stationId
        );
        let sensors: Sensor[] = [];
        dataSensors.forEach((sensorGroup) => {
          if (sensorGroup.isEnabled === 0) return;
          const sensorGroupSensors = sensorGroup.sensors.filter(
            (s) => s.isEnabled === 1
          );
          sensors.push(...sensorGroupSensors);
        });
        const displaySensors: DisplaySensor[] = sensors
          .map((s) => {
            // Set the sensor display name using the translated sensor category and type.
            const category = translateSensor(s.sensorCategory);
            const type = translateSensor(s.sensorType);
            let displayName = `${category} - ${type}`;
            if (category === type) {
              displayName = category;
            } else if (type.includes(category)) {
              displayName = `${category} - ${type.replace(category, '')}`;
            }
            return {
              ...s,
              displayName
            };
          })
          .sort((a, b) => {
            if (a.displayName < b.displayName) {
              return -1;
            }
            if (a.displayName > b.displayName) {
              return 1;
            }
            return 0;
          });
        this.winetData.stations[stationIndex].sensors = displaySensors.map(
          (s) => ({
            ...s
          })
        );
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingStationSensors = false;
    });
  }

  async loadWinetStationMetloads(winetStation: WinetStation) {
    runInAction(() => {
      this.loadingWinetStationMetloads = true;
    });

    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/winet/metloads/${winetStation.id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as WinetMetloads[];
      runInAction(() => {
        this.winetStationMetloads = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.loadingWinetStationMetloads = false;
    });
  }

  async removeSensor(
    winetStationId: number,
    winetSensorId: number,
    sensorId: string,
    measurementTypeId: string
  ) {
    runInAction(() => {
      this.loadingRemoveSensor = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/winet/remove-sensor/${winetStationId}/${winetSensorId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'DELETE',
          body: JSON.stringify({
            variable_id: sensorId,
            measurement_type_id: measurementTypeId
          })
        }
      );
      if (!response?.ok) throw Error('request failed');
      const data = (await response?.json()) as WinetStation;
      runInAction(async () => {
        const winetStationIndex = this.winetStations.findIndex(
          (hs) => hs.id === winetStationId
        );
        this.winetStations = this.winetStations.map((ms, i) => {
          if (i !== winetStationIndex) return ms;
          return data;
        });
        if (this.selectedStation) {
          const station = { ...this.selectedStation };
          station.network = data;
          await this.getMetloadsMeasurements(station);
          this.updateStation(station);
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      throw new Error(error);
    }
    runInAction(() => {
      this.loadingRemoveSensor = false;
    });
  }

  async addSensor(
    winetStationId: number,
    body: Pick<WinetSensor, 'name' | 'sensor_id'> & {
      variable_id: string;
      measurement_type_id: string;
      multiplier: number;
      offset: number;
    }
  ): Promise<WinetStation | undefined> {
    runInAction(() => {
      this.loadingAddSensor = true;
    });
    let data: WinetStation | undefined;
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/winet/add-sensor/${winetStationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(body)
        }
      );
      if (!response?.ok) return undefined;
      const winetStationData = (data = (await response?.json()) as WinetStation);
      runInAction(() => {
        const winetStationIndex = this.winetStations.findIndex(
          (hs) => hs.id === winetStationId
        );
        this.winetStations = this.winetStations.map((hs, i) => {
          if (i !== winetStationIndex) return hs;
          return winetStationData;
        });
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAddSensor = false;
    });
    return data;
  }

  async setupWinetStation(
    station: Station & {
      winet_net_id: string;
      winet_network_setup: 0 | 1;
    }
  ) {
    runInAction(() => {
      this.loadingSetupWinetStation = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/winet/setup-winet-station`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(station)
        }
      );
      const data = (await response?.json()) as WinetStation;
      runInAction(() => {
        this.stationStore.addStation(data.station);
        this.winetStations.push(data);
        const i = this.winetMatchedStations.findIndex(
          (s) => s.station?.netId.toString() === station.winet_net_id
        );
        const matchedMetserviceStation = this.winetMatchedStations[i];
        if (matchedMetserviceStation) {
          matchedMetserviceStation.network = data;
          const newMatchedMetserviceStations = [...this.winetMatchedStations];
          newMatchedMetserviceStations[i] = matchedMetserviceStation;
          const sortedMatched = this.sortMatchedWinetStations(
            newMatchedMetserviceStations
          );
          this.winetMatchedStations = sortedMatched;
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingSetupWinetStation = false;
    });
  }

  /**
   * Match stations and stations by the stations ID.
   * Each station should be assigned to one station.
   * Not all stations will be assigned a station.
   */
  getMatchedWinetStations() {
    const stations = this.winetStations;
    const networks = this.winetData.stations;

    let matched: MatchedWinetStation[] = [];
    networks.forEach((station: Station) => {
      const networkId = station.netId;
      stations.forEach((network: WinetStation) => {
        const netId = network.net_id;
        if (networkId === netId) {
          matched.push({
            station,
            network
          });
        } else {
          matched.push({
            station
          });
        }
      });
      // Handle case when a there are no stations.
      if (stations.length === 0) {
        matched.push({
          station
        });
      }
    });
    matched = this.sortMatchedWinetStations(matched);
    runInAction(() => {
      this.winetMatchedStations = matched;
    });
  }

  sortMatchedWinetStations(matched: MatchedWinetStation[]) {
    return matched
      .filter((matched) => {
        const matchedStation = matched.station;
        const stations = this.winetData.stations;
        return stations.some(
          (station) => station.netId === matchedStation.netId
        );
      })
      .sort((a, b) => {
        const idA = a.network ? a.network.station_id : undefined;
        const idB = b.network ? b.network.station_id : undefined;
        if (!idA && idB) return 1;
        else if (!idB && idA) return -1;
        else if (!idA || !idB) return 0;
        return 0;
      })
      .filter((value, index, self) => {
        return (
          index ===
          self.findIndex((t) => t.station.netId === value.station.netId)
        );
      });
  }

  updateStation(station: MatchedWinetStation) {
    const i = this.findStationIndex(station);
    if (i !== undefined) {
      const newMatchedWinetStation = [...this.winetMatchedStations];
      newMatchedWinetStation[i] = station;
      this.winetMatchedStations = this.sortMatchedWinetStations(
        newMatchedWinetStation
      );
    }
  }

  async getMetloadsMeasurements(matched: MatchedWinetStation) {
    const currentStation = matched.network;
    if (!matched || !matched.station) {
      return;
    }
    // Load site measurements.
    await this.loadStationSensors(matched?.station.netId);
    // Load given station metloads.
    if (currentStation?.station) {
      await this.loadWinetStationMetloads(currentStation);
      currentStation.metloadWithSensors = this.winetStationMetloads;
    }
  }

  deSelectStation() {
    this.selectedStationIndex = undefined;
  }

  findStationIndex(station: MatchedWinetStation): number | undefined {
    const i = this.winetMatchedStations.findIndex((s) =>
      station.station && s.station
        ? station.station.netId === s.station.netId
        : station.network && s.network
        ? station.network.station_id === s.network.station_id
        : false
    );
    return i > -1 ? i : undefined;
  }

  hasSelectedStation(): boolean {
    return this.selectedStation?.station && this.selectedStation.station
      ? true
      : false;
  }

  set setSelectedStationIndex(station: MatchedWinetStation) {
    this.selectedStationIndex = this.findStationIndex(station);
  }

  get selectedStationSensors() {
    return this.winetData.stations.find(
      (g) => g.netId === this.selectedStation?.station.netId
    )?.sensors;
  }

  get selectedStation(): MatchedWinetStation | undefined {
    return this.selectedStationIndex !== undefined &&
      this.selectedStationIndex > -1
      ? this.winetMatchedStations[this.selectedStationIndex]
      : undefined;
  }

  get loading() {
    return (
      this.loadingWinetStations ||
      this.loadingAPIStations ||
      this.loadingStationSensors ||
      this.loadingSetupWinetStation ||
      this.loadingAddSensor ||
      this.loadingRemoveSensor
    );
  }

  get loadingInitial() {
    return this.loadingWinetStations || this.loadingAPIStations;
  }
}

