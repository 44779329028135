import { observer } from 'mobx-react-lite';
import React from 'react';
import CountriesRegions from './CountriesRegions';

const CountriesRegionsManager = () => {
  return (
    <>
      <div className='page-title pb-0'>Countries & Regions Manager</div>
      <CountriesRegions />
    </>
  );
};

export default observer(CountriesRegionsManager);

