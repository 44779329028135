import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { HarvestStationsContext } from '../../../../store/StoreContexts';
import Loading from '../../../layout/Loading';
import GlobalFilter from '../../../utils/table/GlobalFilter';

const HarvestStationsTable = () => {
  const harvestStationsStore = useContext(HarvestStationsContext);
  const tableData = useMemo(
    () =>
      harvestStationsStore.matchedHarvestStations.slice().sort((a, b) => {
        const idA = a.station ? a.station.id : undefined;
        const idB = b.station ? b.station.id : undefined;
        if (!idA || !idB) return 1;
        return idA - idB;
      }),
    [harvestStationsStore.matchedHarvestStations]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Harvest Info',
        canSort: false,
        columns: [
          {
            Header: 'Site ID',
            accessor: 'site.site_id',
            Cell: (props: any) => (
              <a
                href={
                  'https://live.harvest.com/?cmd=home&sid=' + props.cell?.value
                }
                target='_blank'
                rel='noreferrer noopener'
              >
                {props.cell?.value}
              </a>
            )
          },
          {
            Header: 'Region Name',
            accessor: 'site.primary_location.region_name'
          },
          {
            Header: 'Sub Region Name',
            accessor: 'site.primary_location.subregion_name'
          },
          {
            Header: 'Location Name',
            accessor: 'site.primary_location.location_name'
          }
        ]
      },
      {
        Header: 'HortPlus Info',
        columns: [
          {
            Header: 'ID',
            accessor: 'station.id'
          },
          {
            Header: 'Station ID',
            accessor: 'station.station_id',
            Cell: (props: any) => (
              <Link to={'/?id=' + props.cell?.value}>{props.cell?.value}</Link>
            )
          },
          {
            Header: 'Logger Name',
            accessor: 'station.station.Logger_Name'
          }
        ]
      },
      {
        Header: 'Misc',
        columns: [
          {
            Header: 'Status',
            Cell: (props: any) => {
              const original = props.cell.row.original;
              return original.site && original.station ? (
                original.station.setup === 1 ? (
                  <span className='text-success'>Setup Complete</span>
                ) : (
                  <span className='text-warning'>Setup Incomplete</span>
                )
              ) : original.site && !original.station ? (
                <span className='text-danger'>Unlinked Site</span>
              ) : !original.site && original.station ? (
                <span className='text-danger'>Invalid site_id</span>
              ) : (
                <span className='text-danger'>Invalid record</span>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: tableData
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      {/* This div creates a scroll box for the table. */}
      <div style={{ height: '400px', overflowY: 'scroll', marginRight: 0 }}>
        {harvestStationsStore.loading ? (
          <Loading />
        ) : (
          <Table {...getTableProps()} size='sm' striped hover>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ borderTop: 0 }}
                    >
                      {column.render('Header') + ' '}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : column.canSort ? (
                          <FontAwesomeIcon icon={faSort} />
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => {
                      harvestStationsStore.fetchMetloadWithHarvestTrace(
                        row.original
                      );
                    }}
                    className={
                      row.original.station &&
                      harvestStationsStore.selectedStation?.station &&
                      harvestStationsStore.selectedStation?.station?.id ===
                        row.original.station?.id
                        ? 'table-primary'
                        : ''
                    }
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

export default observer(HarvestStationsTable);

