import { MetserviceStore } from '../../../../../store/metservice_stations/MetserviceStore';
import { errorToast, successToast } from '../../../../utils/ToastContainer';

/**
 * Sends an API request to remove a metload and updates the station
 * in the metserviceStore
 */
export const removeVariable = async (
  metserviceStore: MetserviceStore,
  metserviceStationId: number,
  metserviceVariableId: number,
  variableId: string,
  measurementTypeId: string
) => {
  if (window.confirm('Are you sure you want to delete this measurement?')) {
    try {
      await metserviceStore.removeVariable(
        metserviceStationId,
        metserviceVariableId,
        variableId,
        measurementTypeId
      );
      successToast('Successfully deleted the variable');
    } catch (error) {
      errorToast('Something went wrong deleting the measurement');
    }
  }
};

