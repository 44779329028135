import React, { useContext, useState } from 'react';
import { Button, Card, ButtonGroup, ListGroup, Modal } from 'react-bootstrap';
import { DetailItem } from '../../utils/DetailItem';
import { AllUsersContext } from '../../../store/StoreContexts';
import { UserDetails } from '../../../store/user/UserTypes';
import Loading from '../../layout/Loading';
import { Link } from 'react-router-dom';

type UserDetailsProps = {
  userInfo?: UserDetails;
  setEditMode: Function;
};

const TenantUserDetailsForm: React.FC<UserDetailsProps> = (props) => {
  const userStore = useContext(AllUsersContext);
  const tenant = props.userInfo!.service;

  async function logInAsUser() {
    setModalOpen(true);
    if (tenant !== undefined) {
      const jwt = await userStore.requestLogInAsUser(
        tenant,
        props.userInfo?.id
      );
      const serviceHeader = userStore.tenant;
      var start = 'https://';
      if (serviceHeader === 'ppi') {
        start = 'https://nz';
      }
      const url = start + serviceHeader + '.metwatch.nz/?token=' + jwt;
      setUserJwtLoading(url);
    }
  }

  const [modalIsOpen, setModalOpen] = useState(false);
  const [userJwt, setUserJwtLoading] = useState<string | undefined>(undefined);

  return (
    <Card>
      <Modal show={modalIsOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>
            Log in as {userStore.current?.name}({userStore.current?.service}-
            {userStore.current?.client_id})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!userJwt ? (
            <Loading />
          ) : (
            // When the user jwt has loaded, put jwt into modal & break line to prevent overflow
            <div
              style={{
                wordWrap: 'break-word'
              }}
            >
              <a target='_blank' href={userJwt}>
                {userJwt}
              </a>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Card.Header>
        User Details
        <ButtonGroup style={{ float: 'right', margin: -4 }}>
          <Button size='sm' onClick={() => props.setEditMode(true)}>
            Edit
          </Button>
        </ButtonGroup>
        <ButtonGroup
          style={{ margin: -4, padding: '0px 20px', float: 'right' }}
        >
          <Button
            type='button'
            disabled={props.userInfo?.login_type === 'emailpass' ? true : false}
            className='btn btn-secondary btn-sm'
            size='sm'
            onClick={() => logInAsUser()}
          >
            Log in as user
          </Button>
        </ButtonGroup>
      </Card.Header>
      <Card.Body>
        <ListGroup variant='flush'>
          {props.userInfo ? (
            Object.entries(props.userInfo).map(([key, value]) => (
              <DetailItem
                key={key}
                title={
                  key === 'id'
                    ? key.toUpperCase()
                    : key.charAt(0).toUpperCase() +
                      key.slice(1).replaceAll('_', ' ')
                }
                content={
                  key === 'access_id'
                    ? <Link to={`access-array-manager?id=${value}`}>
                    {`${value}`}{' '}
                  </Link>
                    : value
                }
              />
            ))
          ) : (
            <div>Please select user to view details...</div>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default TenantUserDetailsForm;

