import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs, Card } from 'react-bootstrap';
import {
  AccessArrayContext,
  AllUsersContext
} from '../../../store/StoreContexts';
import AccessArrayTable from './AccessArrayTable';
import AccessArrayDetailsCard from './details/AccessArrayDetailsCard';
import MetWatchAccessArrayDetailsCard from './details/MetWatchAccessArrayDetailsCard';
import UserDetailsCard from './details/UserDetailsCard';
import { useQuery } from '../../../utils/requests/Query';
import JSONPretty from 'react-json-pretty';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { UserDetails } from '../../../store/user/UserTypes';
import 'react-json-pretty/themes/monikai.css';
import { MetWatchAccessArray } from '../../../store/user_access_arrays/AccessArrayTypes';

function AccessArrayManager() {
  const accessStore = useContext(AccessArrayContext);
  const allUsersStore = useContext(AllUsersContext);

  const query = useQuery();

  // Here we set the current access array according to URL query string.
  accessStore.setCurrentAccessArray(
    query.get('id') !== null ? parseInt(query.get('id') as string) : undefined
  );

  useEffect(() => {
    accessStore.requestAccessUsers();
    allUsersStore.requestUsers();
  }, []);

  //Looks up and returns an array of all users who have access to the current access array
  const lookupAccessUsers = (users: UserDetails[]): UserDetails[] => {
    if (!users) return [];
    const accessId = accessStore.currentAccessArray?.id;
    var allusers: UserDetails[] = [];
    allusers = [
      ...allusers,
      ...users.filter((userTenant) => userTenant.access_id === accessId)
    ];
    return allusers;
  };

  //On every change of the currently selected access array, request the
  //metwatch access array associated with it
  useEffect(() => {
    const currentId = accessStore.currentAccessArray?.id;
    if (currentId !== undefined) {
      accessStore.requestFullUserAccessArray(currentId);
    }
  }, [accessStore.currentAccessArray?.id]);

  let parentCount = 0;

  const MetwatchArrayCardHelper = (
    mwarray: MetWatchAccessArray
  ): MetWatchAccessArray[] => {
    let mwParents: MetWatchAccessArray[] = [];
    mwParents[0] = mwarray;
    let parentArray = mwarray;
    if (parentArray?.parent!) {
      parentCount++;
      mwParents[parentCount] = parentArray.parent;
      parentArray = parentArray.parent;
      MetwatchArrayCardHelper(parentArray);
    }
    return mwParents!;
  };

  return (
    <div>
      <ArcherContainer strokeColor='blue'>
        <div className='page-title pb-0'>Access Array Manager</div>
        <Row className='pb-3'>
          <Col>
            <AccessArrayTable
              data={accessStore.accessArrays}
              currentAccessArray={accessStore.currentAccessArray}
              loading={accessStore.loading}
            />
          </Col>
        </Row>
        <Tabs defaultActiveKey='visual' className='mb-3' justify>
          <Tab eventKey='visual' title='Visual'>
            <Row>
              <Col xs={12} md={6} id='aad'>
                <AccessArrayDetailsCard
                  aadProps={{
                    currentAccessArray: accessStore.currentAccessArray
                  }}
                ></AccessArrayDetailsCard>
                <div style={{ height: '10px', margin: '10px' }}></div>
                <UserDetailsCard
                  props={lookupAccessUsers(allUsersStore.allUsers)}
                  mwdProps={{
                    currentMetWatchAccessArray: accessStore.userAccessArray
                      ?.metwatch_access!,
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                {MetwatchArrayCardHelper(
                  accessStore.userAccessArray?.metwatch_access!
                ).map((access) => {
                  return (
                    <>
                      <div>
                        <ArcherElement
                          id={access?.id.toString()}
                          relations={[
                            {
                              targetId: access?.parent?.id!.toString()!,
                              targetAnchor: 'top',
                              sourceAnchor: 'bottom',
                              style: { lineStyle: 'straight', strokeWidth: 2 },
                              order: 1
                            }
                          ]}
                        >
                          <div>
                            <MetWatchAccessArrayDetailsCard
                              mwdProps={{
                                currentMetWatchAccessArray: access,
                              }}
                            />
                          </div>
                        </ArcherElement>
                      </div>
                      <div style={{ height: '10px', margin: '10px' }}></div>
                    </>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <div style={{ height: '5px', margin: '5px' }}></div>
            </Row>
            <Row></Row>
          </Tab>
          <Tab eventKey='text' title='Text'>
            <Row>
              <Card className='m-2'>
                <JSONPretty data={accessStore.userAccessArray}></JSONPretty>
              </Card>
            </Row>
          </Tab>
        </Tabs>
      </ArcherContainer>
    </div>
  );
}

export default observer(AccessArrayManager);

