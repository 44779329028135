import React from 'react';

require('./footer.scss');

/**
 * Page footer component to be rendered on each page. Contains contact and
 * about links.
 */
export default function Footer() {
  return (
    <div className='footer'>
      <div className='footer_inner'>
        <div className='text'>
          MetWatch is made by <a href='https://www.hortplus.com'>HortPlus</a> in
          New Zealand.
        </div>
        <div className='text'>
          <a href='https://www.hortplus.com/support'>Free Support</a> |{' '}
          <a href='https://www.hortplus.com/contact'>Contact Us</a>
        </div>
      </div>
    </div>
  );
}
