import { makeAutoObservable, runInAction } from 'mobx';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import StationStore from '../stations/StationStore';
import UserStore from '../user/UserStore';
import {
  MatchedMetserviceStation,
  MetserivceData,
  MetserviceMetloads,
  Station,
  metserviceStationIds
} from './MetserviceUtils';
import {
  GetStationVariablesResponse,
  GetStationsResponse
} from './models/MetserviceAPI';
import { MetserviceStation } from './models/MetserviceStation';
import { MetserviceVariable } from './models/MetserviceVariables';

export class MetserviceStore {
  // Stores.
  userStore: UserStore;
  stationStore: StationStore;

  metserviceStations: MetserviceStation[] = [];
  metserviceData: MetserivceData = {
    stations: []
  };
  metserviceStationMetloads: MetserviceMetloads[] = [];
  metserviceMatchedStations: MatchedMetserviceStation[] = [];

  selectedStationIndex: number | undefined;

  loadingMetserviceStations = false;
  loadingAPIStations = false;
  loadingStationVariables = false;
  loadingMetserviceStationMetloads = false;
  loadingRemoveVariable = false;
  loadingAddVariable = false;
  loadingSetupMetserviceStation = false;

  constructor(userStore: UserStore, stationStore: StationStore) {
    makeAutoObservable(this);
    this.userStore = userStore;
    this.stationStore = stationStore;
  }

  async loadStations() {
    runInAction(() => {
      this.loadingMetserviceStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metservice/metservice-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as MetserviceStation[];
      runInAction(() => {
        this.metserviceStations = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingMetserviceStations = false;
    });
  }

  async loadAPIStations() {
    runInAction(() => {
      this.loadingAPIStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metservice/api/get-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetStationsResponse;
      runInAction(() => {
        const stations: Station[] = data.results.map(
          ({ WMOID, name, latitude, longitude, elevation }) => ({
            id: WMOID,
            name,
            latitude,
            longitude,
            elevation,
            variables: []
          })
        );
        this.metserviceData.stations = stations.filter((s) =>
          metserviceStationIds.includes(s.id)
        );
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAPIStations = false;
    });
  }

  async loadStationVariables(stationId: number) {
    runInAction(() => {
      this.loadingStationVariables = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metservice/api/get-station-variables?station_id=${stationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetStationVariablesResponse;
      runInAction(() => {
        const stationIndex = this.metserviceData.stations.findIndex(
          (station) => station.id === stationId
        );
        this.metserviceData.stations[stationIndex].variables = Object.keys(
          data.variables
        ).map((name) => ({
          name,
          description: data.variables[name].attributes.long_name,
          units: data.variables[name].attributes.units
        }));
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingStationVariables = false;
    });
  }

  async loadMetseriveStationMetloads(metserviceStation: MetserviceStation) {
    runInAction(() => {
      this.loadingMetserviceStationMetloads = true;
    });

    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/metservice/metloads/${metserviceStation.id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as MetserviceMetloads[];
      runInAction(() => {
        this.metserviceStationMetloads = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.loadingMetserviceStationMetloads = false;
    });
  }

  async removeVariable(
    metserviceStationId: number,
    metserviceVariableId: number,
    variableId: string,
    measurementTypeId: string
  ) {
    runInAction(() => {
      this.loadingRemoveVariable = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metservice/remove-variable/${metserviceStationId}/${metserviceVariableId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'DELETE',
          body: JSON.stringify({
            variable_id: variableId,
            measurement_type_id: measurementTypeId
          })
        }
      );
      if (!response?.ok) throw Error('request failed');
      const data = (await response?.json()) as MetserviceStation;
      runInAction(async () => {
        const metserviceStationIndex = this.metserviceStations.findIndex(
          (hs) => hs.id === metserviceStationId
        );
        this.metserviceStations = this.metserviceStations.map((ms, i) => {
          if (i !== metserviceStationIndex) return ms;
          return data;
        });
        if (this.selectedStation) {
          const station = { ...this.selectedStation };
          station.site = data;
          await this.getMetloadsMeasurements(station);
          this.updateStation(station);
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      throw new Error(error);
    }
    runInAction(() => {
      this.loadingRemoveVariable = false;
    });
  }

  async addVariable(
    metserviceStationId: number,
    body: Pick<MetserviceVariable, 'name'> & {
      variable_id: string;
      measurement_type_id: string;
      multiplier: number;
      offset: number;
    }
  ): Promise<MetserviceStation | undefined> {
    runInAction(() => {
      this.loadingAddVariable = true;
    });
    let data: MetserviceStation | undefined;
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metservice/add-variable/${metserviceStationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(body)
        }
      );
      if (!response?.ok) return undefined;
      const metserviceStationData = (data = (await response?.json()) as MetserviceStation);
      runInAction(() => {
        const metserviceStationIndex = this.metserviceStations.findIndex(
          (hs) => hs.id === metserviceStationId
        );
        this.metserviceStations = this.metserviceStations.map((hs, i) => {
          if (i !== metserviceStationIndex) return hs;
          return metserviceStationData;
        });
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAddVariable = false;
    });
    return data;
  }

  async setupMetserviceStation(
    station: Station & {
      metservice_wmo_id: string;
      metservice_site_setup: 0 | 1;
    }
  ) {
    runInAction(() => {
      this.loadingSetupMetserviceStation = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metservice/setup-metservice-station`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(station)
        }
      );
      const data = (await response?.json()) as MetserviceStation;
      runInAction(() => {
        this.stationStore.addStation(data.station);
        this.metserviceStations.push(data);
        const i = this.metserviceMatchedStations.findIndex(
          (s) => s.station?.id.toString() === station.metservice_wmo_id
        );
        const matchedMetserviceStation = this.metserviceMatchedStations[i];
        if (matchedMetserviceStation) {
          matchedMetserviceStation.site = data;
          const newMatchedMetserviceStations = [
            ...this.metserviceMatchedStations
          ];
          newMatchedMetserviceStations[i] = matchedMetserviceStation;
          const sortedMatched = this.sortMatchedMetserviceStations(
            newMatchedMetserviceStations
          );
          this.metserviceMatchedStations = sortedMatched;
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingSetupMetserviceStation = false;
    });
  }

  updateStation(station: MatchedMetserviceStation) {
    const i = this.findStationIndex(station);
    if (i !== undefined) {
      const newMatchedMetserviceStation = [...this.metserviceMatchedStations];
      newMatchedMetserviceStation[i] = station;
      this.metserviceMatchedStations = this.sortMatchedMetserviceStations(
        newMatchedMetserviceStation
      );
    }
  }

  sortMatchedMetserviceStations(matched: MatchedMetserviceStation[]) {
    return matched
      .filter((matched) => {
        const matchedStation = matched.station;
        const stations = this.metserviceData.stations;
        return stations.some((station) => station.id === matchedStation.id);
      })
      .sort((a, b) => {
        const idA = a.site ? a.site.station_id : undefined;
        const idB = b.site ? b.site.station_id : undefined;
        if (!idA && idB) return 1;
        else if (!idB && idA) return -1;
        else if (!idA || !idB) return 0;
        return 0;
      })
      .filter((value, index, self) => {
        return (
          index === self.findIndex((t) => t.station.id === value.station.id)
        );
      });
  }

  async getMetloadsMeasurements(matched: MatchedMetserviceStation) {
    const currentStation = matched.site;
    if (!matched || !matched.station) {
      return;
    }
    // Load site measurements.
    await this.loadStationVariables(matched?.station.id);
    // Load given station metloads.
    if (currentStation?.station) {
      await this.loadMetseriveStationMetloads(currentStation);
      currentStation.metloadWithVariables = this.metserviceStationMetloads;
    }
  }

  /**
   * Match stations and stations by the stations ID.
   * Each station should be assigned to one station.
   * Not all stations will be assigned a station.
   */
  getMatchedMetserviceStations() {
    const stations = this.metserviceStations;
    const sites = this.metserviceData.stations;

    let matched: MatchedMetserviceStation[] = [];
    sites.forEach((station: Station) => {
      const siteId = station.id;
      stations.forEach((site: MetserviceStation) => {
        const wmoId = site.wmo_id;
        if (siteId === wmoId) {
          matched.push({
            station,
            site
          });
        } else {
          matched.push({
            station
          });
        }
      });
      // Handle case when a there are no stations.
      if (stations.length === 0) {
        matched.push({
          station
        });
      }
    });
    matched = this.sortMatchedMetserviceStations(matched);
    runInAction(() => {
      this.metserviceMatchedStations = matched;
    });
  }

  deSelectStation() {
    this.selectedStationIndex = undefined;
  }

  findStationIndex(station: MatchedMetserviceStation): number | undefined {
    const i = this.metserviceMatchedStations.findIndex((s) =>
      station.station && s.station
        ? station.station.id === s.station.id
        : station.site && s.site
        ? station.site.station_id === s.site.station_id
        : false
    );
    return i > -1 ? i : undefined;
  }

  hasSelectedStation(): boolean {
    return this.selectedStation?.station && this.selectedStation.station
      ? true
      : false;
  }

  set setSelectedStationIndex(station: MatchedMetserviceStation) {
    this.selectedStationIndex = this.findStationIndex(station);
  }

  get selectedStationVariables() {
    return this.metserviceData.stations
      .find((g) => g.id === this.selectedStation?.station.id)
      ?.variables.filter((v) => v.name !== 'obs_timestamp')
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  }

  get selectedStation(): MatchedMetserviceStation | undefined {
    return this.selectedStationIndex !== undefined &&
      this.selectedStationIndex > -1
      ? this.metserviceMatchedStations[this.selectedStationIndex]
      : undefined;
  }

  get loading() {
    return (
      this.loadingMetserviceStations ||
      this.loadingAPIStations ||
      this.loadingStationVariables ||
      this.loadingSetupMetserviceStation ||
      this.loadingAddVariable ||
      this.loadingRemoveVariable
    );
  }

  get loadingInitial() {
    return this.loadingMetserviceStations || this.loadingAPIStations;
  }
}

