import { HaloStore } from '../../../../store/halo_stations/HaloStore';
import { MetloadWithProperty } from '../../../../store/halo_stations/models/HaloStation';

/**
 * Returns the metloads which are different to those in the haloStore
 * selected station.
 *
 * Only compares the following keys because they are the only ones that can
 * be changed:
 *  - MetVar_ID
 *  - MetMesTp_ID
 *  - Multiplier
 *  - Offset
 */
export const filterChangedMetloads = (
  haloStore: HaloStore,
  metloads: MetloadWithProperty[]
) => {
  const initialMetloads =
    haloStore.selectedStation?.station?.metloadWithProperties;

  if (!initialMetloads) return metloads;

  return metloads.filter((metload) => {
    const initialMetload = initialMetloads.find((m) => m.id === metload.id);
    return (
      !initialMetload ||
      metload.MetVar_ID !== initialMetload.MetVar_ID ||
      metload.MetMesTp_ID !== initialMetload.MetMesTp_ID ||
      metload.Multiplier !== initialMetload.Multiplier ||
      metload.Offset !== initialMetload.Offset
    );
  });
};

