import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, ListGroup, Modal } from 'react-bootstrap';
import { MetWatchAccessArrayDetailsCardProps } from '../AccessArrayTypes';
import { DetailItem as AccessArrayDetailItem } from '../../../utils/DetailItem';
import EditMetWatchAccessArray from '../forms/EditMetWatchAccessArray';
import {
  ForecastProviders,
  MetWatchNetwork
} from '../../../../store/user_access_arrays/AccessArrayTypes';
import { Station } from '../../../../store/stations/StationTypes';
import JSONPretty from 'react-json-pretty';
import { StationContext } from '../../../../store/StoreContexts';
import { Link } from 'react-router-dom';
import 'react-json-pretty/themes/monikai.css';

/**
 * This component wraps the MetWatchAccessArrayDetails component. This provides the
 * outer Card component and headers, as well as functionality for interacting
 * with the AccessArrayManager page.
 *
 * @param props Props for WeatherStationDetails and editing state from manager.
 */
export default function MetWatchAccessArrayDetailsCard(
  props: MetWatchAccessArrayDetailsCardProps
) {
  const [editMode, setEditMode] = React.useState(false);

  //When true, the show more button is active and can be used to see more stations
  const [showLess, setShowLess] = useState(true);

  const stations = useContext(StationContext);

  /**
   * requests all stations with networks
   */
  useEffect(() => {
    stations.requestStationNetworks();
  }, []);

  //Outputs in format of NetworkName(NetworkID), for example KVH Integrated Tools(4)
  const generateNetworkString = (networks: MetWatchNetwork[]): string => {
    if (!networks) {
      return 'None';
    }
    const id = networks?.map(
      (nw) => String(nw.label + '(' + nw.network_id) + '), '
    );
    if (id.length === 0) {
      return 'None';
    }
    const reduced = id.reduce((a, b) => a + b).slice(0, -2);
    return reduced;
  };

  const generateNetworkIdNumber = (networks: MetWatchNetwork[]): number[] => {
    if (!networks) throw new Error('No netoworks supplied');
    const out = networks.map((nw) => Number(nw.network_id));
    if (out.length === 0) {
      return [];
    }
    return out;
  };

  const generateProvidersIDString = (
    providers: ForecastProviders[]
  ): string => {
    if (!providers) {
      return 'None';
    }
    const out = providers
      .map((mwp) => String(mwp.name))
      .reduce((a, b) => a + ', ' + b, '')
      .slice(2);
    return out;
  };

  const generateStationIdString = (
    stations: Station[]
  ): JSX.Element[] | JSX.Element => {
    if (!stations) return [];
    const nw_id = generateNetworkIdNumber(
      props.mwdProps.currentMetWatchAccessArray?.networks_access!
    );
    let stationids: Station[] = [];
    //iterate over all stations for each network id supplied, and get the stations associated with those networks into the station ids array to be made into links
    nw_id.map((currentNwId) => {
      stationids = [
        ...stationids,
        ...stations.filter((nw) =>
          nw.networks?.some((nw2) => nw2.network_id === currentNwId)
        )
      ];
    });

    const stationLinks = stationids.map((nw) => (
      <Link to={'/?id=' + nw.Station_ID}>{nw.Station_ID}, </Link>
    ));

    const text = stationLinks;

    if (stationLinks.length < 30) {
      return stationLinks;
    }

    return (
      <div>
        {showLess ? stationLinks.slice(0, 30) : text}
        <button
          className='float-right mb-2 m-1 btn-sm btn btn-secondary'
          onClick={() => setShowLess(!showLess)}
        >
          {showLess ? 'Show more' : 'Show less'}
        </button>
      </div>
    );
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  return (
    <Card className='m-2'>
      {editMode ? (
        <EditMetWatchAccessArray
          metwatchAccessArrayDetails={props.mwdProps.currentMetWatchAccessArray}
          setEditMode={setEditMode}
        />
      ) : (
        <>
          <Card.Header>
            MetWatch Access Array Index Details
            <ButtonGroup style={{ float: 'right', margin: -4 }}>
              <Button size='sm' onClick={() => setEditMode(true)}>
                Edit
              </Button>
            </ButtonGroup>
          </Card.Header>
          <Card.Body>
            <ListGroup variant='flush'>
              {props.mwdProps.currentMetWatchAccessArray ? (
                <>
                  <Card.Title>
                    {props.mwdProps.currentMetWatchAccessArray?.name}
                  </Card.Title>

                  <AccessArrayDetailItem
                    title='Access ID'
                    content={props.mwdProps.currentMetWatchAccessArray?.id}
                  />
                  <AccessArrayDetailItem
                    title='Name'
                    content={props.mwdProps.currentMetWatchAccessArray?.name}
                  />
                  <AccessArrayDetailItem
                    title='Description'
                    content={
                      props.mwdProps.currentMetWatchAccessArray?.description
                    }
                  />
                  <AccessArrayDetailItem
                    title='Forecast Hours'
                    content={
                      props.mwdProps.currentMetWatchAccessArray?.forecast_hours
                    }
                  />
                  <AccessArrayDetailItem
                    title='Route ID'
                    content={
                      props.mwdProps.currentMetWatchAccessArray?.route_id
                    }
                  />
                  <AccessArrayDetailItem
                    title='Parent ID'
                    content={
                      props.mwdProps.currentMetWatchAccessArray?.parent_id
                    }
                  />
                  <AccessArrayDetailItem
                    title='Networks'
                    content={generateNetworkString(
                      props.mwdProps.currentMetWatchAccessArray
                        ?.networks_access!
                    )}
                  />
                  <AccessArrayDetailItem
                    title='Station IDs'
                    content={generateStationIdString(stations.stations)}
                  />
                  <AccessArrayDetailItem
                    title='Provider Access Array'
                    content={generateProvidersIDString(
                      props.mwdProps.currentMetWatchAccessArray
                        ?.providers_access!
                    )}
                  />
                  {props.mwdProps.currentMetWatchAccessArray?.routes_access
                    ?.route_array ? (
                    <div>
                      <button
                        onClick={() => setIsOpen(true)}
                        className='float-right mb-2 m-1 btn-sm btn btn-primary'
                      >
                        Route Array
                      </button>
                      <Modal
                        onHide={() => setIsOpen(false)}
                        show={modalIsOpen}
                        style={{
                          width: '305px',
                          left: '40%',
                          top: '30%'
                        }}
                      >
                        <AccessArrayDetailItem
                          title='Route Array'
                          content={
                            <JSONPretty
                              data={
                                props.mwdProps.currentMetWatchAccessArray
                                  ?.routes_access?.route_array
                              }
                            ></JSONPretty>
                          }
                        />
                      </Modal>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </>
              ) : (
                <div>Please select an access array to view details...</div>
              )}
            </ListGroup>
          </Card.Body>
        </>
      )}
    </Card>
  );
}

