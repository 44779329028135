import { FormikValues } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import NetworksSelector from './NetworksSelector';

/**
 * This component creates a form for modifying subscriptions.
 * @param props Formikbag
 */
const EditMetWatchAccessArrayForm: React.FC<FormikValues> = (props) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors
  } = props;

  return (
    <>
      <Form>
        {/* Editable Fields */}
        <Form.Group controlId='id'>
          <Form.Label>MW Access ID</Form.Label>
          <Form.Control
            disabled
            type='text'
            name='id'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.id}
            isInvalid={touched.id && errors.id}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.id}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            name='name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            isInvalid={touched.name && errors.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='description'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as='textarea'
            style={{ height: '100px' }}
            name='description'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            isInvalid={touched.description && errors.description}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.description}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='networks'></Form.Group>
          <Form.Label>Networks</Form.Label>
          <NetworksSelector 
            name='Networks'
            value={values.network_id} 
            isInvalid={touched.network_id && errors.network_id} />
        <Form.Group controlId='route_id'>
          <Form.Label>Route ID</Form.Label>
          <Form.Control
            type='text'
            name='route_id'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.route_id}
            isInvalid={touched.route_id && errors.route_id}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.route_id}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='parent_id'>
          <Form.Label>Parent ID</Form.Label>
          <Form.Control
            type='text'
            name='parent_id'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.parent_id}
            isInvalid={touched.parent_id && errors.parent_id}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.parent_id}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};

export default EditMetWatchAccessArrayForm;
