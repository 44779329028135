import { FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import {
  MetloadContext,
  MetrisStationsContext
} from '../../../../../store/StoreContexts';
import VariableSelect, {
  Option
} from '../../../../utils/selects/VariableSelect';

interface Props {
  formikProps: FormikProps<{
    point: { name: string; node_id: string; units: string };
    variable_id: string;
    measurement_type_id: string;
    multiplier: number;
    offset: number;
  }>;
}

const MeasurementForm = ({ formikProps }: Props) => {
  const metrisStore = useContext(MetrisStationsContext);
  const metloadStore = useContext(MetloadContext);

  return (
    <Form onSubmit={formikProps.handleSubmit} noValidate>
      <Form.Group>
        <Form.Label>Measurement</Form.Label>
        <Select
          options={metrisStore.selectedStationPoints?.map((point) => {
            const label = `${point.name} (${point.units})`;
            return {
              label,
              value: JSON.stringify({
                name: point.name,
                node_id: point.nodeId,
                units: point.units
              })
            };
          })}
          onChange={(option) => {
            const value = JSON.parse(option?.value || '{}') as {
              name: string;
              node_id: string;
              units: string;
            };
            formikProps.setFieldValue('point', {
              name: value.name || '',
              node_id: value.node_id.toString() || '',
              units: value.units || ''
            });
          }}
        />
        {formikProps.errors.point ? (
          <div className='react-select-feedback-invalid'>
            {formikProps.errors.point}
          </div>
        ) : null}
      </Form.Group>

      <Form.Group>
        <Form.Label>Variable</Form.Label>
        <VariableSelect
          onChange={(option) => {
            formikProps.setFieldValue(
              'variable_id',
              option ? (option as Option).value : ''
            );
          }}
        />
        {formikProps.errors.variable_id ? (
          <div className='react-select-feedback-invalid'>
            {formikProps.errors.variable_id}
          </div>
        ) : null}
      </Form.Group>

      <Form.Group>
        <Form.Label>Measurement Type</Form.Label>
        <Select
          options={metloadStore.measurementTypes.map((measurementType) => {
            return {
              value: measurementType.Meas_Type_ID,
              label: measurementType.Meas_Type_Name
            };
          })}
          onChange={(option) => {
            formikProps.setFieldValue(
              'measurement_type_id',
              option ? option.value : ''
            );
          }}
        />
        {formikProps.errors.measurement_type_id ? (
          <div className='react-select-feedback-invalid'>
            {formikProps.errors.measurement_type_id}
          </div>
        ) : null}
      </Form.Group>

      <Form.Group>
        <Form.Label>Multiplier</Form.Label>
        <Form.Control
          type='number'
          min={0}
          step={0.01}
          name='multiplier'
          onChange={formikProps.handleChange}
          value={formikProps.values.multiplier}
          isInvalid={!!formikProps.errors.multiplier}
        />
        <Form.Control.Feedback type='invalid'>
          {formikProps.errors.multiplier}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label>Offset</Form.Label>
        <Form.Control
          type='number'
          step={0.01}
          name='offset'
          onChange={formikProps.handleChange}
          value={formikProps.values.offset}
          isInvalid={!!formikProps.errors.offset}
        />
        <Form.Control.Feedback type='invalid'>
          {formikProps.errors.offset}
        </Form.Control.Feedback>
      </Form.Group>

      <Button type='submit' variant='outline-primary'>
        Submit
      </Button>
    </Form>
  );
};

export default observer(MeasurementForm);

