import { MetrisStore } from '../../../../../store/metris_stations/MetrisStore';
import { errorToast, successToast } from '../../../../utils/ToastContainer';

/**
 * Sends an API request to remove a metload and updates the station
 * in the metrisStore
 */
export const removePoint = async (
  metrisStore: MetrisStore,
  metrisStationId: number,
  metrisPointId: number,
  variableId: string,
  measurementTypeId: string
) => {
  if (window.confirm('Are you sure you want to delete this measurement?')) {
    try {
      await metrisStore.removePoint(
        metrisStationId,
        metrisPointId,
        variableId,
        measurementTypeId
      );
      successToast('Successfully deleted the point');
    } catch (error) {
      errorToast('Something went wrong deleting the measurement');
    }
  }
};

