import React from 'react';
import Container from 'react-bootstrap/Container';
import Footer from './Footer';
import Header from './Header';
import { LayoutProps } from './LayoutTypes';
import NavBar from './nav/NavBar';

require('./layout.scss');

/**
 * Layout component designed to wrap whole pages. Generally, this will be used
 * to wrap the children of Route components.
 *
 * Includes components common to every page (header, footer). Also includes the
 * navbar if the hasNavBar prop is set to true.
 * @param props Child components.
 */
export default function Layout(props: LayoutProps) {
  if (props.hasNavBar) {
    return (
      <>
        <Header />
        <NavBar />
        <Container
          className='d-flex-column justify-content-center rounded pb-3'
          id='page-content'
        >
          {props.children}
        </Container>
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header />
      <Container id='page-content'>{props.children}</Container>
      <Footer />
    </>
  );
}
