import React from 'react';
import { Slide, toast, ToastContainer as Toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const successToast = (text: string, id?: string) => {
  toast(text, {
    type: toast.TYPE.SUCCESS,
    className: 'text-center bg-success p-3 m-2 shadow-sm',
    toastId: id
  });
};

export const errorToast = (text: string, id?: string) => {
  toast(text, {
    type: toast.TYPE.ERROR,
    className: 'text-center bg-danger p-3 m-2 shadow-sm',
    toastId: id
  });
};

/**
 * Sets out the functionality, styling and limitations of the toasts.
 * By implementing this component in App.tsx it allows for a global toast system.
 *
 */
export const ToastContainer = () => (
  <Toastify
    position='top-right'
    limit={3}
    autoClose={5000}
    hideProgressBar={true}
    closeOnClick
    pauseOnFocusLoss={false}
    draggable={false}
    pauseOnHover={false}
    transition={Slide}
    closeButton={false}
    toastClassName={() => 'text-center bg-white text-dark p-3 m-2 shadow-sm'}
  />
);
