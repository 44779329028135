import React from 'react';
import {
  HarvestApiGraphList,
  MatchedHarvestStation
} from '../utils/HarvestTypes';
import { Table } from 'react-bootstrap';

interface HarvestTracesTableProps {
  station: MatchedHarvestStation;
  graphList: HarvestApiGraphList;
}

const HarvestTracesTable = (props: HarvestTracesTableProps) => {
  return props.station.site && props.station.station ? (
    <Table size='sm' bordered striped hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(props.graphList.graphs).flatMap((graph) =>
          Object.values(graph.traces).map((trace) => (
            <tr key={trace.trace_id}>
              <td>{trace.trace_id}</td>
              <td>{trace.trace_name}</td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  ) : (
    <>Invalid Station</>
  );
};

export default HarvestTracesTable;
