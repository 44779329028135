import React from 'react';
import { Spinner } from 'react-bootstrap';

const CenterSpinner = () => {
  const spinnerParentStyle: any = {
    position: 'relative',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  };
  const spinnerStyle: any = {
    margin: '100px'
  };

  return (
    <div style={spinnerParentStyle}>
      <Spinner animation='border' variant='primary' style={spinnerStyle} />
    </div>
  );
};

export default CenterSpinner;
