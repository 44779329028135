import React, { useContext, useState } from 'react';
import { DetailItem as AccessArrayDetailItem } from '../../../utils/DetailItem';
import { Card, ListGroup } from 'react-bootstrap';
import { UserDetails } from '../../../../store/user/UserTypes';
import { UserDetailsCardProps } from '../AccessArrayTypes';
import { Link } from 'react-router-dom';

/**
 * This component wraps the UserDetails component. This provides the
 * outer Card component and headers, as well as functionality for interacting
 * with the AccessArrayManager page.
 *
 * @param props Props for UserDetails and editing state from manager.
 */

export default function UserDetailsCard(props: UserDetailsCardProps) {
  //When true, the show more button is active and can be used to see more users
  const [showLess, setShowLess] = useState(true);

  const usersWithAccess = (users: UserDetails[]): any => {
    if (!users) return 'None';
    const accessUsers = users.map((user) => (
      <Link to={`/user-manager?tenant=${user.service}&id=${user.client_id}`}>
        {`${user.name} (${user.service}-${user.client_id})`},{' '}
      </Link>
    ));
    const text = accessUsers;

    if (accessUsers.length < 30) {
      return accessUsers;
    }

    return (
      <div>
        {showLess ? accessUsers.slice(0, 30) : text}
        <button
          className='float-right mb-2 m-1 btn-sm btn btn-secondary'
          onClick={() => setShowLess(!showLess)}
        >
          {showLess ? 'Show more' : 'Show less'}
        </button>
      </div>
    );
  };

  return (
    <Card className='m-2'>
      <Card.Header>
        <span>User Details</span>
      </Card.Header>
      <Card.Body>
        <ListGroup variant='flush'>
          {props.mwdProps?.currentMetWatchAccessArray ? (
            <>
              <Card.Title>Users:</Card.Title>
              <AccessArrayDetailItem
                title='Users with this access:'
                content={usersWithAccess(props.props)}
              />
            </>
          ) : (
            <div>Please select an access array to view details...</div>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

