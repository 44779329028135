import { UserAuthToken } from '../user/UserTypes';
import { UnproccessableEntityError } from '../stations/errors/UnprocessableEntityError';
import * as All from './AccessArrayTypes';
import {
  Access,
  MetWatchAccessArray
} from './AccessArrayTypes';

/**
 * Returns all access entries permitted by the provided bearer token.
 * @param token UserAuthToken containing JWT.
 */
export async function getAccessIDs(token: UserAuthToken): Promise<All.Access[]> {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/access',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      }
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else throw Error(response.statusText);
  });
}

/**
 * Returns all MetWatch access entries permitted by the provided bearer token.
 * @param token UserAuthToken containing JWT.
 */
export async function getUserAccessArray(token: UserAuthToken, id: number | undefined): Promise<All.accessArray> {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/access/' + id + '?&with[]=metwatchAccess&with[]=networksAccess',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      }
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else throw Error(response.statusText);
  });
}

/**
 * Returns all access entries permitted by the provided bearer token.
 * @param token UserAuthToken containing JWT.
 */
export async function getMetwatchAccessIDs(token: UserAuthToken): Promise<All.MetWatchAccessArray[]> {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/access/metwatch',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      }
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else throw Error(response.statusText);
  });
}

/**
 * Updates a single station record with the station in body.
 * @param userStore Current admin user UserStore.
 */
export async function updateAccessArray(
  token: UserAuthToken,
  accessArray: Access
) {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/access/' + accessArray.id,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer,
      },
      body: JSON.stringify(accessArray)
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else if (response.status === 422)
      throw new UnproccessableEntityError(response.statusText, response.json());
    else throw Error(response.statusText);
  });
}

/**
 * Updates a single station record with the station in body.
 * @param userStore Current admin user UserStore.
 */
export async function updateMetWatchAccessArray(
  token: UserAuthToken,
  metWatchAccessArray: MetWatchAccessArray
) {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/access/metwatch/' + metWatchAccessArray.id,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer,
      },
      body: JSON.stringify(metWatchAccessArray)
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else if (response.status === 422)
      throw new UnproccessableEntityError(response.statusText, response.json());
    else throw Error(response.statusText);
  });
}