import * as Yup from 'yup';
import { Metload } from '../metload/MetloadTypes';
import { Region } from '../regions/RegionsTypes';
import { MetWatchNetwork } from './../user_access_arrays/AccessArrayTypes';
/**
 * Defines the required fields for a Station object. Note that additional
 * fields may be added if required.
 */
export interface Station {
  Station_ID: string;
  DisplayOrder: number;
  Logger_Name: string;
  Region: string;
  Logger_Type: string;
  Start_Date: string;
  End_Date: string | null;
  Latitude: number;
  Longitude: number;
  Altitude: number;
  Last_Calibration: string | null;
  WMONumber: string;
  Backup_Station_ID: string;
  ET_Station: string;
  Active: 'Y' | 'N';
  Calc_Wetness: 'Y' | 'N';
  DST: 'Y' | 'N';
  Logger_Description: string;
  metloads: Metload[];
  networks?: Array<MetWatchNetwork>;
  status_id: number;
  status_text_override?: string;
  timezone: string;
  region_id: number;
  region: Region | null;
  is_utc: 'Y' | 'N';

  // Allows any other fields to be passed in without encapsulating in an object.
  [propName: string]: any;
}

export interface Status {
  id: number;
  severity: number;
  description: string;
  status_text: string | null;
}

/**
 * Yup validation for Stations. Can be used anywhere where station object
 * validation is required.
 */
export const StationValidationSchema = Yup.object().shape({
  Station_ID: Yup.string()
    .length(3, 'Station ID must be 3 characters.')
    .uppercase('Station ID must be uppercase.')
    .strict(true)
    .required('Station ID cannot be blank.'),
  DisplayOrder: Yup.number()
    .integer()
    .typeError('Please enter a valid integer.')
    .positive('Please enter a positive integer.'),
  Logger_Name: Yup.string()
    .required('Station name cannot be blank.')
    .max(60, 'Station name must be less than or equal to 60 characters.'),
  Logger_Description: Yup.string().required(
    'Station description cannot be blank'
  ),
  Region: Yup.string().required('Region cannot be blank.'),
  Logger_Type: Yup.string().required('Station type cannot be blank.'),
  Start_Date: Yup.date()
    .notRequired()
    .nullable()
    .typeError('Please enter a valid date.'),
  End_Date: Yup.date()
    .notRequired()
    .nullable()
    .typeError('Please enter a valid date.'),
  Latitude: Yup.number().typeError('Please enter a valid number.'),
  Longitude: Yup.number().typeError('Please enter a valid number.'),
  Altitude: Yup.number().typeError('Please enter a valid number.'),
  Last_Calibration: Yup.date()
    .notRequired()
    .nullable()
    .typeError('Please enter a valid date.'),
  WMONumber: Yup.string().typeError('Please enter a valid string.').nullable(),
  Backup_Station_ID: Yup.string()
    .length(3, 'Station ID must be 3 characters.')
    .uppercase()
    .nullable()
    .notRequired(),
  ET_Station: Yup.string()
    .length(3, 'Station ID must be 3 characters.')
    .uppercase()
    .nullable()
    .notRequired(),
  status_id: Yup.number().typeError('Please enter a valid number.').nullable(),
  status_text_override: Yup.string().nullable(),
  timezone: Yup.string()
    .oneOf(
      Intl.supportedValuesOf('timeZone'),
      'The value entered is not a valid timezone.'
    )
    .required('A timezone must be provided'),
  region_id: Yup.number().typeError('Please enter a valid number.'),
  is_utc: Yup.mixed().oneOf(['Y', 'N'])
});

