import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { BomStationsContext } from '../../../store/StoreContexts';
import { BomFileId, fileIdMap } from '../../../store/bom_stations/BomUtils';
import { useQuery } from '../../../utils/requests/Query';

const RegionSelect = () => {
  const bomStore = useContext(BomStationsContext);
  const [selectedFileId, setSelectedFileId] = useState<BomFileId | undefined>(
    bomStore.selectedFileId
  );
  const query = useQuery();
  const history = useHistory();

  const options: {
    value: BomFileId;
    label: string;
  }[] = Array.from(fileIdMap, ([value, label]) => ({ value, label }));

  const defaultValue = options.filter(
    (option) => option.value === selectedFileId
  );

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Select
              placeholder='Select a Region'
              defaultValue={defaultValue}
              options={options}
              onChange={(option) => {
                setSelectedFileId(option!.value);
              }}
            />
          </Col>
          <Col xs={2} sm={4} md={2} className='pl-0'>
            <Button
              onClick={(e) => {
                e.preventDefault();
                bomStore.setSelectedFileId = selectedFileId;
                if (selectedFileId) {
                  query.set('region', selectedFileId);
                  history.push({
                    search: query.toString()
                  });
                }
              }}
            >
              Select
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default observer(RegionSelect);

