import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Select from 'react-select';
import { AllUsersContext } from '../../../store/StoreContexts';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '../../layout/Loading';

const TenantSelector = () => {
  const allUsersStore = useContext(AllUsersContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    allUsersStore.requestUsers();
  }, [allUsersStore.user.userState]);

  let tenantsList: string[] = [];

  /**
   * Iterates over all users to add each individual tenant to an array
   */
  for (let i = 0; i < allUsersStore.allUsers.length; i++) {
    const tenantService = allUsersStore.allUsers[i].service;
    if (
      tenantService !== undefined &&
      tenantService.includes('metwatch') &&
      !tenantsList.includes(allUsersStore.allUsers[i].service!)
    ) {
      tenantsList.push(tenantService);
    }
  }

  const tenantFromUrl = new URLSearchParams(location.search).get('tenant');
  const tenant = tenantFromUrl?.substring(0, tenantFromUrl.indexOf('_'));

  return (
    <>
      <Form>
        <Form.Group>
          {!allUsersStore.loading ? (
            <Select
              options={tenantsList.map((tenant) => {
                const tenantName = tenant;

                return {
                  value: tenant,
                  label: tenantName
                };
              })}
              defaultValue={
                tenant !== undefined
                  ? {
                      value: tenant,
                      label: tenant
                    }
                  : { value: '', label: 'Select tenant...' }
              }
              onChange={(select: any) => {
                history.push({ search: '?tenant=' + select.value });
              }}
            />
          ) : (
            <Loading />
          )}
        </Form.Group>
      </Form>
    </>
  );
};

export default observer(TenantSelector);

