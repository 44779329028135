import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { StationFormProps } from '../WeatherStationTypes';
import StatusSelector from './StatusSelector';

/**
 * This component creates a form for modifying station objects.
 *
 * See StationStatusEditCard for use.
 *
 * @param props Station state and callback function for updating station.
 */
export default function StationStatusForm(props: StationFormProps) {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors
  } = props.formikProps;
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
        <Form.Group controlId='status_id'>
        <Form.Label>Status ID</Form.Label>
        <StatusSelector
          name='status_id'
          value={values.status_id}
          isInvalid={touched.status_id && errors.status_id}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.status_id}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='status_text_override'>
        <Form.Label>Status Text Override</Form.Label>
        <Form.Control
          as='textarea'
          name='status_text_override'
          className='form-control'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.status_text_override}
          isInvalid={
            touched.status_text_override && errors.status_text_override
          }
        />
        <Form.Control.Feedback type='invalid'>
          {errors.status_text_override}
        </Form.Control.Feedback>
        {props.children}
      </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

