import React, { useContext, useMemo } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import { StationContext } from '../../../../store/StoreContexts';
import { Region } from '../../../../store/regions/RegionsTypes';
import { StationFormProps } from '../WeatherStationTypes';
import StationSelector from './StationSelector';

/**
 * This component creates a form for modifying station objects.
 *
 * See WeatherStationEditCard for use.
 *
 * @param props Station state and callback function for updating station.
 */
export default function StationForm(props: StationFormProps) {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;
  const countries = regionStore.countries;
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors
  } = props.formikProps;

  const region = useMemo(() => regionStore.findRegion(values.region_id), [
    regionStore,
    values.region_id
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId='Active'>
        <Row className='mb-2'>
          <Col xs={8}>
            <Card.Title className='mb-0'>{values.Logger_Name}</Card.Title>
          </Col>
          {props.removed?.Active ? (
            <></>
          ) : (
            <>
              <Col>
                <Form.Check
                  className='float-right'
                  type='switch'
                  checked={values.Active === 'Y'}
                  label='Active'
                  name='Active'
                  onChange={(e) => {
                    e.target.value = e.target.value === 'Y' ? 'N' : 'Y';
                    values.Active = e.target.value;
                    // Call handleBlur to update the toggle switch. Not ideal, but
                    // calling handleChange will mess up the value.
                    handleBlur(e);
                  }}
                  onBlur={handleBlur}
                  value={values.Active}
                  isInvalid={touched.Active && errors.Active}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.Active}
                </Form.Control.Feedback>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col xs={7}></Col>
          <Col>
            <Form.Group controlId='is_utc'>
              <Form.Check
                checked={values.is_utc === 'Y'}
                type='switch'
                label='UTC Station?'
                name='is_utc'
                className='float-right'
                onChange={(e) => {
                  e.target.value = e.target.value === 'Y' ? 'N' : 'Y';
                  values.is_utc = e.target.value;
                  // Call handleBlur to update the toggle switch. Not ideal, but
                  // calling handleChange will mess up the value.
                  handleBlur(e);
                }}
                onBlur={handleBlur}
                value={values.is_utc}
                isInvalid={touched.is_utc && errors.is_utc}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.is_utc}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group controlId='Station_ID'>
            <Form.Label>Station ID</Form.Label>
            <Form.Control
              type='text'
              name='Station_ID'
              className='form-control'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Station_ID}
              isInvalid={touched.Station_ID && errors.Station_ID}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.Station_ID}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId='DisplayOrder'>
            <Form.Label>Display Order</Form.Label>
            <Form.Control
              type='number'
              name='DisplayOrder'
              className='form-control'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.DisplayOrder}
              isInvalid={touched.DisplayOrder && errors.DisplayOrder}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.DisplayOrder}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId='Logger_Name'>
        <Form.Label>Station Name</Form.Label>
        <Form.Control
          type='text'
          name='Logger_Name'
          className='form-control'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.Logger_Name}
          isInvalid={touched.Logger_Name && errors.Logger_Name}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.Logger_Name}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='region_id'>
        <Form.Label>Region</Form.Label>
        <Select
          options={countries.map((c) => {
            return {
              label: c.name,
              options: c.regions.map((r) => {
                return {
                  label: r.name,
                  value: r
                };
              })
            };
          })}
          onChange={(option: SingleValue<{ label: string; value: Region }>) => {
            const region = option?.value;
            if (!region) return;
            setFieldValue('region_id', region.id);
            setFieldValue('Region', region.name);
            setFieldValue('timezone', region.timezone);
          }}
          defaultValue={
            region
              ? {
                  label: region.name,
                  value: region
                }
              : null
          }
        />
      </Form.Group>
      <Form.Group controlId='Logger_Type'>
        {props.removed?.Logger_Type ? (
          <></>
        ) : (
          <>
            <Form.Label>Station Type</Form.Label>
            <Form.Control
              type='text'
              name='Logger_Type'
              className='form-control'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Logger_Type}
              isInvalid={touched.Logger_Type && errors.Logger_Type}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.Logger_Type}
            </Form.Control.Feedback>
          </>
        )}
      </Form.Group>

      <Form.Group controlId='Logger_Description'>
        <Form.Label>Logger Description</Form.Label>
        <Form.Control
          as='textarea'
          name='Logger_Description'
          className='form-control'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.Logger_Description}
          isInvalid={touched.Logger_Description && errors.Logger_Description}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.Logger_Description}
        </Form.Control.Feedback>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group controlId='Calc_Wetness'>
            <Form.Check
              checked={values.Calc_Wetness === 'Y'}
              type='switch'
              label='Calculate Wetness?'
              name='Calc_Wetness'
              className='m-2'
              onChange={(e) => {
                e.target.value = e.target.value === 'Y' ? 'N' : 'Y';
                values.Calc_Wetness = e.target.value;
                // Call handleBlur to update the toggle switch. Not ideal, but
                // calling handleChange will mess up the value.
                handleBlur(e);
              }}
              onBlur={handleBlur}
              value={values.Calc_Wetness}
              isInvalid={touched.Calc_Wetness && errors.Calc_Wetness}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.Calc_Wetness}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Control.Feedback type='invalid'>
              {errors.DST}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <hr />

      <Form.Group controlId='WMONumber'>
        {props.removed?.WMONumber ? (
          <></>
        ) : (
          <>
            <Form.Label>Forecast Site (WMO Number)</Form.Label>
            <Form.Control
              type='text'
              name='WMONumber'
              className='form-control'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.WMONumber}
              isInvalid={touched.WMONumber && errors.WMONumber}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.WMONumber}
            </Form.Control.Feedback>
          </>
        )}
      </Form.Group>

      <Form.Group controlId='Backup_Station_ID'>
        <Form.Label>Backup Station</Form.Label>
        <StationSelector
          name='Backup_Station_ID'
          value={values.Backup_Station_ID}
          isInvalid={touched.Backup_Station_ID && errors.Backup_Station_ID}
        />

        <Form.Control.Feedback type='invalid'>
          {errors.Backup_Station_ID}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='ET_Station'>
        <Form.Label>ET Station</Form.Label>
        <StationSelector
          name='ET_Station'
          value={values.ET_Station}
          isInvalid={touched.ET_Station && errors.ET_Station}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.ET_Station}
        </Form.Control.Feedback>
      </Form.Group>

      <hr />

      <Row>
        <Col>
          <Form.Group controlId='Start_Date'>
            <Form.Label>Start Date (Installation)</Form.Label>
            <Form.Control
              type='date'
              name='Start_Date'
              className='form-control'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Start_Date}
              isInvalid={touched.Start_Date && errors.Start_Date}
              placeholder='YYYY-MM-DD'
            />
            <Form.Control.Feedback type='invalid'>
              {errors.Start_Date}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId='End_Date'>
            <Form.Label>End Date (Decommission)</Form.Label>
            <Form.Control
              type='date'
              name='End_Date'
              className='form-control'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.End_Date}
              isInvalid={touched.End_Date && errors.End_Date}
              placeholder='YYYY-MM-DD'
            />
            <Form.Control.Feedback type='invalid'>
              {errors.End_Date}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId='Last_Calibration'>
        <Form.Label>Last Calibrated</Form.Label>
        <Form.Control
          type='date'
          name='Last_Calibration'
          className='form-control'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.Last_Calibration}
          isInvalid={touched.Last_Calibration && errors.Last_Calibration}
          placeholder='YYYY-MM-DD'
        />
        <Form.Control.Feedback type='invalid'>
          {errors.Last_Calibration}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='DST'>
        <Form.Check
          checked={values.DST === 'Y'}
          type='switch'
          label='Adjusts for DST?'
          name='DST'
          onChange={(e) => {
            e.target.value = e.target.value === 'Y' ? 'N' : 'Y';
            values.DST = e.target.value;
            // Call handleBlur to update the toggle switch. Not ideal, but
            // calling handleChange will mess up the value.
            handleBlur(e);
          }}
          onBlur={handleBlur}
          value={values.DST}
          isInvalid={touched.DST && errors.DST}
        />
      </Form.Group>

      <hr />

      <Row>
        <Col>
          <Form.Group controlId='Latitude'>
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              type='text'
              name='Latitude'
              className='form-control'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Latitude}
              isInvalid={touched.Latitude && errors.Latitude}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.Latitude}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId='Longitude'>
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              type='text'
              name='Longitude'
              className='form-control'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Longitude}
              isInvalid={touched.Longitude && errors.Longitude}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.Longitude}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId='Altitude'>
        <Form.Label>Altitude (m)</Form.Label>
        <Form.Control
          type='text'
          name='Altitude'
          className='form-control'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.Altitude}
          isInvalid={touched.Altitude && errors.Altitude}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.Altitude}
        </Form.Control.Feedback>
      </Form.Group>

      <hr />

      <Form.Group controlId='Comments'>
        <Form.Label>Comments</Form.Label>
        <Form.Control
          as='textarea'
          name='Comments'
          className='form-control'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.Comments}
          isInvalid={touched.Comments && errors.Comments}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.Comments}
        </Form.Control.Feedback>
        {props.children}
      </Form.Group>
    </Form>
  );
}

