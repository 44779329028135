import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  ListGroup,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import { DetailItem as StationDetailItem } from '../../../utils/DetailItem';
import { WeatherStationDetailsProps } from '../WeatherStationTypes';
import { MetWatchNetwork } from '../../../../store/user_access_arrays/AccessArrayTypes';
import { Station } from '../../../../store/stations/StationTypes';
import { StationContext } from '../../../../store/StoreContexts';
import { Link } from 'react-router-dom';

/**
 * Displays the details of a single weather station's network that it belongs to in a card body.
 *
 * Note that this component is returned within a Card.Body component - not a
 * Card component. This is because it is designed to be used within an existing
 * card, not on its own. See WeatherStationDetailsCard for use.
 *
 * @param props Station data.
 */
export default function StationNetworksDetails(
  props: WeatherStationDetailsProps
) {
  const stations = useContext(StationContext);

  //Outputs in format of NetworkName(NetworkID), for example KVH Integrated Tools(4)
  const generateNetworkString = (networks: MetWatchNetwork[]): string[] => {
    if (!networks) {
      return [];
    }
    const id = networks?.map(
      (nw) => String(nw.label + '(' + nw.network_id) + '), '
    );
    if (id.length === 0) {
      return [];
    }
    return id;
  };

  //Takes in the network id of the selected stations, and returns a list of all stations 
  //in that network, and makes backlinks to all of them
  const generateStationIdString = (
    stations: Station[],
    network: string
  ): JSX.Element[] | JSX.Element => {
    if (!stations) return [];
    const nw_id = Number(network);
    let stationids: Station[] = [];
    //iterate over all stations for each network id supplied, and get the stations associated with those networks into the station ids array to be made into links
    stationids = [
      ...stationids,
      ...stations.filter((nw) =>
        nw.networks?.some((nw2) => nw2.network_id === nw_id)
      )
    ];

    const stationLinks = stationids.map((nw) => (
      <Link to={'/?id=' + nw.Station_ID}>{nw.Station_ID}, </Link>
    ));
      return stationLinks;
  };

  //Splits the string given which is in format NetworkName(NetworkID) to just being the id
  const networkId = (networkString: string): JSX.Element => {
    let network = networkString.split('(');
    let networkId = network[1].split(')');
    return (
      //Popover when clicked displays all the stations belonging to the selected network
      <OverlayTrigger
        placement='top'
        trigger='click'
        overlay={
          <Popover id='popover-basic'>
            <Popover.Title as='h3'>Stations in the network</Popover.Title>

            <Popover.Content>
              {generateStationIdString(stations.stations, networkId[0])}
            </Popover.Content>
          </Popover>
        }
      >
        <Button variant='info' size="sm"> {networkId[0]} </Button>
      </OverlayTrigger>
    );
  };

  //Splits the string given which is in format NetworkName(NetworkID) to just being the name
  const networkName = (networkString: string): any => {
    let network = networkString.split('(');
    return network[0];
  };

  return (
    <Card.Body>
      <ListGroup variant='flush'>
        {props.station ? (
          Object.entries(
            generateNetworkString(props.station?.networks!)
          ).map(([key, value]) => (
            <StationDetailItem
              key={key}
              title={networkId(value?.toString())}
              content={networkName(value?.toString())}
              titleWidth={2}
              contentWidth={10}
            />
          ))
        ) : (
          <div>Please select a station to view details...</div>
        )}
      </ListGroup>
    </Card.Body>
  );
}

