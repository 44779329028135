import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, ButtonProps, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as Yup from 'yup';
import {
  HarvestStationsContext,
  MetloadContext,
  UserContext
} from '../../../../store/StoreContexts';
import { UnproccessableEntityError } from '../../../../store/stations/errors/UnprocessableEntityError';
import { fetchWithRefresh } from '../../../../utils/requests/RequestsHelpers';
import LoadingOverlay from '../../../utils/LoadingOverlay';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import VariableSelect from '../../../utils/selects/VariableSelect';

/**
 * Renders a button which opens a modal to add a new harvest station
 * measurement into the system
 */
const AddMeasurementModal: React.FC<ButtonProps> = (props) => {
  const userStore = useContext(UserContext);
  const harvestStationsStore = useContext(HarvestStationsContext);
  const metloadStore = useContext(MetloadContext);

  const { children, ...otherProps } = props;
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <Button {...otherProps} onClick={() => setShow(true)}>
        {children}
      </Button>
      <Modal
        show={
          show &&
          harvestStationsStore.selectedStation &&
          harvestStationsStore.selectedStation.site &&
          harvestStationsStore.selectedStation.station
            ? true
            : false
        }
        onHide={() => setShow(false)}
      >
        <Modal.Header>Add New Measurement</Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              trace_id: '',
              variable_id: '',
              measurement_type_id: '',
              multiplier: 1,
              offset: 0
            }}
            validationSchema={Yup.object().shape({
              trace_id: Yup.string().required(),
              variable_id: Yup.string().required(),
              measurement_type_id: Yup.string().required(),
              multiplier: Yup.number().required(),
              offset: Yup.number().required()
            })}
            onSubmit={(values, actions) => {
              fetchWithRefresh(
                userStore,
                `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/harvest/add-measurement/${harvestStationsStore.selectedStation?.station?.id}`,
                {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${userStore.user?.userToken.jwt_bearer}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values)
                }
              )
                .then((res) => {
                  if (res?.ok) return res.json();
                  if (res?.status === 422)
                    throw new UnproccessableEntityError(
                      res.statusText,
                      res.json()
                    );
                  else throw new Error(res?.statusText);
                })
                .then(async (data) => {
                  // Returns the harvest station model so update the selected station
                  // in the harvestStationsStore with the new data
                  if (harvestStationsStore.selectedStation) {
                    const station = { ...harvestStationsStore.selectedStation };
                    station.station = data;
                    await harvestStationsStore.fetchMetloadWithHarvestTrace(
                      station
                    );
                    harvestStationsStore.updateStation(station);
                    setShow(false);
                    successToast('Successfully added the measurement');
                  }
                })
                .catch((err) => {
                  if (process.env.NODE_ENV === 'development')
                    console.error(err);

                  if (err instanceof UnproccessableEntityError) {
                    err.errors.then((data: any) => {
                      for (var err in data.errors) {
                        actions.setFieldError(err, data.errors[err][0]);
                      }
                    });
                  } else {
                    errorToast('Something went wrong adding the measurement');
                  }
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit} noValidate>
                {formikProps.isSubmitting ? <LoadingOverlay /> : null}
                <Form.Group>
                  <Form.Label>Trace</Form.Label>
                  <Select
                    options={
                      harvestStationsStore.selectedStationGraphList
                        ? Object.values(
                            harvestStationsStore.selectedStationGraphList.graphs
                          ).flatMap((graph) =>
                            Object.values(graph.traces).map((trace) => {
                              return {
                                value: trace.trace_id,
                                label: trace.trace_name
                              };
                            })
                          )
                        : []
                    }
                    onChange={(option) => {
                      formikProps.setFieldValue(
                        'trace_id',
                        option ? option.value : ''
                      );
                    }}
                  />
                  {formikProps.errors.trace_id ? (
                    <div className='react-select-feedback-invalid'>
                      {formikProps.errors.trace_id}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Variable</Form.Label>
                  <VariableSelect
                    onChange={(option: any) => {
                      formikProps.setFieldValue(
                        'variable_id',
                        option ? option.value : ''
                      );
                    }}
                  />
                  {formikProps.errors.variable_id ? (
                    <div className='react-select-feedback-invalid'>
                      {formikProps.errors.variable_id}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Measurement Type</Form.Label>
                  <Select
                    options={metloadStore.measurementTypes.map(
                      (measurementType) => {
                        return {
                          value: measurementType.Meas_Type_ID,
                          label: measurementType.Meas_Type_Name
                        };
                      }
                    )}
                    onChange={(option) => {
                      formikProps.setFieldValue(
                        'measurement_type_id',
                        option ? option.value : ''
                      );
                    }}
                  />
                  {formikProps.errors.measurement_type_id ? (
                    <div className='react-select-feedback-invalid'>
                      {formikProps.errors.measurement_type_id}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Multiplier</Form.Label>
                  <Form.Control
                    type='number'
                    min={0}
                    step={0.01}
                    name='multiplier'
                    onChange={formikProps.handleChange}
                    value={formikProps.values.multiplier}
                    isInvalid={!!formikProps.errors.multiplier}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formikProps.errors.multiplier}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type='number'
                    step={0.01}
                    name='offset'
                    onChange={formikProps.handleChange}
                    value={formikProps.values.offset}
                    isInvalid={!!formikProps.errors.offset}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formikProps.errors.offset}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button type='submit' variant='outline-primary'>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default observer(AddMeasurementModal);

