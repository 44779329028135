import { FormikValues } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import CronExpressionSelector from './CronExpressionSelector';

/**
 * This component creates a form for modifying subscriptions.
 * @param props Formikbag
 */
const EditSubscriptionForm: React.FC<FormikValues> = (props) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue
  } = props;

  return (
    <>
      <Form>
        <Row>
          <Col className='col-12 col-md-6'>
            <Form.Group controlId='id'>
              <Form.Label>Subscription ID</Form.Label>
              <Form.Control
                disabled
                type='text'
                name='id'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.id}
                isInvalid={touched.id && errors.id}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.id}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className='col-12 col-md-6'>
            <Form.Group controlId='user_id'>
              <Form.Label>User ID</Form.Label>
              <Form.Control
                disabled
                type='text'
                name='user_id'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user_id}
                isInvalid={touched.user_id && errors.user_id}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.user_id}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className='col-12 col-md-6'>
            <Form.Group controlId='user_type'>
              <Form.Label>Subscription Domain</Form.Label>
              <Form.Control
                disabled
                type='text'
                name='user_type'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user_type}
                isInvalid={touched.user_type && errors.user_type}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.user_type}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className='col-12 col-md-6'>
            <Form.Group controlId='user_name'>
              <Form.Label>User Name</Form.Label>
              <Form.Control
                disabled
                type='text'
                name='user_name'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user?.name}
                isInvalid={touched.user_name && errors.user_name}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.user_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className='col-12 col-md-6'>
            <Form.Group controlId='sub_type'>
              <Form.Label>Subscription Type</Form.Label>
              <Form.Control
                disabled
                type='text'
                name='sub_type'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sub_type}
                isInvalid={touched.sub_type && errors.sub_type}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.sub_type}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className='col-12 col-md-6'>
            <Form.Group controlId='user_email'>
              <Form.Label>User Email</Form.Label>
              <Form.Control
                disabled
                type='text'
                name='user_email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user?.email}
                isInvalid={touched.user_email && errors.user_email}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.user_email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        {/* Editable Fields */}
        <Form.Group controlId='start_date'>
          <Form.Label>Subscription Start Date</Form.Label>
          <Form.Control
            type='date'
            name='start_date'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.start_date}
            isInvalid={touched.start_date && errors.start_date}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.start_date}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='stop_date'>
          <Form.Label>Subscription Stop Date</Form.Label>
          <Form.Control
            type='date'
            name='stop_date'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.stop_date}
            isInvalid={touched.stop_date && errors.stop_date}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.stop_date}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='cron_expression'>
          <Form.Label>User Send Time</Form.Label>
          <CronExpressionSelector
            name='cron_expression'
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            value={values.cron_expression}
            isInvalid={
              (touched.cron_expression ?? false) &&
              (errors.cron_expression ?? '') !== ''
            }
          />
          <Form.Control.Feedback type='invalid'>
            {errors.cron_expression}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='alternate_email'>
          <Form.Label>Alternative Email (Optional)</Form.Label>
          <Form.Control
            type='text'
            name='alternate_email'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.alternate_email}
            isInvalid={touched.alternate_email && errors.alternate_email}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.alternate_email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='comments'>
          <Form.Label>User Comments</Form.Label>
          <Form.Control
            as='textarea'
            style={{ height: '100px' }}
            name='comments'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.comments}
            isInvalid={touched.comments && errors.comments}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.comments}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='suspended_at'>
          <Row>
            <Col>
              <Form.Label>
                User Subscription Status (Override all reports)
              </Form.Label>
              <Form.Check
                type='switch'
                name='suspended_at'
                label={values.suspended_at ? 'Suspended' : 'Active'}
                onBlur={handleBlur}
                onChange={() => {
                  setFieldValue(
                    'suspended_at',
                    values.suspended_at
                      ? null
                      : DateTime.now().toFormat('yyyy-MM-dd hh:mm:ss')
                  );
                }}
                defaultChecked={values.suspended_at ? false : true}
              />
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
};

export default EditSubscriptionForm;
