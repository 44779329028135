import { HilltopSite } from '../hilltop_stations/models/HilltopSite';
import { HilltopStation } from '../hilltop_stations/models/HilltopStation';

export interface MatchedStationSite {
  site: HilltopSite;
  station?: HilltopStation;
}

export interface RelevantStation {
  Station_ID: string;
  name: string;
  latitude: number;
  longitude: number;
}

export const HilltopProviderArray = [
  'horizons',
  'gdc',
  'hbrc',
  'orc',
  'src'
] as const;
export type HilltopProvider = typeof HilltopProviderArray[number];
export function isHilltopProvider(str: string): str is HilltopProvider {
  return HilltopProviderArray.includes(str as HilltopProvider);
}

export const providerMap = new Map<HilltopProvider, string>([
  ['horizons', 'Horizons'],
  ['gdc', 'Gisborne District Council'],
  ['hbrc', 'HBRC'],
  ['orc', 'ORC'],
  ['src', 'Environment Southland']
]);

