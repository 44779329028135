import { FormikValues } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { UserDetails } from '../../../../store/user/UserTypes';
import CronExpressionSelector from './CronExpressionSelector';

const NewSubscriptionForm: React.FC<FormikValues & { user: UserDetails }> = (
  props
) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue
  } = props;

  return (
    <Form>
      <Row>
        <Col className='col-12 col-md-6'>
          <Form.Group controlId='user_id'>
            <Form.Label>User ID</Form.Label>
            <Form.Control
              disabled
              type='text'
              name='user_id'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.user_id}
              isInvalid={touched.user_id && errors.user_id}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.user_id}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col className='col-12 col-md-6'>
          <Form.Group>
            <Form.Label>Subscription Domain</Form.Label>
            <Form.Control
              disabled
              type='text'
              onChange={handleChange}
              onBlur={handleBlur}
              value={props.user.service}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='col-12 col-md-6'>
          <Form.Group controlId='user_name'>
            <Form.Label>User Name</Form.Label>
            <Form.Control
              disabled
              type='text'
              name='user_name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={props.user.name}
            />
          </Form.Group>
        </Col>
        <Col className='col-12 col-md-6'>
          <Form.Group controlId='user_email'>
            <Form.Label>User Email</Form.Label>
            <Form.Control
              disabled
              type='text'
              name='user_email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={props.user.email}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='col-12 col-md-6'>
          <Form.Group controlId='sub_type'>
            <Form.Label>Subscription Type</Form.Label>
            <Form.Control
              disabled
              type='text'
              name='sub_type'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.sub_type}
              isInvalid={touched.sub_type && errors.sub_type}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.sub_type}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <hr />
      {/* Editable Fields */}
      <Form.Group controlId='start_date'>
        <Form.Label>Subscription Start Date</Form.Label>
        <Form.Control
          type='date'
          name='start_date'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.start_date}
          isInvalid={touched.start_date && errors.start_date}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.start_date}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='stop_date'>
        <Form.Label>Subscription Stop Date</Form.Label>
        <Form.Control
          type='date'
          name='stop_date'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.stop_date}
          isInvalid={touched.stop_date && errors.stop_date}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.stop_date}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='cron_expression'>
        <Form.Label>User Send Time</Form.Label>
        <CronExpressionSelector
          name='cron_expression'
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          value={values.cron_expression}
          isInvalid={touched.cron_expression && errors.cron_expression}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.cron_expression}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='alternate_email'>
        <Form.Label>Alternative Email</Form.Label>
        <Form.Control
          type='text'
          name='alternate_email'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.alternate_email}
          isInvalid={touched.alternate_email && errors.alternate_email}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.alternate_email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='comments'>
        <Form.Label>User Comments</Form.Label>
        <Form.Control
          as='textarea'
          style={{ height: '100px' }}
          className='form-control'
          name='comments'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.comments}
          isInvalid={touched.comments && errors.comments}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.comments}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default NewSubscriptionForm;
