import React from 'react';
import { Button, ButtonGroup, Card, ListGroup } from 'react-bootstrap';
import { Subscription } from '../../../../store/reports/MdrUtils';
import { DetailItem } from '../../../utils/DetailItem';

type ReportDetailsProps = {
  subscription: Subscription;
  setEditMode: Function;
};

const SubscriptionDetails: React.FC<ReportDetailsProps> = (props) => {
  return (
    <Card>
      <Card.Header>
        Subscription Details
        <ButtonGroup style={{ float: 'right', margin: -4 }}>
          <Button size='sm' onClick={() => props.setEditMode(true)}>
            Edit
          </Button>
        </ButtonGroup>
      </Card.Header>
      <Card.Body>
        {props.subscription ? (
          <ListGroup variant='flush'>
            <DetailItem title='ID' content={props.subscription.id} />
            <DetailItem
              title='Created'
              content={
                props.subscription.created_at === null
                  ? ''
                  : props.subscription.created_at
                      .replace(/T/, ' ')
                      .substr(0, 10)
                      .split('-')
                      .reverse()
                      .join('/')
              }
            />
            <DetailItem
              title='Updated'
              content={
                props.subscription.updated_at === null
                  ? ''
                  : props.subscription.updated_at
                      .replace(/T/, ' ')
                      .substr(0, 10)
                      .split('-')
                      .reverse()
                      .join('/')
              }
            />
            <DetailItem
              title='Deleted'
              content={props.subscription.deleted_at}
            />
            <DetailItem title='User ID' content={props.subscription.user_id} />
            <DetailItem
              title='User Type'
              content={props.subscription.user_type}
            />
            <DetailItem
              title='Subscription Type'
              content={props.subscription.sub_type}
            />
            <DetailItem
              title='Start Date'
              content={props.subscription.start_date
                .split('-')
                .reverse()
                .join('/')}
            />
            <DetailItem
              title='Stop Date'
              content={props.subscription.stop_date
                .split('-')
                .reverse()
                .join('/')}
            />
            <DetailItem
              title='Cron Expression'
              content={props.subscription.cron_expression}
            />
            <DetailItem
              title='Alternate Email'
              content={props.subscription.alternate_email}
            />
            <DetailItem
              title='User Comments'
              content={props.subscription.comments}
            />
            <DetailItem
              title='Suspended'
              content={props.subscription.suspended_at}
            />
          </ListGroup>
        ) : (
          <>Select a subscription to view details...</>
        )}
      </Card.Body>
    </Card>
  );
};
export default SubscriptionDetails;
