import { FieldArray, Formik, Form as FormikForm } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import { HaloStationsContext } from '../../../../store/StoreContexts';
import { MatchedStationGroup } from '../../../../store/halo_stations/HaloUtils';
import { MetloadWithProperty } from '../../../../store/halo_stations/models/HaloStation';
import { fetchWithRefresh } from '../../../../utils/requests/RequestsHelpers';
import LoadingOverlay from '../../../utils/LoadingOverlay';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import AddMeasurementModal from '../AddMeasurementModel/AddMeasurementModal';
import TableRow from './TableRow/TableRow';
import { filterChangedMetloads } from './utils';

const MetloadsTable = () => {
  const haloStore = useContext(HaloStationsContext);
  const userStore = haloStore.userStore;
  const [loading, setLoading] = useState<boolean>(false);

  return haloStore.hasSelectedStation() ? (
    <Formik
      enableReinitialize
      initialValues={{
        metloads:
          haloStore.selectedStation?.station?.metloadWithProperties ?? []
      }}
      onSubmit={(values, actions) => {
        fetchWithRefresh(
          userStore,
          `${process.env.REACT_APP_METWATCH_API_URL}api/metload/update-metloads`,
          {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${userStore.user?.userToken.jwt_bearer}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              metloads: filterChangedMetloads(haloStore, values.metloads)
            })
          }
        )
          .then((res) => {
            if (res?.ok) return res.json();
            else throw Error(res?.statusText);
          })
          .then((data) => {
            // Pass each metload that was updated into the selected station
            const newStation = {
              ...haloStore.selectedStation
            };

            data.forEach((newMetload: any) => {
              const i = newStation.station!.metloadWithProperties!.findIndex(
                (metload) => metload.id === newMetload.id
              );

              /**
               * Merge so that the metload doesn't lose the harvestTrace
               * attribute which the API route doesn't return
               * (it can't reutrn this because it isn't a harvest specific route)
               */
              if (i > -1)
                newStation.station!.metloadWithProperties![i] = {
                  ...newStation.station!.metloadWithProperties![i],
                  ...newMetload
                };
            });

            haloStore.updateStation(newStation as MatchedStationGroup);
            successToast('Successfully updated the metloads');
          })
          .catch((err) => {
            if (process.env.NODE_ENV === 'development') console.error(err);
            errorToast('Something went wrong updating the metloads');
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const changedMetloads = filterChangedMetloads(
          haloStore,
          formikProps.values.metloads
        );
        return (
          <FormikForm>
            {formikProps.isSubmitting ? (
              <Spinner animation='border' variant='primary' />
            ) : null}
            <Table size='sm' bordered striped hover>
              <thead>
                <tr>
                  <th style={{ width: '8%' }}>Halo Property ID</th>
                  <th
                    style={{
                      width: '8%'
                    }}
                  >
                    Halo Module ID
                  </th>
                  <th style={{ width: '22.5%' }}>Halo Property Name</th>
                  <th style={{ width: '22.5%' }}>Variable</th>
                  <th style={{ width: '20%' }}>Measurement</th>
                  <th style={{ width: '10%' }}>Multiplier</th>
                  <th style={{ width: '10%' }}>Offset</th>
                  <th style={{ width: '5%' }} />
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name='metloads'
                  render={() =>
                    loading ? (
                      <LoadingOverlay />
                    ) : haloStore.selectedStation?.station ? (
                      formikProps.values.metloads.map(
                        (metload: MetloadWithProperty, index) => {
                          return (
                            <TableRow
                              key={metload.id}
                              metload={metload}
                              formikProps={formikProps}
                              index={index}
                              setLoading={setLoading}
                            />
                          );
                        }
                      )
                    ) : null
                  }
                />
              </tbody>
            </Table>
            <div className='d-flex justify-content-center'>
              <AddMeasurementModal variant='outline-primary' className='mx-2' />
              <Button
                variant='primary'
                type='submit'
                className='mx-2'
                disabled={changedMetloads.length === 0}
              >
                Submit
              </Button>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  ) : null;
};

export default observer(MetloadsTable);

