import { makeAutoObservable, runInAction } from 'mobx';
import UserStore from '../user/UserStore';
import {
  getAccessIDs as getAccessIDsRequest,
  getUserAccessArray as getUserAccessArrayRequest,
  updateAccessArray as updateAccessArrayRequest,
  updateMetWatchAccessArray as updateMetWatchAccessArrayRequest,
  getMetwatchAccessIDs as getMetwatchAccessIDsRequest
} from './AccessArrayRequests';
import * as All from './AccessArrayTypes';


/**
 * Station store contains station data and methods for retrieving and updating
 * data.
 */
export default class AccessArrayStore {
  user: UserStore;
  accessArrays: All.Access[] = [];
  currentAccessArray: All.Access | undefined = undefined;
  currentMetwatchAccessArray: All.MetWatchAccessArray | undefined = undefined;
  metwatchAccessArrays: All.MetWatchAccessArray[] = [];
  userAccessArray: All.accessArray | undefined
  networks: All.MetWatchNetwork[] = []
  loading: boolean = false;
  retryCount: number = 0;

  constructor(user: UserStore) {
    // Cannot be instantiated before user login - UserStore provided to
    // constructor.
    this.user = user;
    makeAutoObservable(this);
  }

  /**
   * Gets all users and populates the user fields.
   *
   * If 401 encountered, refreshes login.
   */
  async requestAccessUsers() {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await getAccessIDsRequest(this.user.user!.userToken)
      .then((data) => {
        runInAction(() => {
          this.accessArrays = data
        });
      })
      .catch((err: Error) => {
        // Refresh if no longer logged in.

        if (err.message.includes('401')) {
          if (this.retryCount < 1) {
            runInAction(() => {
              this.retryCount++;
              this.user.refresh();
            });
          }
          // Logout if already attempted to refresh.
          runInAction(() => {
            this.retryCount = 0;
            this.user.logout();
          });
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  /**
 * Gets all users and populates the user fields.
 *
 * If 401 encountered, refreshes login.
 */
  async requestMetwatchAccessUsers() {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await getMetwatchAccessIDsRequest(this.user.user!.userToken)
      .then((data) => {
        runInAction(() => {
          this.metwatchAccessArrays = data
        });
      })
      .catch((err: Error) => {
        // Refresh if no longer logged in.

        if (err.message.includes('401')) {
          if (this.retryCount < 1) {
            runInAction(() => {
              this.retryCount++;
              this.user.refresh();
            });
          }
          // Logout if already attempted to refresh.
          runInAction(() => {
            this.retryCount = 0;
            this.user.logout();
          });
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  async requestFullUserAccessArray(id: number | undefined) {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await getUserAccessArrayRequest(this.user.user!.userToken, id)
      .then((data) => {
        runInAction(() => {
          this.userAccessArray = data
        });
      })
      .catch((err: Error) => {
        // Refresh if no longer logged in.

        if (err.message.includes('401')) {
          if (this.retryCount < 1) {
            runInAction(() => {
              this.retryCount++;
              this.user.refresh();
            });
          }
          // Logout if already attempted to refresh.
          runInAction(() => {
            this.retryCount = 0;
            this.user.logout();
          });
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  /**
   * Sets the current AccessArray index to the index with the provided ID.
   * @param accessId index to set as current.
   */
  setCurrentAccessArray(accessId: number | undefined) {
    runInAction(() => {
      this.currentAccessArray =
        accessId !== undefined
          ? this.accessArrays.find((accessArray) => accessArray.id === accessId)
          : undefined;
    });
  }

  async updateAccessArray(
    accessArray: All.Access,
    id: number
  ) {
    await updateAccessArrayRequest(
      this.user.user!.userToken,
      accessArray
    ).then((data) => {
      const index = this.accessArrays.findIndex((sub) => sub.id === id);
      runInAction(() => {
        this.accessArrays[index] = {
          ...this.accessArrays[index],
          ...data
        };
        this.currentAccessArray = this.accessArrays[index];
      });
    });
  }

  async updateMetwatchAccessArray(
    mwAccessArray: All.MetWatchAccessArray,
    id: number
  ) {
    await updateMetWatchAccessArrayRequest(
      this.user.user!.userToken,
      mwAccessArray
    ).then((data) => {
      const index = this.accessArrays.findIndex((sub) => sub.id === id);
      runInAction(() => {
        this.accessArrays[index] = {
          ...this.accessArrays[index],
          ...data
        };
        this.currentAccessArray = this.accessArrays[index];
      });
    });
  }
}

