import React from 'react';
import { Link } from 'react-router-dom';

require('./header.scss');

/**
 * Header component to be rendered on each page. Contains a clickable header
 * image which links to the dashboard.
 */
export default function Header() {
  return (
    <div className='header bg-admin'>
      <div className='header_inner'>
        <Link to='/'>
          <img
            className='header-img d-flex'
            src={
              process.env.REACT_APP_S3_BUCKET_ASSETS_URL +
              'metwatch_admin_logo.svg'
            }
            alt='Metwatch Admin'
          />
        </Link>
      </div>
    </div>
  );
}
