import { createContext } from 'react';
import { AllUsersStore } from './allusers/AllUsersStore';
import { BomStore } from './bom_stations/BomStore';
import { HaloStore } from './halo_stations/HaloStore';
import HarvestStationsStore from './harvest_stations/HarvestStationsStore';
import { HilltopStore } from './hilltop_stations/HilltopStore';
import MetloadStore from './metload/MetloadMetadataStore';
import { MetrisStore } from './metris_stations/MetrisStore';
import { MetserviceStore } from './metservice_stations/MetserviceStore';
import { RegionStore } from './regions/RegionStore';
import { MdrStore } from './reports/MdrStore';
import StationStore from './stations/StationStore';
import UserStore from './user/UserStore';
import AccessArrayStore from './user_access_arrays/AccessArrayStore';
import { WinetStore } from './winet_stations/WinetStore';

/*
 * This is where the global MobX stores are defined and created.
 *
 * Most stores will contain a reference to UserStore, as this is required to
 * access the JWT token to make requests. Therefore, userStore is defined first
 * and passed into the constructors of the subsequent stores.
 *
 * Note that the contexts here are being provided with default values, which are
 * NOT overriden by a Context.Provider later on in the application.
 */

const userStore = new UserStore();
export const UserContext = createContext<UserStore>(userStore);

const allUsersStore = new AllUsersStore(userStore);
export const AllUsersContext = createContext<AllUsersStore>(allUsersStore);

const regionStore = new RegionStore(userStore);

const stationStore = new StationStore(userStore, regionStore);
export const StationContext = createContext<StationStore>(stationStore);

export const HarvestStationsContext = createContext<HarvestStationsStore>(
  new HarvestStationsStore(userStore, stationStore)
);

export const HilltopStationsContext = createContext<HilltopStore>(
  new HilltopStore(userStore, stationStore)
);

export const HaloStationsContext = createContext<HaloStore>(
  new HaloStore(userStore, stationStore)
);

export const MetserviceStationsContext = createContext<MetserviceStore>(
  new MetserviceStore(userStore, stationStore)
);

export const WinetStationsContext = createContext<WinetStore>(
  new WinetStore(userStore, stationStore)
);

export const BomStationsContext = createContext<BomStore>(
  new BomStore(userStore, stationStore)
);

export const MetrisStationsContext = createContext<MetrisStore>(
  new MetrisStore(userStore, stationStore)
);

export const MetloadContext = createContext<MetloadStore>(
  new MetloadStore(userStore)
);

export const ReportContext = createContext<MdrStore>(
  new MdrStore(userStore, allUsersStore)
);

const accessArrayStore = new AccessArrayStore(userStore);
export const AccessArrayContext = createContext<AccessArrayStore>(
  accessArrayStore
);

