import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Region } from '../../../../store/regions/RegionsTypes';
import UpdateRegionForm from './UpdateRegionForm';

interface Props {
  region: Region;
}

const UpdateRegionModal = ({ region }: Props) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <div className='float-right pr-3'>
      <Button variant='outline-primary' size='sm' onClick={toggleShow}>
        <FontAwesomeIcon icon={faEdit} />
      </Button>
      <Modal show={show} onHide={toggleShow}>
        <Modal.Header closeButton>
          <b>Update Region</b>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <UpdateRegionForm
                hideModal={() => setShow(false)}
                region={region}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default observer(UpdateRegionModal);

