import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import Select from 'react-select';
import { StationContext } from '../../../../store/StoreContexts';
import { StationSelectorProps } from './StationSelectorUtils';

require('./stationselector.scss');

/**
 * Station selector component. Sets value to station ID and displays station
 * name and ID in dropdown.
 */
const StationSelector: React.FC<StationSelectorProps> = (props) => {
  const { setFieldValue, handleBlur } = useFormikContext();
  const stationsStore = useContext(StationContext);
  const options = stationsStore.stations.map((station) => {
    return {
      value: station.Station_ID,
      label: station.Logger_Name + ' (' + station.Station_ID + ')'
    };
  });
  return (
    <Select
      name={props.name}
      options={options}
      onChange={(opt) => {
        // We cannot pass in the regular onChange handler from formik - instead
        // we must directly call setFieldValue on the appropriate field.
        setFieldValue(props.name, opt?.value);
      }}
      onBlur={handleBlur}
      value={options.find((opt) => opt.value === props.value)}
      className={props.isInvalid ? 'is-invalid' : ''}
      styles={
        props.isInvalid
          ? {
              control: (provided, _) => ({
                ...provided,
                borderColor: '#dc3545'
              })
            }
          : {}
      }
    ></Select>
  );
};

export default observer(StationSelector);

