import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import {
  HarvestStationsContext,
  MetloadContext
} from '../../../store/StoreContexts';
import { Station } from '../../../store/stations/StationTypes';
import StationsMap from '../../maps/StationsMap';
import CenterSpinner from '../../utils/CenterSpinner';
import HarvestStationManager from './components/HarvestStationManager';
import HarvestStationsTable from './components/HarvestStationsTable';
import LinkHarvestSiteForm from './components/LinkHarvestSiteForm';

const HarvestStationsPage: React.FC = () => {
  const harvestStationsStore = useContext(HarvestStationsContext);
  const metloadStore = useContext(MetloadContext);
  const [showLinkSiteModal, setShowLinkSiteModal] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const stationStore = harvestStationsStore.stationStore;
  const regionStore = stationStore.regionStore;
  const selectedStation = harvestStationsStore.selectedStation;
  // Whether the harvest site is linked to a MetWatch station.
  const stationLinked =
    selectedStation && selectedStation.station && selectedStation.site;

  useEffect(() => {
    if (stationStore.stations.length === 0) stationStore.requestStations();
    if (harvestStationsStore.matchedHarvestStations.length === 0)
      harvestStationsStore.loadHarvestStations();
    if (
      metloadStore.variables.length === 0 ||
      metloadStore.measurementTypes.length === 0
    )
      metloadStore.initializeData();
  }, [harvestStationsStore, metloadStore, stationStore]);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      if (mounted) setLoadingCountries(true);
      await regionStore.fetchCountries();
      if (mounted) setLoadingCountries(false);
    };
    fetch();
    return () => {
      mounted = false;
    };
  }, [regionStore]);

  return stationStore.loading ||
    harvestStationsStore.loading ||
    metloadStore.loading ||
    loadingCountries ? (
    <CenterSpinner />
  ) : (
    <>
      <Card className='my-3 w-100 rounded-0'>
        <HarvestStationsTable />
      </Card>
      {stationLinked ? (
        <Row>
          <Col className='text-center'>
            <Button
              variant='primary'
              onClick={() => {
                setShowLinkSiteModal(true);
              }}
            >
              Duplicate Station
            </Button>
          </Col>
        </Row>
      ) : null}
      {harvestStationsStore.loadingSelectedStation ? (
        <Row>
          <Col>
            <CenterSpinner />
          </Col>
        </Row>
      ) : stationLinked ? (
        <HarvestStationManager />
      ) : null}
      {selectedStation?.site ? (
        <Modal
          show={
            (selectedStation !== undefined &&
              !harvestStationsStore.loadingSelectedStation &&
              selectedStation.site !== undefined &&
              !selectedStation.station) ||
            showLinkSiteModal
          }
          onHide={() => {
            setShowLinkSiteModal(false);
            harvestStationsStore.fetchMetloadWithHarvestTrace();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              {showLinkSiteModal ? 'Duplicate Site Link:' : 'Link Site:'}
              <a
                href={
                  'https://live.harvest.com/?cmd=home&sid=' +
                  selectedStation.site.site_id
                }
                target='_blank'
                rel='noreferrer noopener'
                className='pl-2'
              >
                {selectedStation.site.site_id}
              </a>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LinkHarvestSiteForm
              harvest_site_id={parseInt(selectedStation.site.site_id)}
              selectedStation={selectedStation.station?.station}
              setSelectedStation={
                harvestStationsStore.fetchMetloadWithHarvestTrace
              }
              isDuplicating={showLinkSiteModal}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      <StationsMap
        stations={
          harvestStationsStore.matchedHarvestStations
            .map((mhs) => {
              return mhs.station ? mhs.station?.station : null;
            })
            .filter((s) => s !== null) as Station[]
        }
        loading={false}
      />
    </>
  );
};

export default observer(HarvestStationsPage);

