import React from 'react';
import { useFormikContext } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import StationSelector from '../../stations/forms/StationSelector';
import { EditCurrentReport } from '../../../../store/reports/MdrUtils';
import CronExpressionSelector from './CronExpressionSelector';

export default function EditReportForm() {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    setFieldValue
  } = useFormikContext<EditCurrentReport>();

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Col>
            <Form.Label>Report Name</Form.Label>
            <Form.Control
              disabled
              type='null'
              onChange={handleChange}
              onBlur={handleBlur}
              name='report_type_name'
              value={values.report_type_name}
              isInvalid={
                (touched.report_type_name ?? false) &&
                (errors.report_type_name ?? '') !== ''
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.report_type_id}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Label>Station</Form.Label>
            <StationSelector
              name='station_id'
              value={values.station_id}
              isInvalid={
                (touched.station_id ?? false) &&
                (errors.station_id ?? '') !== ''
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.station_id}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Label>Send time</Form.Label>
            <CronExpressionSelector
              name='cron_expression'
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values.cron_expression}
              isInvalid={
                (touched.cron_expression ?? false) &&
                (errors.cron_expression ?? '') !== ''
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.cron_expression}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Label>Settings</Form.Label>
            <Form.Control
              type='text'
              onChange={handleChange}
              onBlur={handleBlur}
              name='settings'
              value={values.settings}
              isInvalid={
                (touched.settings ?? false) && (errors.settings ?? '') !== ''
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.settings}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <Form.Label>Suspended at</Form.Label>
            <Form.Control
              disabled
              type='string'
              onChange={handleChange}
              onBlur={handleBlur}
              name='suspended_at'
              value={values.suspended_at}
              isInvalid={
                (touched.suspended_at ?? false) &&
                (errors.suspended_at ?? '') !== ''
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.suspended_at}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Form>
    </>
  );
}
