import { WinetStore } from '../../../../../store/winet_stations/WinetStore';
import { errorToast, successToast } from '../../../../utils/ToastContainer';

/**
 * Sends an API request to remove a metload and updates the station
 * in the winetStore
 */
export const removeSensor = async (
  winetStore: WinetStore,
  winetStationId: number,
  winetSensorId: number,
  variableId: string,
  measurementTypeId: string
) => {
  if (window.confirm('Are you sure you want to delete this measurement?')) {
    try {
      await winetStore.removeSensor(
        winetStationId,
        winetSensorId,
        variableId,
        measurementTypeId
      );
      successToast('Successfully deleted the sensor');
    } catch (error) {
      errorToast('Something went wrong deleting the measurement');
    }
  }
};

