import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StationContext } from '../../../store/StoreContexts';
import { Station } from '../../../store/stations/StationTypes';
import { UnproccessableEntityError } from '../../../store/stations/errors/UnprocessableEntityError';
import { useQuery } from '../../../utils/requests/Query';
import StationsMap from '../../maps/StationsMap';
import WeatherStationTable from './WeatherStationTable';
import StationNetworksDetailsCard from './details/StationNetworksDetailsCard';
import StationRegionDetailsCard from './details/StationRegionDetailsCard';
import StationStatusDetailsCard from './details/StationStatusDetailsCard';
import WeatherStationDetailsCard from './details/WeatherStationDetailsCard';
import StationCreateCard from './forms/StationCreateCard';
import StationEditCard from './forms/StationEditCard';
import StationNetworksEditCard from './forms/StationNetworksEditCard';
import StationStatusEditCard from './forms/StationStatusesEditCard';

require('./wsmanager.scss');

/**
 * Main page for Weather Station Manager.
 */
function WeatherStationManager() {
  const [stationDetailsEditMode, setStationDetailsEditMode] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  // const [newStation, setNewStation] = useState(getEmptyFormStation());
  const stations = useContext(StationContext);
  const query = useQuery();

  const [stationNetworksEditMode, setStationNetworksEditMode] = useState(false);
  const [stationStatusEditMode, setStationStatusEditMode] = useState(false);

  // Here we set the current station according to URL query string.
  stations.setCurrentStation(
    query.get('id') !== null ? (query.get('id') as string) : undefined
  );

  useEffect(() => {
    stations.requestStations();
    stations.requestStatuses();
    // Suppress warnings for passing empty dependency array to prevent infinite
    // rendering loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for calling update method on stations store, to pass into edit
  // form.
  const updateStation = async (stationId: string, station: Station) => {
    try {
      await stations.updateStation(stationId, station);
      // Will set edit mode to false only if updated OK.
      setStationDetailsEditMode(false);
      setStationNetworksEditMode(false);
      setStationStatusEditMode(false);
      return {};
    } catch (err) {
      if (err instanceof UnproccessableEntityError) {
        return err.errors;
      }
      console.error(err);
      return err;
    }
  };

  // Handler for calling create method on stations store, to pass into modal.
  const createStation = async (station: Station) => {
    await stations.createStation(station);
    // Will hide modal if updated OK.
    setShowCreate(false);
  };

  // Handler for discarding form changes and returning to details view.
  const discardChangesEdit = () => {
    setStationDetailsEditMode(false);
    setStationNetworksEditMode(false);
    setStationStatusEditMode(false);
  };

  // Handler for discarding form changes and dismissing create modal.
  const discardChangesCreate = () => {
    // setNewStation(getEmptyFormStation());
    setShowCreate(false);
  };

  return (
    <>
      <div className='page-title pb-0'>Weather Station Manager</div>
      <Row>
        <Col>
          <Button
            onClick={() => setShowCreate(true)}
            className='float-right m-1 btn-sm'
          >
            New Station
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <StationCreateCard
            visible={showCreate}
            onSubmit={createStation}
            onDiscard={discardChangesCreate}
          ></StationCreateCard>
        </Col>
      </Row>
      <Row>
        <Col>
          <WeatherStationTable
            data={stations.stations}
            currentStation={stations.currentStation}
            loading={stations.loading}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          {stationDetailsEditMode ? (
            <StationEditCard
              station={stations.currentStation!}
              onSubmit={updateStation}
              onDiscard={discardChangesEdit}
            />
          ) : (
            <WeatherStationDetailsCard
              editMode={stationDetailsEditMode}
              setEditMode={setStationDetailsEditMode}
              wsdProps={{
                station: stations.currentStation
              }}
            ></WeatherStationDetailsCard>
          )}
        </Col>
        <Col xs={12} md={4}>
          {stationStatusEditMode ? (
            <StationStatusEditCard
              station={stations.currentStation!}
              onSubmit={updateStation}
              onDiscard={discardChangesEdit}
            ></StationStatusEditCard>
          ) : (
            <StationStatusDetailsCard
              editMode={stationStatusEditMode}
              setEditMode={setStationStatusEditMode}
              wsdProps={{
                station: stations.currentStation
              }}
            ></StationStatusDetailsCard>
          )}
          {stationNetworksEditMode ? (
            <StationNetworksEditCard
              station={stations.currentStation!}
              onSubmit={updateStation}
              onDiscard={discardChangesEdit}
            ></StationNetworksEditCard>
          ) : (
            <StationNetworksDetailsCard
              editMode={stationNetworksEditMode}
              setEditMode={setStationNetworksEditMode}
              wsdProps={{
                station: stations.currentStation
              }}
            ></StationNetworksDetailsCard>
          )}
          <StationRegionDetailsCard
            wsdProps={{
              station: stations.currentStation
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StationsMap
            stations={stations.stations}
            loading={stations.loading}
          />
        </Col>
      </Row>
    </>
  );
}

export default observer(WeatherStationManager);

