import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

type CronExpSelectorProps = {
  name: string;
  setFieldValue: any;
  onBlur: any;
  value: any;
  isInvalid: boolean;
};

const CronExpressionSelector: React.FC<CronExpSelectorProps> = (props) => {
  const options = [
    { value: '* 7 * * *', label: 'Every day at 7am' },
    { value: '* 7 * * 1', label: 'Every Monday at 7am' },
    {
      value: '* 7 * * 1-5',
      label: 'Monday - Friday at 7am'
    }
  ];
  const [selectedOption, setSelectedOption] = useState(props.value);
  const cronExpressions = options.map((option) => {
    return {
      value: option.value,
      label: option.label
    };
  });

  return (
    <div className={props.isInvalid ? 'is-invalid' : ''}>
      <CreatableSelect
        name={props.name}
        placeholder={selectedOption}
        options={cronExpressions}
        defaultValue={props.value}
        value={selectedOption}
        onBlur={props.onBlur}
        onChange={(option) => {
          setSelectedOption(option?.value);
          props.setFieldValue(props.name, option?.value);
        }}
      />
    </div>
  );
};

export default observer(CronExpressionSelector);

