import { useCallback, useContext } from "react";
import { HilltopStore } from "../../../../../store/hilltop_stations/HilltopStore";
import { UnproccessableEntityError } from "../../../../../store/stations/errors/UnprocessableEntityError";
import { HilltopStationsContext, UserContext } from "../../../../../store/StoreContexts";
import { fetchWithRefresh } from "../../../../../utils/requests/RequestsHelpers";

export interface FormValues {
	measurement_id: string;
	variable_id: string;
	measurement_type_id: string;
	multiplier: number;
	offset: number;
}

export interface AddMeasurementSubmitParams {
	values: FormValues;
}

type AddMeasurementBody = Omit<FormValues, "measurement_id">

export const useAddMeasurement = () => {
	const userStore = useContext(UserContext);
	const hilltopStore = useContext<HilltopStore>(HilltopStationsContext);

	const addMeasurement = useCallback(async (values: FormValues) => {
		const provider = hilltopStore.provider;
		const site = hilltopStore.selectedStation?.site.name;
		const measurementName = values.measurement_id;
		const body: AddMeasurementBody = {
			variable_id: values.variable_id,
			measurement_type_id: values.measurement_type_id,
			multiplier: values.multiplier,
			offset: values.offset,
		}
		const resposne = await fetchWithRefresh(
			userStore,
			`${process.env.REACT_APP_METWATCH_API_URL}
			api/admin/weather-providers/hilltop/add-measurement/
			${hilltopStore.selectedStation?.station?.id}?
			provider=${provider}&site=${site}&measurementName=${measurementName}`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${userStore.user?.userToken.jwt_bearer}`,
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			}
		)
		if (resposne?.ok) return await resposne.json();
		if (resposne?.status === 422)
			throw new UnproccessableEntityError(
				resposne.statusText,
				await resposne.json()
			);
		else throw new Error(resposne?.statusText);
	}, [
		hilltopStore.selectedStation?.station?.id,
		userStore,
		hilltopStore.provider,
		hilltopStore.selectedStation?.site.name
	]);

	return { addMeasurement }
}