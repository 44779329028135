import { groupBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { HaloStationsContext } from '../../../../store/StoreContexts';

const MeasurementsTable = () => {
  const haloStore = useContext(HaloStationsContext);

  const station = haloStore.selectedStation;
  const properties = haloStore.selectedGroupProperties;

  const hasData = () => {
    return station && properties && station.group && station.station;
  };
  // Group properties by the 'module_id'.
  const propertiesGrouped = groupBy(properties, 'module_id');

  return hasData() ? (
    <Table size='sm' bordered striped hover>
      <thead>
        <tr>
          <th style={{ fontWeight: 'bold' }}>
            Name / <i style={{ fontWeight: 'bolder' }}>Module ID</i>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(propertiesGrouped)?.map((moduleId) => {
          // Display the property module ID first then the property names.
          const moduleIdRow = (
            <tr key={`${moduleId}`}>
              <td style={{ backgroundColor: '#d6d6d6' }}>
                <b>{moduleId}</b>
              </td>
            </tr>
          );
          const rows = [
            moduleIdRow,
            ...propertiesGrouped[moduleId].map((property, i) => {
              const propertyName =
                property.unit === ''
                  ? property.name
                  : `${property.name} (${property.unit})`;
              return (
                <tr key={`${moduleId}-${i}`}>
                  <td>{propertyName}</td>
                </tr>
              );
            })
          ];
          return rows;
        })}
      </tbody>
    </Table>
  ) : null;
};

export default observer(MeasurementsTable);

