import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { MetserviceStationsContext } from '../../../../store/StoreContexts';
import MetloadsTable from '../MetloadsTable/MetloadsTable';
import VariablesTable from '../VariablesTable/VariablesTable';

const MetserviceStationManager = () => {
  const metserviceStore = useContext(MetserviceStationsContext);

  const stationVariables = metserviceStore.selectedStationVariables;

  const stationHasVariables = stationVariables && stationVariables.length > 0;

  return metserviceStore.loadingStationVariables ? (
    <Row>
      <Col>
        <Spinner animation='border' variant='primary' />
      </Col>
    </Row>
  ) : metserviceStore.hasSelectedStation() &&
    metserviceStore.selectedStation?.site?.metloadWithVariables ? (
    <>
      <Row className='mt-3'>
        {stationHasVariables ? (
          <>
            <Col xs={3}>
              <h4>Variabes</h4>
              <VariablesTable />
            </Col>
            <Col xs={9}>
              <h4>Metloads</h4>
              <MetloadsTable />
            </Col>
          </>
        ) : null}
      </Row>
    </>
  ) : null;
};

export default observer(MetserviceStationManager);

