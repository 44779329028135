import { makeAutoObservable, runInAction } from 'mobx';
import { devConsoleLog } from '../../logging';
import { authenticate, getUser, isUserAdmin, refreshToken } from './UserAuth';
import {
  getRememberMe,
  refreshTokenExists,
  removeRefreshToken,
  saveRefreshToken,
  setRememberMe
} from './UserCookieHandler';
import { User } from './UserTypes';

/**
 * UserStore contains user details, authentication and session information.
 */
export default class UserStore {
  user: User | undefined;
  userState: 'loggedIn' | 'cookieExists' | 'loggedOut' = 'loggedOut';
  loading = false;

  // Determines whether the user has administrator privileges.
  isAdmin = () => (this.user !== undefined ? this.user!.isAdmin : false);

  // Determines whether the store should refresh the JWT token and
  // reauth the user. This works by checking whether the remember me cookie
  // is set, and if not, checking whether the remember value in session
  // storage is set. If this is set in session storage, but remember me is
  // set to false, this means that the store should still refresh, as the
  // user has not ended their session.
  shouldRefresh = () =>
    getRememberMe() || window.sessionStorage.getItem('remember') !== null;

  constructor() {
    // Determine user account state. If logged out, all pages will
    // redirect to login.
    devConsoleLog('Initialising UserStore.');
    runInAction(() => {
      this.userState =
        this.user !== undefined
          ? 'loggedIn'
          : refreshTokenExists()
            ? 'cookieExists'
            : 'loggedOut';
    });

    if (this.userState === 'cookieExists' && this.shouldRefresh()) {
      devConsoleLog('Cookie exists. Refreshing in UserStore constructor.');
      this.refresh();
    } else {
      this.logout();
    }
    makeAutoObservable(this);
  }

  /**
   * Handles the authentication and login process. Uses functions in UserAuth
   * to authenticate and retrieve user data for the given credentials.
   * @param email User email address.
   * @param password User password.
   */
  async login(email: string, password: string, remember: boolean) {
    runInAction(() => {
      this.loading = true;
    });
    await authenticate(email, password)
      .then((token) => {
        getUser(token).then((data) => {
          // Saves the refresh token in cookies for session persistence.
          saveRefreshToken(token);
          setRememberMe(remember);

          runInAction(() => {
            this.user = {
              userDetails: data,
              userToken: token,
              isAdmin: isUserAdmin(token)
            };
            this.userState = 'loggedIn';
          });
        });
      })
      .catch(() => {
        this.logout();
        alert('Invalid email address or password');
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  /**
   * Handles reauthenticating with refresh token. Uses refreshToken function in
   * UserAuth to authenticate and retrieve user data for the existing refresh
   * token stored in cookies.
   */
  async refresh(silent = false) {
    if (this.loading) return;
    if (!silent)
      runInAction(() => {
        this.loading = true;
      });
    return await refreshToken()
      .then((token) => {
        getUser(token).then((data) => {
          // Saves the refresh token in cookies for session persistence.
          saveRefreshToken(token);
          runInAction(() => {
            this.user = {
              userDetails: data,
              userToken: token,
              isAdmin: isUserAdmin(token)
            };
            this.userState = 'loggedIn';
          });

          devConsoleLog(
            'Successfully logged in by refresh. User state: ' + this.userState
          );
          return true;
        });
      })
      .catch(() => {
        // Logout if caught error refreshing.
        devConsoleLog(
          'Failed to log in by refresh. User state: ' + this.userState
        );
        this.logout();
        return false;
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  }

  /**
   * Handles logging the user out by deleting user information and removing the
   * refresh token cookie. Will navigate to homepage after this.
   */
  logout() {
    devConsoleLog(`Logging out. Previous user state: ${this.userState}`);
    removeRefreshToken();
    this.userState = 'loggedOut';
    this.user = undefined;
  }
}
