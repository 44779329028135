import React from 'react';
import { Spinner } from 'react-bootstrap';
import { LoadingProps } from './LayoutTypes';

require('./loading.scss');

/**
 * Loading spinner component. Displays a spinner over a semi-opaque white box.
 * Can be set to fill the screen or fill a parent component.
 * @param props If isFixed is true, fill the screen. Otherwise, fill parent.
 */
export default function Loading(props: LoadingProps) {
  return (
    <div
      className={props.isFixed ? 'loading-overlay-fixed' : 'loading-overlay'}
    >
      <Spinner className='spinner' animation='border' role='loading'></Spinner>
    </div>
  );
}
