import {
  faSortDown,
  faSortUp,
  faSort
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Column, useGlobalFilter, useSortBy, useTable } from 'react-table';
import {
  UserSubscription,
  Subscription
} from '../../../store/reports/MdrUtils';
import Loading from '../../layout/Loading';
import GlobalFilter from '../../utils/table/GlobalFilter';
import YesNoCheckIcon from '../../utils/YesNoCheckIcon';

type ReportsTableProps = {
  data: UserSubscription[];
  loading: boolean;
  subscription: Subscription | undefined;
};

const getIntervalFromNow = (date: string) =>
  Interval.fromDateTimes(
    DateTime.now(),
    DateTime.fromFormat(date, 'yyyy-MM-dd')
  );

const columns: Column<UserSubscription>[] = [
  {
    Header: 'ID',
    accessor: 'id',
    Cell: (props: any) => (
      <Link className='text-dark' to={'/reports?id=' + props.cell?.value}>
        {props.cell?.value}
      </Link>
    )
  },
  {
    Header: 'Email',
    accessor: (row) => {
      return row.alternate_email ? row.alternate_email : row.user?.email;
    }
  },
  {
    Header: 'Name',
    accessor: (row) => {
      return row.user ? row.user.name : null;
    }
  },
  {
    Header: 'Subscription Domain',
    accessor: (row) => {
      return row.user ? row.user_type : null;
    }
  },
  {
    Header: 'Subscription Type',
    accessor: 'sub_type'
  },
  {
    Header: 'Days Remaining',
    accessor: (row) => {
      const interval = getIntervalFromNow(row.stop_date);
      return interval.isValid ? interval.count('days') : '-';
    }
  },
  {
    Header: 'Active',
    accessor: (row) => {
      return (
        row.suspended_at === null &&
        row.deleted_at === null &&
        getIntervalFromNow(row.stop_date).isValid &&
        !getIntervalFromNow(row.start_date).isValid
      );
    },
    Cell: (props: any) => <YesNoCheckIcon value={props.cell.value} />
  }
];

/**
 * Table displaying all subscriptions.
 * @param props Loading flag and table data.
 */
export const SubscriptionsTable: React.FC<ReportsTableProps> = (props) => {
  const data = props.data;
  const history = useHistory();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      {/* This div creates a scroll box for the table. */}
      <div style={{ height: '300px', overflowY: 'scroll', marginRight: 0 }}>
        {props.loading && data.length == 0 ? (
          <Loading />
        ) : (
          <Table {...getTableProps()} size='sm' striped hover>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ borderTop: 0 }}
                    >
                      {column.render('Header') + ' '}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                function showSubscriptionDetails() {
                  const url = '/reports?id=' + row.values.id.toString();
                  history.push(url);
                }
                return (
                  <tr
                    onClick={showSubscriptionDetails}
                    className={
                      props.subscription?.id === row.values.id
                        ? 'table-primary'
                        : ''
                    }
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};
