import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { Region } from '../../../store/regions/RegionsTypes';
import GlobalFilter from '../../utils/table/GlobalFilter';
import { TableRowEdit } from '../../utils/types';
import AddRegionModal from './add_region/AddRegionModal';
import DeleteRegionModal from './delete_region/DeleteRegionModal';
import UpdateRegionModal from './update_region/UpdateRegionModal';

type TableData = Array<Region & TableRowEdit>;

interface Props {
  regions: Region[];
}

const RegionsTable = ({ regions }: Props) => {
  const data: TableData = useMemo(
    () =>
      orderBy(
        regions.map((region) => {
          return {
            ...region,
            update: <UpdateRegionModal region={region} />,
            delete: <DeleteRegionModal region={region} />
          };
        }),
        'display_order',
        'asc'
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(regions)]
  );

  // This defines the data to be displayed in the table. Header represents the
  // heading that will be displayed for a given column, while accessor defines
  // the field to access in the data object for the corresponding header.
  const columns = useMemo(
    () => [
      {
        Header: 'Region',
        accessor: 'name'
      },
      {
        Header: 'Timezone',
        accessor: 'timezone'
      },
      {
        disableSortBy: true,
        Header: '',
        accessor: 'update'
      },
      {
        disableSortBy: true,
        Header: '',
        accessor: 'delete'
      }
    ],
    []
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  return (
    <Row className='pr-4'>
      <Col className='m-0 p-0' xs={1}>
        <div
          className='ml-4'
          style={{
            height: '100%',
            borderLeft: '2px solid #4599d4',
            borderRadius: '0% 10%'
          }}
        />
      </Col>
      <Col className='m-0 p-0' xs={11}>
        <Row className='mt-4 mb-1'>
          <Col>
            <AddRegionModal />
          </Col>
        </Row>
        <Card className='tableContainer'>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          {/* This div creates a scroll box for the table. */}
          <div
            style={{
              minHeight: '150px',
              maxHeight: '300px',
              overflowY: 'scroll',
              marginRight: 0
            }}
          >
            <Table {...getTableProps()} size='sm' striped hover>
              <thead className='tableHeader'>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header') + ' '}
                        {column.canSort ? (
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortDown} />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} />
                            )}
                          </span>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default observer(RegionsTable);

