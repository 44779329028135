import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { makeAutoObservable, runInAction } from 'mobx';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import StationStore from '../stations/StationStore';
import { Station } from '../stations/StationTypes';
import UserStore from '../user/UserStore';
import {
  Group,
  HaloData,
  HaloMetloads,
  MatchedStationGroup
} from './HaloUtils';
import {
  GetGroupPropertiesResponse,
  GetGroupsResponse
} from './models/HaloApi';
import { HaloProperty } from './models/HaloProperty';
import { HaloStation } from './models/HaloStation';

export class HaloStore {
  // Stores.
  userStore: UserStore;
  stationStore: StationStore;
  // Loaded Halo stations (Halo stations in our network).
  haloStations: HaloStation[] = [];
  // Halo API data.
  haloData: HaloData = {
    groups: []
  };
  //
  haloMatchedStationGroups: MatchedStationGroup[] = [];
  haloStationMetloads: HaloMetloads[] = [];
  selectedStationIndex: number | undefined;
  // Loading indicators.
  loadingHaloStations: boolean = false;
  loadingGroups: boolean = false;
  loadingGroupProperties: boolean = false;
  loadingSetupHaloStation: boolean = false;
  loadingAddProperty: boolean = false;
  loadingRemoveProperty: boolean = false;
  loadingHaloStationMetloads: boolean = false;

  constructor(userStore: UserStore, stationStore: StationStore) {
    makeAutoObservable(this);
    this.userStore = userStore;
    this.stationStore = stationStore;
  }

  updateStation(station: MatchedStationGroup) {
    const i = this.findStationIndex(station);
    if (i !== undefined) {
      const newMatchedStationGroup = [...this.haloMatchedStationGroups];
      newMatchedStationGroup[i] = station;
      this.haloMatchedStationGroups = newMatchedStationGroup;
    }
  }

  get selectedGroupProperties() {
    return this.haloData.groups.find(
      (g) => g.id === this.selectedStation?.group.id
    )?.properties;
  }

  hasSelectedStation(): boolean {
    return this.selectedStation?.station && this.selectedStation.group
      ? true
      : false;
  }

  async getMetloadsMeasurements(matched: MatchedStationGroup) {
    const currentStation = matched.station;
    if (!matched || !matched.station) {
      return;
    }
    // Load site measurements.
    await this.loadGroupProperties(matched?.group.id);
    // Load given station metloads.
    if (currentStation?.station) {
      await this.loadHaloStationMetloads(currentStation);
      currentStation.metloadWithProperties = this.haloStationMetloads;
    }
  }

  get selectedStation(): MatchedStationGroup | undefined {
    return this.selectedStationIndex !== undefined &&
      this.selectedStationIndex > -1
      ? this.haloMatchedStationGroups[this.selectedStationIndex]
      : undefined;
  }

  deSelectStation() {
    this.selectedStationIndex = undefined;
  }

  set setSelectedStationIndex(station: MatchedStationGroup) {
    this.selectedStationIndex = this.findStationIndex(station);
  }

  findStationIndex(station: MatchedStationGroup): number | undefined {
    const i = this.haloMatchedStationGroups.findIndex((s) =>
      station.group && s.group
        ? station.group.id === s.group.id
        : station.station && s.station
        ? station.station.station_id === s.station.station_id
        : false
    );

    return i > -1 ? i : undefined;
  }

  /**
   * Match groups and stations by the group ID.
   * Each station should be assigned to one group.
   * Not all groups will be assigned a station.
   */
  getMatchedStationsGroups() {
    const stations = this.haloStations;
    const sites = this.haloData.groups;

    let matched: MatchedStationGroup[] = [];
    sites.forEach((group: Group) => {
      const siteId = group.id.toString();
      stations.forEach((station: HaloStation) => {
        const stationGroupId = station.group_id;
        if (siteId === stationGroupId) {
          matched.push({
            group,
            station
          });
        } else {
          matched.push({
            group
          });
        }
      });
      // Handle case when a there are no stations.
      if (stations.length === 0) {
        matched.push({
          group
        });
      }
    });
    matched = uniqBy(matched, function (o) {
      return [o.group.id, o.station?.group_id].join();
    });
    matched = sortBy(matched, [
      function (o) {
        return o.station;
      }
    ]);
    runInAction(() => {
      this.haloMatchedStationGroups = matched;
    });
  }

  async loadHaloStationMetloads(haloStation: HaloStation) {
    runInAction(() => {
      this.loadingHaloStationMetloads = true;
    });

    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/halo/metloads/${haloStation.id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as HaloMetloads[];
      runInAction(() => {
        this.haloStationMetloads = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.loadingHaloStationMetloads = false;
    });
  }

  async loadStations() {
    runInAction(() => {
      this.loadingHaloStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/halo/halo-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as HaloStation[];
      runInAction(() => {
        this.haloStations = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingHaloStations = false;
    });
  }

  async loadGroups() {
    runInAction(() => {
      this.loadingGroups = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/halo/api/get-groups`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetGroupsResponse;
      runInAction(() => {
        const groups: Group[] = data.map(({ GroupId, Name }) => ({
          id: GroupId,
          name: Name,
          properties: []
        }));
        this.haloData.groups = groups;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingGroups = false;
    });
  }

  async loadGroupProperties(groupId: number) {
    runInAction(() => {
      this.loadingGroupProperties = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/halo/api/get-group-properties?group_id=${groupId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetGroupPropertiesResponse;
      runInAction(() => {
        const groupIndex = this.haloData.groups.findIndex(
          (group) => group.id === groupId
        );
        this.haloData.groups[groupIndex].properties = data.map(
          ({ Name, Units, module_id }) => {
            return {
              name: Name,
              unit: Units,
              module_id
            };
          }
        );
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingGroupProperties = false;
    });
  }

  async setupHaloStation(
    station: Station & {
      halo_group_id: string;
      halo_site_setup: 0 | 1;
    }
  ) {
    runInAction(() => {
      this.loadingSetupHaloStation = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/halo/setup-halo-station`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(station)
        }
      );
      const data = (await response?.json()) as HaloStation;
      runInAction(() => {
        this.stationStore.addStation(data.station);
        this.haloStations.push(data);
        const i = this.haloMatchedStationGroups.findIndex(
          (s) => s.group?.id.toString() === station.halo_group_id
        );
        const matchedHaloStation = this.haloMatchedStationGroups[i];
        if (matchedHaloStation) {
          matchedHaloStation.station = data;
          const newMatchedHaloStations = [...this.haloMatchedStationGroups];
          newMatchedHaloStations[i] = matchedHaloStation;
          const sortedMatched = sortBy(newMatchedHaloStations, [
            function (o) {
              return o.station;
            }
          ]);
          this.haloMatchedStationGroups = sortedMatched;
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingSetupHaloStation = false;
    });
  }

  async addProperty(
    haloStationId: number,
    body: Pick<HaloProperty, 'name'> & {
      module_id: string;
      variable_id: string;
      measurement_type_id: string;
      multiplier: number;
      offset: number;
    }
  ): Promise<HaloStation | undefined> {
    runInAction(() => {
      this.loadingAddProperty = true;
    });
    let data: HaloStation | undefined;
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/halo/add-property/${haloStationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(body)
        }
      );
      if (!response?.ok) return undefined;
      const haloStationData = (data = (await response?.json()) as HaloStation);
      runInAction(() => {
        const haloStationIndex = this.haloStations.findIndex(
          (hs) => hs.id === haloStationId
        );
        this.haloStations = this.haloStations.map((hs, i) => {
          if (i !== haloStationIndex) return hs;
          return haloStationData;
        });
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAddProperty = false;
    });
    return data;
  }

  async removeProperty(
    haloStationId: number,
    haloPropertyId: number,
    variableId: string,
    measurementTypeId: string
  ) {
    runInAction(() => {
      this.loadingAddProperty = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/halo/remove-property/${haloStationId}/${haloPropertyId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'DELETE',
          body: JSON.stringify({
            variable_id: variableId,
            measurement_type_id: measurementTypeId
          })
        }
      );
      if (!response?.ok) throw Error('request failed');
      const data = (await response?.json()) as HaloStation;
      runInAction(async () => {
        const haloStationIndex = this.haloStations.findIndex(
          (hs) => hs.id === haloStationId
        );
        this.haloStations = this.haloStations.map((hs, i) => {
          if (i !== haloStationIndex) return hs;
          return data;
        });
        if (this.selectedStation) {
          const station = { ...this.selectedStation };
          station.station = data;
          await this.getMetloadsMeasurements(station);
          this.updateStation(station);
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      throw new Error(error);
    }
    runInAction(() => {
      this.loadingAddProperty = false;
    });
  }

  get loading() {
    return (
      this.loadingHaloStations ||
      this.loadingGroups ||
      this.loadingGroupProperties ||
      this.loadingSetupHaloStation ||
      this.loadingAddProperty ||
      this.loadingRemoveProperty
    );
  }

  get loadingInitial() {
    return this.loadingHaloStations || this.loadingGroups;
  }
}

