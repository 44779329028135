import React, { useContext } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { StationContext } from '../../../../store/StoreContexts';
import { DetailItem as StationDetailItem } from '../../../utils/DetailItem';
import YesNoCheckIcon from '../../../utils/YesNoCheckIcon';
import { WeatherStationDetailsProps } from '../WeatherStationTypes';

/**
 * Displays the details of a single weather station in a card body.
 *
 * Note that this component is returned within a Card.Body component - not a
 * Card component. This is because it is designed to be used within an existing
 * card, not on its own. See WeatherStationDetailsCard for use.
 *
 * @param props Station data.
 */
export default function WeatherStationDetails(
  props: WeatherStationDetailsProps
) {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;

  // Formats a date object as a readable string.
  // Example: Thu 3 Dec 2020
  const toHumanReadableDate = (date: Date) =>
    date.toDateString().split(' ').splice(0, 4).join(' ');

  // Display '-' when no region other wise display region and country.
  // If there is no country display '-' in it's place.
  const regionCountryText = !props.station?.region
    ? '-'
    : `${props.station?.region?.name}, ${
        props.station.region?.country_id
          ? regionStore.getCountry(props.station.region?.country_id)?.name
          : '-'
      }`;

  // Display default card if no station is selected.
  if (props.station === undefined)
    return <Card.Body>Select a station to see more...</Card.Body>;
  return (
    <Card.Body>
      <Card.Title>{props.station?.Logger_Name}</Card.Title>
      <ListGroup variant='flush'>
        <StationDetailItem
          title='Station ID'
          content={props.station?.Station_ID}
        />
        <StationDetailItem
          title='Active'
          content={<YesNoCheckIcon value={props.station?.Active === 'Y'} />}
        />
        <StationDetailItem
          title='UTC Station'
          content={<YesNoCheckIcon value={props.station?.is_utc === 'Y'} />}
        />
        <StationDetailItem title='Name' content={props.station?.Logger_Name} />
        <StationDetailItem title='Region' content={regionCountryText} />
        <StationDetailItem title='Timezone' content={props.station?.timezone} />
        <StationDetailItem
          title='Logger Type'
          content={props.station?.Logger_Type}
        />
        <StationDetailItem
          title='Logger Description'
          content={props.station?.Logger_Description}
        />
        <StationDetailItem
          title='Calculates Wetness'
          content={
            <YesNoCheckIcon value={props.station?.Calc_Wetness === 'Y'} />
          }
        />
        <StationDetailItem
          title='Forecast Site (WMO Number)'
          content={props.station?.WMONumber}
        />
        <StationDetailItem
          title='Backup Station'
          content={<StationLink stationId={props.station?.Backup_Station_ID} />}
        />
        <StationDetailItem
          title='ET Station'
          content={<StationLink stationId={props.station?.ET_Station} />}
        />
        <StationDetailItem
          title='Installation Date'
          content={
            props.station.Start_Date
              ? toHumanReadableDate(new Date(props.station?.Start_Date))
              : ''
          }
        />
        <StationDetailItem
          title='Decommission Date'
          content={
            props.station.End_Date
              ? toHumanReadableDate(new Date(props.station?.End_Date))
              : ''
          }
        />
        <StationDetailItem
          title='Last Calibrated'
          content={
            props.station.Last_Calibration
              ? toHumanReadableDate(new Date(props.station?.Last_Calibration))
              : ''
          }
        />
        <StationDetailItem
          title='Adjusts for DST'
          content={<YesNoCheckIcon value={props.station?.DST === 'Y'} />}
        />
        <StationDetailItem
          title='Lat / Long'
          content={
            <StationMapLink
              lat={props.station?.Latitude}
              lon={props.station?.Longitude}
            />
          }
        />
        <StationDetailItem title='Comments' content={props.station?.Comments} />
      </ListGroup>
    </Card.Body>
  );
}

/**
 * Displays the full name and a link to the details page of a given station.
 * @param props ID of station to link to.
 */
function StationLink(props: { stationId: string }) {
  const stations = useContext(StationContext);
  if (
    !stations.stations.some((station) => station.Station_ID === props.stationId)
  )
    return <></>;
  return (
    <>
      {stations.stations.find(
        (station) => station.Station_ID === props.stationId
      )?.Logger_Name + ' ('}
      <Link to={'/?id=' + props.stationId}>{props.stationId}</Link>
      {')'}
    </>
  );
}
/**
 * Displays the coordinates and a link to the google maps location of a given
 * position.
 * @param props Latitude and longitude of station.
 */
function StationMapLink(props: { lat: number; lon: number }) {
  return (
    <a
      href={`https://maps.google.com/maps?q=${props.lat},${props.lon}`}
      target='_blank'
      rel='noreferrer'
    >
      {props.lat}, {props.lon}
    </a>
  );
}

