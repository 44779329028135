import { observer } from 'mobx-react-lite';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import { StationContext } from '../../../../store/StoreContexts';
import { WeatherStationDetailsProps } from '../WeatherStationTypes';

const DetailLayout = ({ children }: { children: ReactNode }) => {
  return <b style={{ fontWeight: 500 }}>{children}</b>;
};

const StationRegionDetails = (props: WeatherStationDetailsProps) => {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;
  const region = props.station?.region;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      if (mounted) setLoading(true);
      await regionStore.fetchCountries();
      if (mounted) setLoading(false);
    };
    fetch();
    return () => {
      mounted = false;
    };
  }, [regionStore]);

  const regionName = region?.name || '-';
  const countryName = useMemo(
    () =>
      region?.country_id
        ? regionStore.getCountry(region?.country_id)?.name
        : '-',
    [region?.country_id, regionStore]
  );

  return (
    <Card.Body>
      {loading ? (
        <Spinner animation={'border'} />
      ) : (
        <ListGroup variant='flush'>
          {props.station ? (
            <Row>
              <Col>
                <Row className='mb-1'>
                  <Col>Region:</Col>
                  <Col>
                    <DetailLayout>{regionName}</DetailLayout>
                  </Col>
                </Row>
                <Row>
                  <Col>Country:</Col>
                  <Col>
                    <DetailLayout>{countryName}</DetailLayout>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <div>Please select a station to view details...</div>
          )}
        </ListGroup>
      )}
    </Card.Body>
  );
};

export default observer(StationRegionDetails);

