import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { HilltopStationsContext } from '../../../../../store/StoreContexts';
import { HilltopProvider } from '../../../../../store/hilltop_stations/HilltopUtils';
import GlobalFilter from '../../../../utils/table/GlobalFilter';
import { useHilltopStationsTable } from './useHilltopStationsTable';
import { loadStationsSites } from './utils';

const HilltopStationsTable = () => {
  const hilltopStore = useContext(HilltopStationsContext);

  const getMatched = useCallback(
    async (provider: HilltopProvider | undefined) => {
      return await loadStationsSites(hilltopStore, provider);
    },
    [hilltopStore]
  );

  useEffect(() => {
    getMatched(hilltopStore.provider);
  }, [hilltopStore.provider, getMatched]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useHilltopStationsTable(hilltopStore.hilltopMatchedStationSites);

  return hilltopStore.hilltopStationsLoading ||
    hilltopStore.hilltopSitesLoading ? (
    <Row>
      <Col className='text-center'>
        <Spinner animation='border' variant='primary' />
      </Col>
    </Row>
  ) : (
    <Container>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div style={{ height: '400px', overflowY: 'scroll', marginRight: 0 }}>
        <Table {...getTableProps()} size='sm' striped hover>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ borderTop: 0 }}
                  >
                    {column.render('Header') + ' '}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : column.canSort ? (
                        <FontAwesomeIcon icon={faSort} />
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={async () => {
                    const clickedStation = row.original;
                    // Don't get measurements if station already selected.
                    if (
                      hilltopStore.selectedStation &&
                      hilltopStore.selectedStation?.station?.station_id ===
                        clickedStation.station?.station_id
                    ) {
                      return;
                    }
                    await hilltopStore.getMetloadsMeasurements(clickedStation);
                    hilltopStore.setSelectedStationIndex = {
                      ...clickedStation
                    };
                  }}
                  className={
                    row.original.station &&
                    hilltopStore.selectedStation?.station &&
                    hilltopStore.selectedStation?.station?.id ===
                      row.original.station?.id
                      ? 'table-primary'
                      : ''
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </Container>
  );
};

export default observer(HilltopStationsTable);

