import { makeAutoObservable, runInAction } from 'mobx';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import UserStore from '../user/UserStore';
import { MeasurementType, Variable } from './MetloadTypes';

export default class MetloadMetadataStore {
  userStore: UserStore;

  loading: boolean = false;
  variables: Variable[] = [];
  measurementTypes: MeasurementType[] = [];

  constructor(userStore: UserStore) {
    makeAutoObservable(this);

    // Stores
    this.userStore = userStore;
  }

  async initializeData() {
    runInAction(() => {
      this.loading = true;
    });

    await fetchWithRefresh(
      this.userStore,
      `${process.env.REACT_APP_METWATCH_API_URL}api/metload/variables`,
      {
        headers: {
          Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`,
          Accept: 'application/json'
        }
      }
    )
      .then((res) => {
        if (res?.ok) return res.json();
        else throw Error(res?.statusText);
      })
      .then((data) => {
        runInAction(() => {
          this.variables = data;
        });
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
        runInAction(() => {
          this.variables = [];
        });
      });

    await fetchWithRefresh(
      this.userStore,
      `${process.env.REACT_APP_METWATCH_API_URL}api/metload/measurement-types`,
      {
        headers: {
          Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`,
          Accept: 'application/json'
        }
      }
    )
      .then((res) => {
        if (res?.ok) return res.json();
        else throw Error(res?.statusText);
      })
      .then((data) => {
        runInAction(() => {
          this.measurementTypes = data;
        });
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
        runInAction(() => {
          this.measurementTypes = [];
        });
      });

    runInAction(() => {
      this.loading = false;
    });
  }
}

