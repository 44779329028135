import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Report } from '../../../../store/reports/MdrUtils';
import { Station } from '../../../../store/stations/StationTypes';
import { ReportContext, StationContext } from '../../../../store/StoreContexts';
import AddReportModal from '../forms/AddReportModal';
import EditReportModal from '../forms/EditReportModal';
import DeleteReportModal from '../forms/DeleteReportModal';

type ReportsDetailsProps = {};

const ReportsDetails: React.FC<ReportsDetailsProps> = (props) => {
  const reportStore = useContext(ReportContext);
  const stations = useContext(StationContext);

  useEffect(() => {
    reportStore.requestReports();
    reportStore.requestReportTypes();

    if (stations.stations.length === 0) {
      stations.requestStations();
    }
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col>Subscribed Reports</Col>
            {reportStore.current ? (
              <Col xs='auto'>
                <AddReportModal />
              </Col>
            ) : null}
          </Row>
        </Card.Header>

        <Card.Body>
          {reportStore.current ? (
            reportStore.getCurrentSubscriptionReports().map((report) => {
              return (
                <ReportDetailsEntry
                  report={report}
                  key={report.id}
                  stations={stations.stations}
                ></ReportDetailsEntry>
              );
            })
          ) : (
            <>Please select a subscription to view the subscribed reports...</>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default observer(ReportsDetails);

type ReportDetailsEntryProps = {
  report: Report;
  stations: Station[];
};
const ReportDetailsEntry: React.FC<ReportDetailsEntryProps> = observer(
  (props: ReportDetailsEntryProps) => {
    const reportStore = useContext(ReportContext);

    const station = props.stations.find(
      (station) => station.Station_ID === props.report.station_id
    );

    return (
      <div>
        <Row className='d-flex'>
          <Col>
            <span>
              Report Name:{' '}
              {
                reportStore.reportTypes.find(
                  (rt) => rt.id === props.report.report_type_id
                )?.name
              }
            </span>
            <br />
            <span>Report ID: {props.report.id}</span>
            <br />
            <span>Station: {station?.Logger_Name}</span>
            <br />
            <span>
              Station ID:{' '}
              <Link to={'/?id=' + props.report.station_id}>
                {props.report.station_id}
              </Link>
            </span>
            <br />
            <span>Send Time: {props.report.cron_expression}</span>
            <br />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            <Form>
              <Form.Group controlId='suspended'>
                <Row>
                  <Col>
                    <Form.Check
                      type='switch'
                      id={props.report.id.toString()}
                      label={props.report.suspended_at ? 'Suspended' : 'Active'}
                      onChange={() => reportStore.toggleReport(props.report)}
                      defaultChecked={props.report.suspended_at ? false : true}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
          <Col xs='auto' className='pr-0'>
            <DeleteReportModal report={props.report} />
          </Col>
          <Col xs='auto' className='pl-2'>
            <EditReportModal report={props.report} />
          </Col>
        </Row>
        <hr />
      </div>
    );
  }
);
