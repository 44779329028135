import { makeAutoObservable, runInAction } from 'mobx';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import StationStore from '../stations/StationStore';
import UserStore from '../user/UserStore';
import {
  MatchedMetrisStation,
  MetrisData,
  MetrisMetloads,
  Station
} from './MetrisUtils';
import {
  GetStationPointsResponse,
  GetStationsResponse
} from './model/MetrisAPI';
import { MetrisPoint } from './model/MetrisPoint';
import { MetrisStation } from './model/MetrisStation';

export class MetrisStore {
  // Stores.
  userStore: UserStore;
  stationStore: StationStore;

  metrisStations: MetrisStation[] = [];
  metrisData: MetrisData = {
    stations: []
  };
  metrisStationMetloads: MetrisMetloads[] = [];
  metrisMatchedStations: MatchedMetrisStation[] = [];

  selectedStationIndex: number | undefined;

  loadingMetrisStations = false;
  loadingAPIStations = false;
  loadingStationPoints = false;
  loadingMetrisStationMetloads = false;
  loadingRemovePoint = false;
  loadingAddPoint = false;
  loadingSetupMetrisStation = false;

  constructor(userStore: UserStore, stationStore: StationStore) {
    makeAutoObservable(this);
    this.userStore = userStore;
    this.stationStore = stationStore;
  }

  async loadStations() {
    runInAction(() => {
      this.loadingMetrisStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metris/metris-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as MetrisStation[];
      runInAction(() => {
        this.metrisStations = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingMetrisStations = false;
    });
  }

  async loadAPIStations() {
    runInAction(() => {
      this.loadingAPIStations = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metris/api/get-stations`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetStationsResponse;
      runInAction(() => {
        const stations: Station[] = data.map((station) => ({
          ...station,
          points: []
        }));
        this.metrisData.stations = stations;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAPIStations = false;
    });
  }

  async loadStationPoints(stationId: string) {
    runInAction(() => {
      this.loadingStationPoints = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metris/api/get-station-points?station_id=${stationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as GetStationPointsResponse;
      const stationIndex = this.metrisData.stations.findIndex(
        (station) => station.nodeId === stationId
      );
      this.metrisData.stations[stationIndex].points = data;
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingStationPoints = false;
    });
  }

  async loadMetrisStationMetloads(metrisStation: MetrisStation) {
    runInAction(() => {
      this.loadingMetrisStationMetloads = true;
    });

    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/metris/metloads/${metrisStation.id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      );
      const data = (await response?.json()) as MetrisMetloads[];
      runInAction(() => {
        this.metrisStationMetloads = data;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.loadingMetrisStationMetloads = false;
    });
  }

  async removePoint(
    metrisStationId: number,
    metrisPointId: number,
    pointId: string,
    measurementTypeId: string
  ) {
    runInAction(() => {
      this.loadingRemovePoint = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metris/remove-point/${metrisStationId}/${metrisPointId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'DELETE',
          body: JSON.stringify({
            variable_id: pointId,
            measurement_type_id: measurementTypeId
          })
        }
      );
      if (!response?.ok) throw Error('request failed');
      const data = (await response?.json()) as MetrisStation;
      runInAction(async () => {
        const metrisStationIndex = this.metrisStations.findIndex(
          (hs) => hs.id === metrisStationId
        );
        this.metrisStations = this.metrisStations.map((ms, i) => {
          if (i !== metrisStationIndex) return ms;
          return data;
        });
        if (this.selectedStation) {
          const station = { ...this.selectedStation };
          station.network = data;
          await this.getMetloadsMeasurements(station);
          this.updateStation(station);
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      throw new Error(error);
    }
    runInAction(() => {
      this.loadingRemovePoint = false;
    });
  }

  async addPoint(
    metrisStationId: number,
    body: Pick<MetrisPoint, 'name' | 'node_id' | 'units'> & {
      variable_id: string;
      measurement_type_id: string;
      multiplier: number;
      offset: number;
    }
  ): Promise<MetrisStation | undefined> {
    runInAction(() => {
      this.loadingAddPoint = true;
    });
    let data: MetrisStation | undefined;
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metris/add-point/${metrisStationId}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(body)
        }
      );
      if (!response?.ok) return undefined;
      const metrisStationData = (data = (await response?.json()) as MetrisStation);
      runInAction(() => {
        const metrisStationIndex = this.metrisStations.findIndex(
          (hs) => hs.id === metrisStationId
        );
        this.metrisStations = this.metrisStations.map((hs, i) => {
          if (i !== metrisStationIndex) return hs;
          return metrisStationData;
        });
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingAddPoint = false;
    });
    return data;
  }

  async setupMetrisStation(
    station: Station & {
      metris_node_id: string;
    }
  ) {
    runInAction(() => {
      this.loadingSetupMetrisStation = true;
    });
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/metris/setup-metris-station`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          },
          method: 'POST',
          body: JSON.stringify(station)
        }
      );
      const data = (await response?.json()) as MetrisStation;
      runInAction(() => {
        this.stationStore.addStation(data.station);
        this.metrisStations.push(data);
        const i = this.metrisMatchedStations.findIndex(
          (s) => s.station?.nodeId.toString() === station.metris_node_id
        );
        const matchedMetserviceStation = this.metrisMatchedStations[i];
        if (matchedMetserviceStation) {
          matchedMetserviceStation.network = data;
          const newMatchedMetserviceStations = [...this.metrisMatchedStations];
          newMatchedMetserviceStations[i] = matchedMetserviceStation;
          const sortedMatched = this.sortMatchedMetrisStations(
            newMatchedMetserviceStations
          );
          this.metrisMatchedStations = sortedMatched;
        }
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
    runInAction(() => {
      this.loadingSetupMetrisStation = false;
    });
  }

  /**
   * Match stations and stations by the stations ID.
   * Each station should be assigned to one station.
   * Not all stations will be assigned a station.
   */
  getMatchedMetrisStations() {
    const stations = this.metrisStations;
    const networks = this.metrisData.stations;

    let matched: MatchedMetrisStation[] = [];
    networks.forEach((station: Station) => {
      const networkId = station.nodeId;
      stations.forEach((network: MetrisStation) => {
        const nodeId = network.node_id;
        if (networkId === nodeId) {
          matched.push({
            station,
            network
          });
        } else {
          matched.push({
            station
          });
        }
      });
      // Handle case when a there are no stations.
      if (stations.length === 0) {
        matched.push({
          station
        });
      }
    });
    matched = this.sortMatchedMetrisStations(matched);
    runInAction(() => {
      this.metrisMatchedStations = matched;
    });
  }

  sortMatchedMetrisStations(matched: MatchedMetrisStation[]) {
    return matched
      .filter((matched) => {
        const matchedStation = matched.station;
        const stations = this.metrisData.stations;
        return stations.some(
          (station) => station.nodeId === matchedStation.nodeId
        );
      })
      .sort((a, b) => {
        const idA = a.network ? a.network.station_id : undefined;
        const idB = b.network ? b.network.station_id : undefined;
        if (!idA && idB) return 1;
        else if (!idB && idA) return -1;
        else if (!idA || !idB) return 0;
        return 0;
      })
      .filter((value, index, self) => {
        return (
          index ===
          self.findIndex((t) => t.station.nodeId === value.station.nodeId)
        );
      });
  }

  updateStation(station: MatchedMetrisStation) {
    const i = this.findStationIndex(station);
    if (i !== undefined) {
      const newMatchedMetrisStation = [...this.metrisMatchedStations];
      newMatchedMetrisStation[i] = station;
      this.metrisMatchedStations = this.sortMatchedMetrisStations(
        newMatchedMetrisStation
      );
    }
  }

  async getMetloadsMeasurements(matched: MatchedMetrisStation) {
    const currentStation = matched.network;
    if (!matched || !matched.station) {
      return;
    }
    // Load site measurements.
    await this.loadStationPoints(matched?.station.nodeId);
    // Load given station metloads.
    if (currentStation?.station) {
      await this.loadMetrisStationMetloads(currentStation);
      currentStation.metloadWithPoints = this.metrisStationMetloads;
    }
  }

  deSelectStation() {
    this.selectedStationIndex = undefined;
  }

  findStationIndex(station: MatchedMetrisStation): number | undefined {
    const i = this.metrisMatchedStations.findIndex((s) =>
      station.station && s.station
        ? station.station.nodeId === s.station.nodeId
        : station.network && s.network
        ? station.network.station_id === s.network.station_id
        : false
    );
    return i > -1 ? i : undefined;
  }

  hasSelectedStation(): boolean {
    return this.selectedStation?.station && this.selectedStation.station
      ? true
      : false;
  }

  set setSelectedStationIndex(station: MatchedMetrisStation) {
    this.selectedStationIndex = this.findStationIndex(station);
  }

  get selectedStationPoints() {
    return this.metrisData.stations.find(
      (g) => g.nodeId === this.selectedStation?.station.nodeId
    )?.points;
  }

  get selectedStation(): MatchedMetrisStation | undefined {
    return this.selectedStationIndex !== undefined &&
      this.selectedStationIndex > -1
      ? this.metrisMatchedStations[this.selectedStationIndex]
      : undefined;
  }

  get loading() {
    return (
      this.loadingMetrisStations ||
      this.loadingAPIStations ||
      this.loadingStationPoints ||
      this.loadingSetupMetrisStation ||
      this.loadingAddPoint ||
      this.loadingRemovePoint
    );
  }

  get loadingInitial() {
    return this.loadingMetrisStations || this.loadingAPIStations;
  }
}

