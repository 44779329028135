import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BomStationsContext } from '../../../../store/StoreContexts';
import ElementsTable from '../ElementsTable/ElementsTable';
import MetloadsTable from '../MetloadsTable/MetloadsTable';

const BomStationManager = () => {
  const bomStore = useContext(BomStationsContext);
  const stationElements = bomStore.selectedStationElements;
  const stationHasElements = stationElements && stationElements.length > 0;

  return bomStore.loadingStationElements ? (
    <Row>
      <Col>
        <Spinner animation='border' variant='primary' />
      </Col>
    </Row>
  ) : bomStore.hasSelectedStation() &&
    bomStore.selectedStation?.network?.metloadWithElements ? (
    <>
      <Row className='mt-3'>
        {stationHasElements ? (
          <>
            <Col xs={3}>
              <h4>Elements</h4>
              <ElementsTable />
            </Col>
            <Col xs={9}>
              <h4>Metloads</h4>
              <MetloadsTable />
            </Col>
          </>
        ) : null}
      </Row>
    </>
  ) : null;
};

export default observer(BomStationManager);

