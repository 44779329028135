import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { StationContext } from '../../../store/StoreContexts';
import CountriesTable from './CountriesTable';
import AddCountryModal from './add_country/AddCountryModal';

const CountriesRegions = () => {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      if (mounted) setLoading(true);
      await regionStore.fetchCountries();
      if (mounted) setLoading(false);
    };
    fetch();
    return () => {
      mounted = false;
    };
  }, [regionStore]);

  return (
    <Row className='mt-3'>
      <Col>
        {loading ? (
          <Row className='text-center mt-4'>
            <Col className='my-4'>
              <Spinner animation={'border'} />
            </Col>
          </Row>
        ) : (
          <Row className='mt-2'>
            <Col>
              <Row>
                <Col>
                  <AddCountryModal />
                </Col>
              </Row>
              <CountriesTable countries={regionStore.countries} />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default observer(CountriesRegions);

