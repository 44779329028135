import { UserAuthToken } from '../user/UserTypes';
import { UnproccessableEntityError } from './errors/UnprocessableEntityError';
import { Station, Status } from './StationTypes';

/**
 * Returns all station permitted by the provided bearer token.
 * @param token UserAuthToken containing JWT.
 */
export async function getStations(token: UserAuthToken): Promise<Station[]> {
  return await fetch(
    process.env.REACT_APP_METWATCH_API_URL +
      'api/admin/stations?with[]=metloads&with[]=networks&with[]=region.country',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      }
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else throw Error(response.statusText);
  });
}

/**
 * Returns a single station specified by the stationId.
 * @param token UserAuthToken containing JWT.
 * @param stationId Three character station ID code.
 */
export async function getStation(
  token: UserAuthToken,
  stationId: string
): Promise<Station> {
  return await fetch(
    process.env.REACT_APP_METWATCH_API_URL + 'api/admin/station/' + stationId,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      }
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else throw Error(response.statusText);
  });
}

/**
 * Updates a single station record with the station in body.
 * @param token UserAuthToken containing JWT.
 * @param stationId Three character station ID code.
 * @param station New station object to replace station at stationId.
 */
export async function updateStation(
  token: UserAuthToken,
  stationId: string,
  station: Station
) {
  return await fetch(
    process.env.REACT_APP_METWATCH_API_URL + 'api/admin/stations/' + stationId,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      },
      body: JSON.stringify(station)
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else if (response.status === 422)
      throw new UnproccessableEntityError(response.statusText, response.json());
    else throw Error(response.statusText);
  });
}

/**
 * Posts a new station object to the API.
 *
 * @param token UserAuthToken containing JWT.
 * @param station Station object.
 */
export async function createStation(token: UserAuthToken, station: Station) {
  return await fetch(
    process.env.REACT_APP_METWATCH_API_URL + 'api/admin/stations',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      },
      body: JSON.stringify(station)
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else if (response.status === 422)
      throw new UnproccessableEntityError(response.statusText, response.json());
    else throw Error(response.statusText); // Generic error.
  });
}

/**
 * Returns all station statuses permitted by the provided bearer token.
 * @param token UserAuthToken containing JWT.
 */
export async function getStatuses(token: UserAuthToken): Promise<Status[]> {
  return await fetch(
    process.env.REACT_APP_METWATCH_API_URL + 'api/admin/stationstatuses',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      }
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else throw Error(response.statusText);
  });
}

/**
 * Returns all station permitted by the provided bearer token.
 * @param token UserAuthToken containing JWT.
 */
export async function getStationNetworks(
  token: UserAuthToken
): Promise<Station[]> {
  return await fetch(
    process.env.REACT_APP_METWATCH_API_URL +
      'api/stations?with[]=networks&with[]=forecast',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer
      }
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else throw Error(response.statusText);
  });
}

