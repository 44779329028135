import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import {
  MetloadContext,
  WinetStationsContext
} from '../../../../../store/StoreContexts';
import { MetloadWithSensor } from '../../../../../store/winet_stations/model/WinetStation';
import VariableSelect from '../../../../utils/selects/VariableSelect';
import { removeSensor } from './utils';

interface Props {
  metload: MetloadWithSensor;
  formikProps: FormikProps<{
    metloads: MetloadWithSensor[];
  }>;
  index: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableRow = ({ metload, formikProps, index, setLoading }: Props) => {
  const metloadStore = useContext(MetloadContext);
  const WinetStore = useContext(WinetStationsContext);

  const variable = useMemo(
    () =>
      metloadStore.variables.find(
        (variable) => variable.Meteo_Var_ID === metload.MetVar_ID
      ),
    [metload.MetVar_ID, metloadStore.variables]
  );

  const measurementType = useMemo(
    () =>
      metloadStore.measurementTypes.find(
        (measurementType) =>
          measurementType.Meas_Type_ID === metload.MetMesTp_ID
      ),
    [metload.MetMesTp_ID, metloadStore.measurementTypes]
  );

  return (
    <tr key={metload.MetRec_Position}>
      <td>{metload.winetSensor.id}</td>
      <td>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={'sensor-name-tooltip'}>
              {metload.winetSensor.name}
            </Tooltip>
          }
        >
          <div>{metload.winetSensor.name}</div>
        </OverlayTrigger>
      </td>
      <td>
        <VariableSelect
          value={
            variable
              ? {
                  value: variable.Meteo_Var_ID,
                  label: variable.Meteo_Var_Menu
                }
              : undefined
          }
          onChange={(option: any) => {
            formikProps.setFieldValue(
              `metloads.${index}.MetVar_ID`,
              option?.value
            );
          }}
        />
      </td>
      <td>
        <Select
          options={metloadStore.measurementTypes.map((measurementType) => {
            return {
              value: measurementType.Meas_Type_ID,
              label: measurementType.Meas_Type_Name
            };
          })}
          value={
            measurementType
              ? {
                  value: measurementType.Meas_Type_ID,
                  label: measurementType.Meas_Type_Name
                }
              : undefined
          }
          onChange={(option) => {
            formikProps.setFieldValue(
              `metloads.${index}.MetMesTp_ID`,
              option?.value
            );
          }}
        />
      </td>
      <td>
        <Form.Control
          name={`metloads.${index}.Multiplier`}
          type='number'
          step={0.01}
          value={metload.Multiplier}
          onChange={formikProps.handleChange}
        />
      </td>
      <td>
        <Form.Control
          name={`metloads.${index}.Offset`}
          type='number'
          step={0.01}
          value={metload.Offset}
          onChange={formikProps.handleChange}
        />
      </td>
      <td>
        <Button
          variant='danger'
          size='sm'
          onClick={async () => {
            setLoading(true);
            await removeSensor(
              WinetStore,
              metload.winetSensor.winet_station_id,
              metload.winetSensor.id,
              metload.MetVar_ID,
              metload.MetMesTp_ID
            );
            setLoading(false);
          }}
        >
          <FontAwesomeIcon icon={faTrash} size='sm' />
        </Button>
      </td>
    </tr>
  );
};

export default observer(TableRow);

