import { BomStore } from '../../../../../store/bom_stations/BomStore';
import { errorToast, successToast } from '../../../../utils/ToastContainer';

/**
 * Sends an API request to remove a metload and updates the station
 * in the bomStore
 */
export const removeElement = async (
  bomStore: BomStore,
  bomStationId: number,
  bomElementId: number,
  variableId: string,
  measurementTypeId: string
) => {
  if (window.confirm('Are you sure you want to delete this measurement?')) {
    try {
      await bomStore.removeElement(
        bomStationId,
        bomElementId,
        variableId,
        measurementTypeId
      );
      successToast('Successfully deleted the element');
    } catch (error) {
      errorToast('Something went wrong deleting the measurement');
    }
  }
};

