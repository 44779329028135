import { sortBy, uniqBy } from 'lodash';
import { makeAutoObservable, runInAction } from "mobx";
import { fetchWithRefresh } from "../../utils/requests/RequestsHelpers";
import StationStore from "../stations/StationStore";
import { Station } from '../stations/StationTypes';
import { UnproccessableEntityError } from '../stations/errors/UnprocessableEntityError';
import UserStore from "../user/UserStore";
import { HilltopProvider, MatchedStationSite, RelevantStation, providerMap } from "./HilltopUtils";
import { HilltopMetloads } from "./models/HilltopMetloads";
import { HilltopSite } from "./models/HilltopSite";
import { HilltopSiteMeasurement } from "./models/HilltopSiteMeasurement";
import { HilltopStation } from "./models/HilltopStation";

export class HilltopStore {
  userStore: UserStore;
  stationStore: StationStore;

  provider: HilltopProvider | undefined;
  selectedStationIndex: number | undefined;
  selectedStationSiteMeasurements: HilltopSiteMeasurement[] = [];

  hilltopStations: HilltopStation[] = [];
  hilltopSites: HilltopSite[] = [];
  hilltopMatchedStationSites: MatchedStationSite[] = [];
  hilltopStationMetloads: HilltopMetloads[] = [];

  hilltopRelevantStations: RelevantStation[] = [];

  hilltopStationsLoading = false;
  hilltopSitesLoading = false;
  hilltopSiteMeasurementsLoading = false;
  hilltopStationMetloadsLoading = false;
  hilltopRelevantStationsLoading = false;

  constructor(userStore: UserStore, stationStore: StationStore) {
    makeAutoObservable(this);

    this.userStore = userStore;
    this.stationStore = stationStore;
  }

  set setProvider(provider: HilltopProvider | undefined) {
    this.provider = provider;
  }

  set setSelectedStationIndex(station: MatchedStationSite) {
    this.selectedStationIndex = this.findStationIndex(station);
  }

  get getProviderName() {
    if (this.provider) {
      return providerMap.get(this.provider);
    }
    return undefined;
  }

  get selectedStation(): MatchedStationSite | undefined {
    return this.selectedStationIndex !== undefined &&
      this.selectedStationIndex > -1
      ? this.hilltopMatchedStationSites[this.selectedStationIndex]
      : undefined;
  }

  findStationIndex(station: MatchedStationSite): number | undefined {
    const i = this.hilltopMatchedStationSites.findIndex((s) =>
      station.site && s.site
        ? station.site.name === s.site.name
        : station.station && s.station
          ? station.station.station_id === s.station.station_id
          : false
    );

    return i > -1 ? i : undefined;
  }

  hasSelectedStation(): boolean {
    return this.selectedStation?.station && this.selectedStation.site ? true : false;
  }

  /**
   * 
   * @param id 
   * @returns 
   */
  getHilltopStation(id: number) {
    return this.hilltopStations.find(hts => hts.id === id);
  }

  updateStation(station: MatchedStationSite) {
    const i = this.findStationIndex(station);

    if (i !== undefined) {
      const newMatchedStationSite = [...this.hilltopMatchedStationSites];
      newMatchedStationSite[i] = station;
      this.hilltopMatchedStationSites = newMatchedStationSite;
    }
  }

  /**
   * Match sites and stations by name.
   * Each station should be assigned to one site.
   * Not all sites will be assigned a station.
   */
  getMatchedStationsSites() {
    const stations = this.hilltopStations;
    const sites = this.hilltopSites;

    let matched: MatchedStationSite[] = [];
    sites.forEach((site: HilltopSite) => {
      const siteName = site.name;
      stations.forEach((station: HilltopStation) => {
        const stationName = station.name;
        if (siteName === stationName) {
          matched.push({
            site,
            station
          })
        } else {
          matched.push({
            site
          })
        }
      });
      // Handle case when a there are no stations.
      if (stations.length === 0) {
        matched.push({
          site
        })
      }
    });
    matched = uniqBy(matched, function (o) {
      return [o.site.name, o.station?.name].join();
    });
    matched = sortBy(matched, [function (o) { return o.station }]);
    runInAction(() => {
      this.hilltopMatchedStationSites = matched;
    });
  }

  async getMetloadsMeasurements(matched: MatchedStationSite) {
    const provider = this.provider;
    const currentStation = matched.station;
    if (!matched || !matched.station) {
      return
    }
    // Load site measurements.
    await this.loadSiteMeasurements(provider, matched?.site);
    // Load given station metloads.
    if (currentStation?.station) {
      await this.loadHilltopStationMetloads(currentStation);
      currentStation.metloadsWithMeasurements = this.hilltopStationMetloads;
    }
  }

  /**
   * 
   */
  async loadStations(provider: HilltopProvider | undefined) {
    runInAction(() => {
      this.hilltopStationsLoading = true;
    })

    try {
      const response = await fetchWithRefresh(this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/hilltop/hilltop-stations?
				provider=${provider}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      )
      const data = await response?.json() as HilltopStation[];
      runInAction(() => {
        this.hilltopStations = data
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.hilltopStationsLoading = false;
    });
  }

  /**
   * 
   * @param provider 
   */
  async loadSites(provider: HilltopProvider | undefined) {
    runInAction(() => {
      this.hilltopSitesLoading = true;
    });

    try {
      const response = await fetchWithRefresh(this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/hilltop/api/get-site-list?
				provider=${provider}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      )
      const data = await response?.json();
      const sites: HilltopSite[] = data['Site'].map((site: any) => {

        return {
          name: site['@attributes']['Name'],
          latitude: parseFloat(site.Latitude) || '',
          longitude: parseFloat(site.Longitude) || ''
        }
      });
      runInAction(() => {
        this.hilltopSites = sites;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.hilltopSitesLoading = false;
    })
  }

  /**
   * 
   * @param provider 
   * @param site 
   */
  async loadSiteMeasurements(provider: HilltopProvider | undefined, site: HilltopSite) {
    runInAction(() => {
      this.hilltopSiteMeasurementsLoading = true;
    })

    try {
      const response = await fetchWithRefresh(this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/hilltop/api/get-site-measurement-list?
				provider=${provider}&
				site=${site.name}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      )
      const data = await response?.json();
      const hilltopSiteMeasurements: HilltopSiteMeasurement[] = []
      data['DataSource'].forEach((source: any, i: number) => {
        const measurements = source['Measurement'];
        if (measurements) {
          if (measurements instanceof Array) {
            measurements.forEach((measurement: any) => {
              if (measurement['@attributes']['Name']) {
                hilltopSiteMeasurements.push({
                  name: measurement['@attributes']['Name']
                });
              }
            });
          } else {
            return hilltopSiteMeasurements.push({
              name: measurements['@attributes']['Name']
            });
          }
        }
      })
      runInAction(() => {
        this.selectedStationSiteMeasurements = hilltopSiteMeasurements;
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.hilltopSiteMeasurementsLoading = false;
    })
  }

  /**
   * 
   * @param hilltopStation 
   */
  async loadHilltopStationMetloads(hilltopStation: HilltopStation) {
    runInAction(() => {
      this.hilltopStationMetloadsLoading = true;
    });

    try {
      const response = await fetchWithRefresh(this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/hilltop/metloads/${hilltopStation.id}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      )
      const data = await response?.json() as HilltopMetloads[];
      runInAction(() => {
        this.hilltopStationMetloads = data
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.hilltopStationMetloadsLoading = false;
    })
  }

  async loadRelevantStations(provider: HilltopProvider) {
    runInAction(() => {
      this.hilltopRelevantStationsLoading = true;
    });

    try {
      const response = await fetchWithRefresh(this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}
				api/admin/weather-providers/hilltop/relevant-hilltop-stations?provider=${provider}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
          }
        }
      )
      const data = await response?.json() as RelevantStation[] || []
      runInAction(() => {
        this.hilltopRelevantStations = data
      });
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }

    runInAction(() => {
      this.hilltopRelevantStationsLoading = false;
    });
  }

  async createHilltopStation(
    station: Station & {
      hilltop_station_agency: string | null | undefined,
      hilltop_site_name: string;
      hilltop_site_setup: 0 | 1
    }
  ) {
    const response = await fetchWithRefresh(
      this.userStore,
      process.env.REACT_APP_METWATCH_API_URL +
      'api/admin/weather-providers/hilltop/setup-hilltop-station',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.userStore.user?.userToken.jwt_bearer
        },
        body: JSON.stringify(station)
      }
    );
    if (response?.ok) {
      const data = await response.json();
      runInAction(() => {
        this.stationStore.addStation(data.station);
        const i = this.hilltopMatchedStationSites.findIndex(
          (s) => s.site?.name === station.hilltop_site_name
        );
        const matchedHilltopStation = this.hilltopMatchedStationSites[i];
        if (matchedHilltopStation) {
          matchedHilltopStation.station = data;
          const newMatchedHilltopStations = [...this.hilltopMatchedStationSites];
          newMatchedHilltopStations[i] = matchedHilltopStation;
          const sortedMatched = sortBy(newMatchedHilltopStations,
            [function (o) { return o.station }]
          );
          this.hilltopMatchedStationSites = sortedMatched;
        }
      });
    }
    else if (response?.status === 422)
      throw new UnproccessableEntityError(
        response.status + response.statusText,
        await response.json()
      );
    else throw new Error('Server error: ' + response?.statusText);
  }
}