import { MetloadWithSensor } from '../../../../store/winet_stations/model/WinetStation';
import { WinetStore } from '../../../../store/winet_stations/WinetStore';

/**
 * Returns the metloads which are different to those in the WinetStore
 * selected station.
 *
 * Only compares the following keys because they are the only ones that can
 * be changed:
 *  - MetVar_ID
 *  - MetMesTp_ID
 *  - Multiplier
 *  - Offset
 */
export const filterChangedMetloads = (
  WinetStore: WinetStore,
  metloads: MetloadWithSensor[]
) => {
  const initialMetloads =
    WinetStore.selectedStation?.network?.metloadWithSensors;

  if (!initialMetloads) return metloads;

  return metloads.filter((metload) => {
    const initialMetload = initialMetloads.find((m) => m.id === metload.id);
    return (
      !initialMetload ||
      metload.MetVar_ID !== initialMetload.MetVar_ID ||
      metload.MetMesTp_ID !== initialMetload.MetMesTp_ID ||
      metload.Multiplier !== initialMetload.Multiplier ||
      metload.Offset !== initialMetload.Offset
    );
  });
};

