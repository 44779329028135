import { ApmBase, init as initApm } from '@elastic/apm-rum';

// Initialises the APM agent as soon as this module is imported.
var apm = initApm({
  serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
  serverUrl: process.env.REACT_APP_APM_URL,
  serviceVersion: process.env.REACT_APP_SOURCE_VERSION,
  distributedTracingOrigins: [
    process.env.REACT_APP_AUTH_API_URL!,
    process.env.REACT_APP_METWATCH_API_URL!,
    process.env.REACT_APP_MDR_API_URL!
  ],
  environment: process.env.REACT_APP_ENVIRONMENT
});

/**
 * Associates the current APM session with the currently authenticated user.
 * @param apm Current APM object.
 * @param userId Current user ID.
 */
export function setupApmUser(apm: ApmBase, userId: number | undefined) {
  apm.setUserContext({
    id: userId
  });
}

export default apm;
