import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import Select from 'react-select';
import { StationContext } from '../../../../store/StoreContexts';
import { StationSelectorProps } from './StationSelectorUtils';

require('./stationselector.scss');

/**
 * Status selector component. Sets value to status id and displays status id,
 * name and then the severity number
 */
const StatusSelector: React.FC<StationSelectorProps> = (props) => {
  const { setFieldValue, handleBlur } = useFormikContext();
  const stationsStore = useContext(StationContext);
  const options = stationsStore.statuses.map((status) => {
    return {
      value: status.id,
      label: `${status.id} (${status.description}, Severity: ${status.severity})`
    };
  });
  return (
    <Select
      name={props.name}
      options={options}
      onChange={(opt) => {
        // We cannot pass in the regular onChange handler from formik - instead
        // we must directly call setFieldValue on the appropriate field.
        setFieldValue(props.name, opt?.value);
      }}
      onBlur={handleBlur}
      value={options.find((opt) => opt.value === props.value)}
      className={props.isInvalid ? 'is-invalid' : ''}
      styles={
        props.isInvalid
          ? {
              control: (provided, _) => ({
                ...provided,
                borderColor: '#dc3545'
              })
            }
          : {}
      }
    ></Select>
  );
};

export default observer(StatusSelector);

