import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { StationValidationSchema } from '../../../../store/stations/StationTypes';
import { UnproccessableEntityError } from '../../../../store/stations/errors/UnprocessableEntityError';
import {
  StationCreateCardProps,
  getEmptyFormStation
} from '../WeatherStationTypes';
import StationForm from './StationForm';

/**
 * This component wraps the StationForm component. This provides the
 * outer Card component and headers, as well as functionality for interacting
 * with the WeatherStationManager page.
 *
 * @param props Props for WeatherStationEdit and editing state from manager.
 */
export default function StationCreateCard(props: StationCreateCardProps) {
  return (
    <Formik
      enableReinitialize
      validateOnBlur
      validateOnMount
      validateOnChange
      initialValues={getEmptyFormStation()}
      validationSchema={StationValidationSchema}
      onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
        setTimeout(() => {
          // onSubmit will return any 422 errors from API.
          props
            .onSubmit(values)
            .then(() => {
              resetForm(); // Reset the form if successful.
            })
            .catch((error: UnproccessableEntityError) => {
              // Finally, deal with errors returned from API. These have priority
              // over client side validation.
              error.errors.then((data: any) => {
                for (var err in data.errors) {
                  console.log(data.errors);
                  setFieldError(err, data.errors[err][0]);
                }
              });
            });

          setSubmitting(false);
        }, 400);
      }}
    >
      {(formikProps) => (
        <>
          <Modal show={props.visible} onHide={props.onDiscard}>
            <Modal.Header>
              <span>Create Station</span>
            </Modal.Header>
            <Modal.Body>
              <StationForm formikProps={formikProps}></StationForm>
            </Modal.Body>
            <Modal.Footer>
              <Col>
                {Object.keys(formikProps.errors).length > 0 ? (
                  <span style={{ fontSize: 12, color: 'red', float: 'left' }}>
                    Invalid station
                  </span>
                ) : (
                  <></>
                )}
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type='submit'
                  className={
                    Object.keys(formikProps.errors).length > 0
                      ? 'btn-outline-danger btn-danger btn-sm m-1 disabled'
                      : 'btn-primary btn-sm m-1'
                  }
                  onClick={formikProps.submitForm}
                >
                  Save
                </Button>
                <Button
                  type='button'
                  className='btn btn-secondary btn-sm m-1'
                  onClick={() => {
                    formikProps.resetForm();
                    props.onDiscard();
                  }}
                >
                  Discard
                </Button>
              </Col>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Formik>
  );
}

