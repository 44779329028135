import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Formik, Form as FormikForm } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import Select from 'react-select';
import {
  HarvestStationsContext,
  MetloadContext,
  UserContext
} from '../../../../store/StoreContexts';
import { fetchWithRefresh } from '../../../../utils/requests/RequestsHelpers';
import LoadingOverlay from '../../../utils/LoadingOverlay';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import VariableSelect from '../../../utils/selects/VariableSelect';
import {
  MatchedHarvestStation,
  MetloadWithHarvestTrace
} from '../utils/HarvestTypes';
import AddMeasurementModal from './AddMeasurementModal';

/**
 * This is a form rendered as a table, the user can modify any met_metload value
 */
const MetloadsTable = () => {
  const userStore = useContext(UserContext);
  const harvestStationsStore = useContext(HarvestStationsContext);
  const metloadStore = useContext(MetloadContext);

  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Sends an API request to remove a metload and updates the station
   * in the harvestStationsStore
   *
   * @param {string} variableId
   * @param {string} measurementTypeId
   */
  const removeMeasurment = (variableId: string, measurementTypeId: string) => {
    if (window.confirm('Are you sure you want to delete this measurement?')) {
      setLoading(true);
      fetchWithRefresh(
        userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/harvest/remove-measurement/${harvestStationsStore.selectedStation?.station?.id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${userStore.user?.userToken.jwt_bearer}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            variable_id: variableId,
            measurement_type_id: measurementTypeId
          })
        }
      )
        .then((res) => {
          if (res?.ok) return res.json();
          else throw res?.statusText;
        })
        .then(async (data) => {
          // Returns the harvest station model so update the selected station
          // in the harvestStationsStore with the new data
          if (harvestStationsStore.selectedStation) {
            const station = { ...harvestStationsStore.selectedStation };
            station.station = data;
            await harvestStationsStore.fetchMetloadWithHarvestTrace(station);
            harvestStationsStore.updateStation(station);
            successToast('Successfully deleted the measurement');
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV === 'development') console.error(err);
          errorToast('Something went wrong deleting the measurement');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  /**
   * Returns the metloads which are different to those in the harvestStationsStore
   * selected station.
   *
   * Only compares the following keys because they are the only ones that can
   * be changed:
   *  - MetVar_ID
   *  - MetMesTp_ID
   *  - Multiplier
   *  - Offset
   *
   * @param {MetloadWithHarvestTrace[]} metloads
   * @returns {MetloadWithHarvestTrace[]}
   */
  const filterChangedMetloads = (metloads: MetloadWithHarvestTrace[]) => {
    const initialMetloads =
      harvestStationsStore.selectedStation?.station?.metloadsWithHarvestTraces;

    if (!initialMetloads) return metloads;

    return metloads.filter((metload) => {
      const initialMetload = initialMetloads.find((m) => m.id === metload.id);
      return (
        !initialMetload ||
        metload.MetVar_ID !== initialMetload.MetVar_ID ||
        metload.MetMesTp_ID !== initialMetload.MetMesTp_ID ||
        metload.Multiplier !== initialMetload.Multiplier ||
        metload.Offset !== initialMetload.Offset
      );
    });
  };

  return harvestStationsStore.selectedStation?.site &&
    harvestStationsStore.selectedStation?.station ? (
    <Formik
      enableReinitialize
      initialValues={{
        metloads: harvestStationsStore.selectedStation.station
          .metloadsWithHarvestTraces
          ? harvestStationsStore.selectedStation.station
              .metloadsWithHarvestTraces
          : []
      }}
      onSubmit={(values, actions) => {
        fetchWithRefresh(
          userStore,
          `${process.env.REACT_APP_METWATCH_API_URL}api/metload/update-metloads`,
          {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${userStore.user?.userToken.jwt_bearer}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              metloads: filterChangedMetloads(values.metloads)
            })
          }
        )
          .then((res) => {
            if (res?.ok) return res.json();
            else throw Error(res?.statusText);
          })
          .then((data) => {
            // Pass each metload that was updated into the selected station
            const newStation: MatchedHarvestStation = {
              ...harvestStationsStore.selectedStation
            };

            data.forEach((newMetload: any) => {
              const i = newStation.station!.metloadsWithHarvestTraces!.findIndex(
                (metload) => metload.id === newMetload.id
              );

              /**
               * Merge so that the metload doesn't lose the harvestTrace
               * attribute which the API route doesn't return
               * (it can't reutrn this because it isn't a harvest specific route)
               */
              if (i > -1)
                newStation.station!.metloadsWithHarvestTraces![i] = {
                  ...newStation.station!.metloadsWithHarvestTraces![i],
                  ...newMetload
                };
            });

            harvestStationsStore.updateStation(newStation);
            successToast('Successfully updated the metloads');
          })
          .catch((err) => {
            if (process.env.NODE_ENV === 'development') console.error(err);
            errorToast('Something went wrong updating the metloads');
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const changedMetloads = filterChangedMetloads(
          formikProps.values.metloads
        );

        return (
          <FormikForm>
            {formikProps.isSubmitting ? <LoadingOverlay /> : null}
            <Table size='sm' bordered striped hover>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>ID</th>
                  <th style={{ width: '10%' }}>Trace ID</th>
                  <th style={{ width: '22.5%' }}>Trace Name</th>
                  <th style={{ width: '22.5%' }}>Variable</th>
                  <th style={{ width: '20%' }}>Measurement</th>
                  <th style={{ width: '10%' }}>Multiplier</th>
                  <th style={{ width: '10%' }}>Offset</th>
                  <th style={{ width: '5%' }} />
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name='metloads'
                  render={() =>
                    loading ? (
                      <LoadingOverlay />
                    ) : harvestStationsStore.selectedStation?.station ? (
                      formikProps.values.metloads.map((metload, index) => {
                        const variable = metloadStore.variables.find(
                          (variable) =>
                            variable.Meteo_Var_ID === metload.MetVar_ID
                        );
                        const measurementType = metloadStore.measurementTypes.find(
                          (measurementType) =>
                            measurementType.Meas_Type_ID === metload.MetMesTp_ID
                        );
                        return (
                          <tr key={metload.MetRec_Position}>
                            <td>{metload.harvestTrace.id}</td>
                            <td>{metload.harvestTrace.trace_id}</td>
                            <td>{metload.harvestTrace.name}</td>
                            <td>
                              <VariableSelect
                                value={
                                  variable
                                    ? {
                                        value: variable.Meteo_Var_ID,
                                        label: variable.Meteo_Var_Menu
                                      }
                                    : undefined
                                }
                                onChange={(option: any) => {
                                  formikProps.setFieldValue(
                                    `metloads.${index}.MetVar_ID`,
                                    option?.value
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                options={metloadStore.measurementTypes.map(
                                  (measurementType) => {
                                    return {
                                      value: measurementType.Meas_Type_ID,
                                      label: measurementType.Meas_Type_Name
                                    };
                                  }
                                )}
                                value={
                                  measurementType
                                    ? {
                                        value: measurementType.Meas_Type_ID,
                                        label: measurementType.Meas_Type_Name
                                      }
                                    : undefined
                                }
                                onChange={(option) => {
                                  formikProps.setFieldValue(
                                    `metloads.${index}.MetMesTp_ID`,
                                    option?.value
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                name={`metloads.${index}.Multiplier`}
                                type='number'
                                step={0.01}
                                value={metload.Multiplier}
                                onChange={formikProps.handleChange}
                              />
                            </td>
                            <td>
                              <Form.Control
                                name={`metloads.${index}.Offset`}
                                type='number'
                                step={0.01}
                                value={metload.Offset}
                                onChange={formikProps.handleChange}
                              />
                            </td>
                            <td>
                              <Button
                                variant='danger'
                                size='sm'
                                onClick={() =>
                                  removeMeasurment(
                                    metload.MetVar_ID,
                                    metload.MetMesTp_ID
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    ) : null
                  }
                />
              </tbody>
            </Table>
            <div className='d-flex justify-content-center'>
              <AddMeasurementModal variant='outline-primary' className='mx-2'>
                Add Measurement
              </AddMeasurementModal>
              {changedMetloads.length > 0 ? (
                <Button variant='primary' type='submit' className='mx-2'>
                  Submit
                </Button>
              ) : null}
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  ) : (
    <>Invalid Station</>
  );
};

export default observer(MetloadsTable);

