import { MeasurementType, Metload, Variable } from '../metload/MetloadTypes';
import { Sensor, Station as WinetAPIStation } from './model/WinetAPI';
import { WinetSensor } from './model/WinetSensor';
import { WinetStation } from './model/WinetStation';

export interface DisplaySensor extends Sensor {
  displayName: string;
}

export interface Station extends WinetAPIStation {
  sensors: DisplaySensor[];
  latitude: number;
  longitude: number;
  elevation: number;
}

export interface WinetData {
  stations: Station[];
}

export interface MatchedWinetStation {
  station: Station;
  network?: WinetStation;
}

export type WinetMetloads = Metload & {
  meas_type: MeasurementType;
  variable: Variable;
  winetSensor: WinetSensor;
};

const normalizeString = (str: string): string =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s/g, '');

// Normalized sensor type and categories translated to an English display name.
const sensorNameTranslations = {
  intensitapioggia: 'Rain Intensity',
  pluviometro: 'Rain',
  direzionevento: 'Wind Direction',
  pioggiacumulata: 'Rain Cumulation',
  anemometro: 'Anemometer',
  velocitavento: 'Wind Speed',
  temperaturaaria: 'Air Temperature',
  temperaturaariasht11: 'Air Temperature',
  umiditaaria: 'Humidity',
  umiditaariasht11: 'Humidity',
  rafficavento: 'Wind Gust',
  rafficadelvento: 'Wind Gust',
  direzioneraffica: 'Wind Gust Direction',
  bagnaturafogliare: 'Leaf Wetness',
  radiazionesolare: 'Solar Radiation',
  radiazionesolaredavis12b: 'Solar Radiation'
};

export const translateSensor = (sensor: string) => {
  const normalizedSensor = normalizeString(
    sensor
  ).toLocaleLowerCase() as keyof typeof sensorNameTranslations;
  return sensorNameTranslations[normalizedSensor] || sensor;
};

