import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { WinetStationsContext } from '../../../../store/StoreContexts';
import {
  Station,
  StationValidationSchema
} from '../../../../store/stations/StationTypes';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import { getEmptyFormStation } from '../../stations/WeatherStationTypes';
import StationForm from '../../stations/forms/StationForm';

const LinkWinetNetworkForm = () => {
  const winetStore = useContext(WinetStationsContext);
  const winetStation = winetStore.selectedStation;
  const winetSiteId = winetStation?.station.netId.toString();

  // Sorts the winet stations by number after O prefix (e.g. O21 > O20)
  const sortWinetStation = (a: Station, b: Station) => {
    return parseInt(b.Station_ID.substring(1, 3)) <
      parseInt(a.Station_ID.substring(1, 3))
      ? a
      : b;
  };

  // Calculates a suggested ID for the winet station by increasing the value of the last winet
  // station ID by one.
  const suggestedId = useMemo(() => {
    const providerLetter = 'W';
    const winetStations = winetStore.stationStore.stations
      .slice()
      .filter((s) => /W[0-9][0-9]/.test(s.Station_ID));
    // Handle case when there are no Winet Stations.
    if (winetStations.length === 0) return 'W01';
    //
    const lastStation = winetStations.reduce(sortWinetStation);
    const isSingleDigit = lastStation?.Station_ID.substring(1, 2) === '0';
    const lastStationNumber = isSingleDigit
      ? lastStation?.Station_ID.substring(2, 3)
      : lastStation?.Station_ID.substring(1, 2);
    const last = parseInt(lastStationNumber) + 1;
    // Returns the next suggested Winet station ID if there are some left, otherwise an empty string.
    if (last >= 99) return '';
    if (last < 10) return `${providerLetter}0${last}`;
    return `${providerLetter}${last}`;
  }, [winetStore.stationStore.stations]);

  // There's a issue with setting this to type 'FormValues' so
  // is set to any.
  const initialValues: any = {
    ...getEmptyFormStation(),
    winet_net_id: winetSiteId || '',
    winet_site_setup: 0,
    Station_ID: suggestedId,
    Logger_Name: winetStation!.station!.netName,
    Logger_Type: 'Winet',
    Logger_Description: `Winet - ${winetStation!.station!.netName} - ${
      winetStation?.station.netId
    }`,
    Latitude: winetStation?.station.latitude,
    Longitude: winetStation?.station.longitude,
    Altitude: winetStation?.station.elevation,
    timezone: 'Pacific/Auckland', // Winet stations are in NZ.
    is_utc: 'Y'
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={StationValidationSchema}
        onSubmit={async (values, { setFieldError }) => {
          // Await the create requests to ensure 422s are caught.
          try {
            await winetStore.setupWinetStation(values);
            winetStore.deSelectStation();
            successToast(`Winet station successfully created.`);
          } catch (error: any) {
            // Check for unprocesable entity
            if (error.message.includes(422)) {
              error.errors.then((data: any) => {
                for (var err in data.errors) {
                  setFieldError(err, data.errors[err][0]);
                }
              });
              errorToast(`Error creating Winet station.`);
            } else throw error;
          }
        }}
      >
        {(formikProps) => (
          <>
            <StationForm
              formikProps={formikProps}
              removed={{ Active: true, Logger_Type: true, WMONumber: true }}
            />
            <Button
              onClick={() => {
                formikProps.handleSubmit();
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Formik>
    </>
  );
};

export default observer(LinkWinetNetworkForm);

