import { HaloStore } from '../../../../../store/halo_stations/HaloStore';
import { errorToast, successToast } from '../../../../utils/ToastContainer';

/**
 * Sends an API request to remove a metload and updates the station
 * in the haloStore
 */
export const removeProperty = async (
  haloStore: HaloStore,
  haloStationId: number,
  haloPropertyId: number,
  variableId: string,
  measurementTypeId: string
) => {
  if (window.confirm('Are you sure you want to delete this measurement?')) {
    try {
      await haloStore.removeProperty(
        haloStationId,
        haloPropertyId,
        variableId,
        measurementTypeId
      );
      successToast('Successfully deleted the property');
    } catch (error) {
      errorToast('Something went wrong deleting the measurement');
    }
  }
};

