import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo, useState } from 'react';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { StationContext } from '../../../../store/StoreContexts';
import { Region } from '../../../../store/regions/RegionsTypes';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import ModalButton, {
  ToggleModalContext
} from '../../../utils/modal/ModalButton';

interface Props {
  region: Region;
}

const DeleteRegionModal = ({ region }: Props) => {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;
  const [loading, setLoading] = useState(false);

  const countryName = useMemo(
    () => regionStore.getCountry(region.country_id)?.name,
    [region.country_id, regionStore]
  );

  return (
    <ModalButton
      buttonContents={<FontAwesomeIcon icon={faTrash} />}
      buttonProps={{ variant: 'outline-danger', size: 'sm' }}
    >
      <Modal.Header closeButton>
        <b>Delete Region?</b>
      </Modal.Header>
      <Modal.Body>
        <Row className='mb-1'>
          <Col>{`Are you sure you want to delete this region from ${countryName}?`}</Col>
        </Row>
        <Row>
          <Col>
            <b>{`${region.name}`}</b>
          </Col>
        </Row>
        <ToggleModalContext.Consumer>
          {(toggleModal) => (
            <Row className='float-right mt-1'>
              <Col>
                <Button
                  variant='danger'
                  size='sm'
                  onClick={async () => {
                    setLoading(true);
                    try {
                      await regionStore.deleteRegion(
                        region.country_id,
                        region.id
                      );
                      successToast(
                        'Successfully deleted region.',
                        'delete-region-success'
                      );
                      toggleModal && toggleModal();
                    } catch (error) {
                      errorToast(
                        'Failed to delete region.',
                        'delete-region-error'
                      );
                    }
                    setLoading(false);
                  }}
                >
                  {loading ? (
                    <Spinner className='mx-3' animation={'border'} size='sm' />
                  ) : (
                    'Confirm'
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </ToggleModalContext.Consumer>
      </Modal.Body>
    </ModalButton>
  );
};

export default observer(DeleteRegionModal);

