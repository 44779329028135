import { MatchedHarvestStation } from '../../components/tools/harvest_stations/utils/HarvestTypes';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import { Station } from '../stations/StationTypes';
import UserStore from '../user/UserStore';

export function createHarvestStationRequest(
  userStore: UserStore,
  station: Station & { harvest_site_id: number; harvest_site_setup: 0 | 1 }
) {
  return fetchWithRefresh(
    userStore,
    process.env.REACT_APP_METWATCH_API_URL +
      'api/admin/weather-providers/harvest/setup-harvest-station',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer
      },
      body: JSON.stringify(station)
    }
  );
}
