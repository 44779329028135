import { MeasurementType, Metload, Variable } from '../metload/MetloadTypes';
import { Station as BomAPIStation, Element } from './model/BomAPI';
import { BomElement } from './model/BomElement';
import { BomStation } from './model/BomStations';

export interface Station extends BomAPIStation {
  elements: Element[];
}

export interface BomData {
  stations: Station[];
}

export interface MatchedBomStation {
  station: Station; // The BOM station from the SFTP server.
  network?: BomStation; // The BOM station stored in MetWatch.
}

export type BomMetloads = Metload & {
  meas_type: MeasurementType;
  variable: Variable;
  bomElement: BomElement;
};

export const bomFileIdArray = [
  'IDD60920',
  'IDN60920',
  'IDQ60920',
  'IDS60920',
  'IDT60920',
  'IDV60920',
  'IDW60920'
] as const;
export type BomFileId = typeof bomFileIdArray[number];
export function isBomFileId(str: string): str is BomFileId {
  return bomFileIdArray.includes(str as BomFileId);
}

export const fileIdMap = new Map<BomFileId, string>([
  [bomFileIdArray[0], 'Northern Territory'],
  [bomFileIdArray[1], 'New South Wales'],
  [bomFileIdArray[2], 'Queensland'],
  [bomFileIdArray[3], 'South Australia'],
  [bomFileIdArray[4], 'Tasmania'],
  [bomFileIdArray[5], 'Victoria'],
  [bomFileIdArray[6], 'Western Australia']
]);

