import Cookies from 'js-cookie';
import { UserAuthToken } from './UserTypes';

/**
 * Saves the JWT refresh token as a cookie for session persistence.
 * @param token UserAuthToken containing bearer and refresh tokens. Note that
 *    the bearer token is not saved in the cookie.
 */
export function saveRefreshToken(token: UserAuthToken) {
  Cookies.set('jwt-refresh', token.jwt_refresh!, {
    sameSite: 'strict',
    secure: true
  });
}

/**
 * Get the refresh token from the cookie. If it does not exist, returns
 * undefined.
 */
export function getRefreshToken(): string | undefined {
  // Currently storing as a standard cookie, not HttpOnly.
  return Cookies.get('jwt-refresh');
}

/**
 * Removes the JWT refresh token. Used on logout.
 */
export function removeRefreshToken() {
  Cookies.remove('jwt-refresh');
}

/**
 * Checks whether a refresh token exists. Returns true if so, false otherwise.
 */
export function refreshTokenExists(): boolean {
  return Cookies.get('jwt-refresh') !== undefined;
}

/**
 * Sets the remember me cookie. Stores remember as string. Also saves to
 * session storage.
 * @param remember True if set to remember, false otherwise.
 */
export function setRememberMe(remember: boolean) {
  window.sessionStorage.setItem('remember', remember ? 'true' : 'false');
  Cookies.set('remember', remember ? 'true' : 'false', {
    sameSite: 'strict',
    secure: true
  });
}

/**
 * Gets the remember cookie. Returns true if should remember, false otherwise.
 */
export function getRememberMe(): boolean {
  return Cookies.get('remember') === 'true';
}
