import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useState } from 'react';
import { Button, ButtonProps, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { MetserviceStationsContext } from '../../../../store/StoreContexts';
import { UnproccessableEntityError } from '../../../../store/stations/errors/UnprocessableEntityError';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import MeasurementForm from './MeasurementForm/MeasurementForm';
import { FormValues } from './utils';

type Props = ButtonProps;

const AddMeasurementModal = (buttonProps: Props) => {
  const metserviceStore = useContext(MetserviceStationsContext);
  const [show, setShow] = useState(false);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      actions.setSubmitting(true);
      try {
        if (metserviceStore.selectedStation) {
          const station = { ...metserviceStore.selectedStation };
          const data = await metserviceStore.addVariable(
            station?.site?.id || 0,
            {
              name: values.variable.name,
              variable_id: values.variable_id,
              measurement_type_id: values.measurement_type_id,
              multiplier: values.multiplier,
              offset: values.offset
            }
          );
          if (data) {
            station.site = data;
            await metserviceStore.getMetloadsMeasurements(station);
            metserviceStore.updateStation(station);
            setShow(false);
            successToast('Successfully added the measurement');
          } else {
            errorToast('Failed to add a variable.');
          }
        }
      } catch (error: any) {
        if (process.env.NODE_ENV === 'development') console.error(error);
        if (error instanceof UnproccessableEntityError) {
          const errors = error.errors.errors;
          errors.forEach((err: string) =>
            actions.setFieldError(err, errors[err][0])
          );
        } else {
          errorToast('Something went wrong adding the measurement');
        }
        actions.setSubmitting(false);
      }
    },
    [metserviceStore]
  );

  const initialValues: FormValues = {
    variable: { name: '' },
    variable_id: '',
    measurement_type_id: '',
    multiplier: 1,
    offset: 0
  };

  const validationSchema = Yup.object().shape({
    variable: Yup.object().required(),
    variable_id: Yup.string().required(),
    measurement_type_id: Yup.string().required(),
    multiplier: Yup.number().required(),
    offset: Yup.number().required()
  });

  return (
    <>
      <Button {...buttonProps} onClick={() => setShow(true)}>
        Add Measurement
      </Button>
      <Modal
        show={
          show &&
          metserviceStore.selectedStation &&
          metserviceStore.selectedStation.station &&
          metserviceStore.selectedStation.site
            ? true
            : false
        }
        onHide={() => setShow(false)}
      >
        <Modal.Header>Add New Measurement</Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) =>
              await onSubmit(values, actions)
            }
          >
            {(formikProps) => {
              return <MeasurementForm formikProps={formikProps} />;
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default observer(AddMeasurementModal);

