import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { ReportContext } from '../../../store/StoreContexts';
import { useQuery } from '../../../utils/requests/Query';
import ReportsDetails from './details/ReportsDetails';
import SubscriptionDetails from './details/SubscriptionDetails';
import AddSubscriptionModal from './forms/AddSubscriptionModal';
import EditSubscription from './forms/EditSubscription';
import { SubscriptionsTable } from './SubscriptionsTable';

/**
 * MDR subscription manager page.
 *
 * Contains a table of subscriptions, subscription details and report details
 * for each subscription.
 */
const ReportsManager: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const reportsStore = useContext(ReportContext);
  const query = useQuery();

  useEffect(() => {
    reportsStore.requestSubscriptions();
  }, [reportsStore]);

  useEffect(() => {
    reportsStore.setCurrentSubscription(
      query.get('id') !== null ? (query.get('id') as string) : undefined
    );
  }, [query]);

  return (
    <>
      <div className='page-title pb-0'>MDR Subscription Manager</div>
      <br></br>
      <AddSubscriptionModal />
      <Card className='mb-3 w-100'>
        <SubscriptionsTable
          data={toJS(reportsStore.subscriptions)}
          loading={reportsStore.loading}
          subscription={reportsStore.current}
        />
      </Card>
      <Row>
        <Col>
          {reportsStore.current ? (
            editMode ? (
              <EditSubscription
                subscription={reportsStore.current}
                setEditMode={setEditMode}
              />
            ) : (
              <SubscriptionDetails
                subscription={reportsStore.current}
                setEditMode={setEditMode}
              />
            )
          ) : (
            <Card>
              <Card.Header>Subscription Details</Card.Header>
              <Card.Body>
                Please select a subscription to view more...
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col className='col-12 col-md-4'>
          <ReportsDetails></ReportsDetails>
        </Col>
      </Row>
    </>
  );
};

export default observer(ReportsManager);
