import { HilltopStore } from "../../../../../store/hilltop_stations/HilltopStore";
import { MetloadWithMeasurement } from "../../../../../store/hilltop_stations/models/HilltopStation";

/**
	 * Returns the metloads which are different to those in the harvestStationsStore
	 * selected station.
	 *
	 * Only compares the following keys because they are the only ones that can
	 * be changed:
	 *  - MetVar_ID
	 *  - MetMesTp_ID
	 *  - Multiplier
	 *  - Offset
	 */
export const filterChangedMetloads = (
	hilltopStore: HilltopStore,
	metloads: MetloadWithMeasurement[]
) => {
	const initialMetloads =
		hilltopStore.selectedStation?.station?.metloadsWithMeasurements;

	if (!initialMetloads) return metloads;

	return metloads.filter((metload) => {
		const initialMetload = initialMetloads.find((m) => m.id === metload.id);
		return (
			!initialMetload ||
			metload.MetVar_ID !== initialMetload.MetVar_ID ||
			metload.MetMesTp_ID !== initialMetload.MetMesTp_ID ||
			metload.Multiplier !== initialMetload.Multiplier ||
			metload.Offset !== initialMetload.Offset
		);
	});
};
