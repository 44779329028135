/**
 * This class represents a 401 unauthorized HTTP response.
 */
export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = UnauthorizedError.name;
  }
}
