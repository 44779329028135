import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { HaloStationsContext } from '../../../../store/StoreContexts';
import MeasurementsTable from '../MeasurementsTable/MeasurementsTable';
import MetloadsTable from '../MetloadsTable/MetloadsTable';

const HaloStationManager = () => {
  const haloStore = useContext(HaloStationsContext);

  const groupProperties = haloStore.selectedGroupProperties;

  const groupHasProperties = groupProperties && groupProperties.length > 0;

  return haloStore.loadingGroupProperties ? (
    <Row>
      <Col>
        <Spinner animation='border' variant='primary' />
      </Col>
    </Row>
  ) : haloStore.hasSelectedStation() &&
    haloStore.selectedStation?.station?.metloadWithProperties ? (
    <>
      <Row className='mt-3'>
        {groupHasProperties ? (
          <>
            <Col xs={3}>
              <h4>Measurements</h4>
              <MeasurementsTable />
            </Col>
            <Col xs={9}>
              <h4>Metloads</h4>
              <MetloadsTable />
            </Col>
          </>
        ) : null}
      </Row>
    </>
  ) : null;
};

export default observer(HaloStationManager);

