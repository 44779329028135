import { DateTime } from 'luxon';
import { makeAutoObservable, runInAction } from 'mobx';
import { AllUsersStore } from '../allusers/AllUsersStore';
import UserStore from '../user/UserStore';
import { UserDetails } from '../user/UserTypes';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import {
  constructUserSubscriptions,
  getSubscriptions,
  Subscription,
  UserSubscription,
  updateSubscription as updateSubscriptionRequest,
  updateReport as updateReportRequest,
  getReports,
  getReportTypes,
  Report,
  NewReportRequest,
  EditReportRequest,
  ReportType,
  createSubscription as createSubscriptionRequest,
  NewSubscription,
  createReportRequest,
  createEditReportRequest
} from './MdrUtils';
import { successToast } from '../../components/utils/ToastContainer';

/**
 * ReportsStore contains information related to MDR subscriptions, reports and
 * report types.
 */
export class MdrStore {
  user: UserStore;
  allUsers: AllUsersStore;
  subscriptions: UserSubscription[];
  reports: Report[];
  reportTypes: ReportType[];
  loading: boolean = false;
  current: UserSubscription | undefined;

  constructor(userStore: UserStore, allUsersStore: AllUsersStore) {
    makeAutoObservable(this);
    // Set fields from constructor params:
    this.user = userStore;
    this.allUsers = allUsersStore;
    // Set fields to be populated later:
    this.subscriptions = [];
    this.reports = [];
    this.reportTypes = [];
    this.current = undefined;
  }

  /**
   * Stores the given subscription in the database. Stores the response in the
   * local store. Links the provided user with the subscription to create a
   * UserSubscription object to store.
   * @param subscription New subscription.
   * @param user User associated with the new subscription.
   */
  async createSubscription(subscription: NewSubscription, user: UserDetails) {
    if (this.user.userState !== 'loggedIn') return;
    return createSubscriptionRequest(this.user, subscription).then((data) =>
      runInAction(() => {
        this.subscriptions = [
          ...this.subscriptions,
          { ...data, user: user, deleted_at: null }
        ];
      })
    );
  }

  /**
   * Retrieves all subscriptions and stores them in the subscriptions array.
   */
  async requestSubscriptions() {
    if (this.user.userState !== 'loggedIn') return;
    this.loading = true;
    runInAction(() => (this.loading = true));
    Promise.all([getSubscriptions(this.user), this.allUsers.requestUsers()])
      .then(async (data) => {
        runInAction(() => {
          this.subscriptions = constructUserSubscriptions(
            data[0],
            this.allUsers
          );
        });
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  /**
   * Retrieves all reports and stores them in the reports array. *
   */
  async requestReports() {
    if (this.user.userState !== 'loggedIn') return;
    await getReports(this.user).then((data) => {
      runInAction(() => {
        this.reports = data;
      });
    });
  }

  /**
   * Retrieves all report types and stores them in the report types array.
   */
  async requestReportTypes() {
    if (this.user.userState !== 'loggedIn') return;
    await getReportTypes(this.user).then((data) => {
      runInAction(() => {
        this.reportTypes = data;
      });
    });
  }

  /**
   * Updates the subscription with the given ID.
   * @param id Subscription ID.
   * @param subscription New subscription object.
   */
  async updateSubscription(
    id: number,
    subscription: Subscription | UserSubscription
  ) {
    await updateSubscriptionRequest(
      this.user.user!.userToken,
      id,
      subscription
    ).then((data) => {
      const index = this.subscriptions.findIndex((sub) => sub.id === id);
      runInAction(() => {
        this.subscriptions[index] = {
          ...this.subscriptions[index],
          ...data
        };
        this.current = this.subscriptions[index];
      });
    });
  }

  /**
   * Toggles whether or not a subscription is suspended. Suspended_at === null
   * indicates that the subscription is currently active, and therefore should set
   * the field to the current date. If the field is not null, the subscription is
   * currently suspended, and therefore should be set to null.
   *
   * @param subscription Subscription to toggle suspended on.
   */
  async toggleSubscription(subscription: Subscription) {
    const suspended = subscription.suspended_at
      ? null
      : DateTime.now().toFormat('yyyy-MM-dd hh:mm:ss');
    this.updateSubscription(subscription.id, {
      ...subscription,
      suspended_at: suspended
    });
  }

  /**
   * Toggles whether or not a report is suspended. Suspended_at === null
   * indicates that the report is currently active, and therefore should set
   * the field to the current date. If the field is not null, the report is
   * currently suspended, and therefore should be set to null.
   *
   * @param report Report to toggle suspended on.
   */
  async toggleReport(report: Report) {
    const suspended = report.suspended_at
      ? null
      : DateTime.now().toFormat('yyyy-MM-dd hh:mm:ss');
    this.updateReport(report.id, { ...report, suspended_at: suspended });
  }

  /**
   * Create a new report.
   * @param report
   */
  createReport(report: NewReportRequest) {
    return createReportRequest(this.user, report).then((data) => {
      runInAction(() => (this.reports = [...this.reports, { ...data }]));
      successToast('Report was added');
    });
  }

  /**
   * Updates the report with the given ID.
   * @param id Report ID.
   * @param report New report object.
   */
  editReport(id: number, report: EditReportRequest) {
    return createEditReportRequest(this.user, id, report).then((data) => {
      const index = this.reports.findIndex((rep) => rep.id === id);
      runInAction(() => {
        this.reports[index] = data;
      });
    });
  }

  /**
   * Updates the report with the given ID.
   * @param id Report ID.
   * @param report New report object.
   */
  async updateReport(id: number, report: Report) {
    updateReportRequest(this.user, id, report).then((data) => {
      const index = this.reports.findIndex((rep) => rep.id === id);
      runInAction(() => {
        this.reports[index] = data;
      });
    });
  }

  /**
   * Delete the report with the given ID.
   * @param id Report ID.
   */
  async deleteReport(id: number) {
    const response = await fetchWithRefresh(
      this.user,
      process.env.REACT_APP_MDR_API_URL + 'api/admin/reports/' + id,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.user.user?.userToken.jwt_bearer
        }
      }
    );
    // If deleted successfully, remove from the store.
    if (response?.ok) {
      runInAction(() => {
        this.reports.splice(
          this.reports.findIndex((r) => r.id === id),
          1
        );
      });
    }
    successToast('Report was deleted');
  }

  /**
   * Filters reports by current subscription ID.
   */
  getCurrentSubscriptionReports() {
    return this.reports.filter(
      (report) => report.subscription_id === this.current?.id
    );
  }

  /**
   * Gets all users who do not currently have a subscription associated with
   * their account.
   */
  getUnsubscribedUsers() {
    return this.allUsers.allUsers.filter(
      (user) =>
        !this.subscriptions.some(
          (sub) =>
            sub.user_id === user.client_id && sub.user_type === user.service
        )
    );
  }

  /**
   * Sets the current subscription to the subscription with the given ID.
   * @param id Subscription ID.
   */
  async setCurrentSubscription(id: string | undefined) {
    runInAction(() => {
      this.current = id
        ? this.subscriptions.find((sub) => sub.id === parseInt(id))
        : undefined;
    });
  }
}
