import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import React, {
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import {
  MetloadContext,
  WinetStationsContext
} from '../../../store/StoreContexts';
import StationsMap from '../../maps/StationsMap';
import CenterSpinner from '../../utils/CenterSpinner';
import LinkWinetNetworkForm from './LinkWinetNetworkForm/LinkWinetNetworkForm';
import WinetStationManager from './WinetStationManager/WinetStationManager';
import WinetStationsTable from './WinetStationTable/WinetStationsTable';

const WinetStationsPage = () => {
  const winetStore = useContext(WinetStationsContext);
  const metloadStore = useContext(MetloadContext);
  const stationStore = winetStore.stationStore;
  const regionStore = stationStore.regionStore;

  const mapStations = (winetStore.selectedStation?.network
    ? [winetStore.selectedStation.network]
    : winetStore.winetStations
  ).map((ms) => {
    const station = ms.station;
    return {
      Station_ID: station.Station_ID,
      Logger_Name: station.Logger_Name,
      Longitude: Number(station.Longitude),
      Latitude: Number(station.Latitude),
      Logger_Type: 'Winet'
    };
  });

  useEffect(() => {
    const init = async () => {
      if (winetStore.stationStore.stations.length === 0) {
        await winetStore.stationStore.requestStations();
      }
      await Promise.all([
        await metloadStore.initializeData(),
        await regionStore.fetchCountries()
      ]);
    };
    init();
    // De-select station when the page is un-mounted.
    return () => {
      winetStore.deSelectStation();
    };
  }, [winetStore, metloadStore, regionStore]);

  useEffect(() => {
    let ignore = false;
    // Fetch winet stations.
    if (!ignore) {
      winetStore
        .loadStations()
        .then(() => winetStore.loadAPIStations())
        .finally(() => winetStore.getMatchedWinetStations());
    }
    return () => {
      ignore = true;
    };
  }, [winetStore]);

  return winetStore.stationStore.loading ||
    metloadStore.loading ||
    winetStore.loadingInitial ? (
    <CenterSpinner />
  ) : (
    <Container>
      <Row className='mt-2'>
        <Col>
          <Row>
            <Col>
              <Card className='my-3 w-100 rounded-0'>
                <WinetStationsTable />
              </Card>
            </Col>
          </Row>
          {winetStore.selectedStation ? (
            winetStore.selectedStation.network &&
            winetStore.selectedStation.station ? (
              <Row>
                <Col>
                  <WinetStationManager />
                </Col>
              </Row>
            ) : (
              <Modal
                show={winetStore.selectedStation ? true : false}
                onHide={() => {
                  winetStore.deSelectStation();
                }}
              >
                <Modal.Header>Link Network</Modal.Header>
                <Modal.Body>
                  <LinkWinetNetworkForm />
                </Modal.Body>
              </Modal>
            )
          ) : null}
          <Row className='mt-4'>
            <Col>
              {winetStore.loadingWinetStations ? (
                <div className='text-center'>
                  <Spinner animation='border' variant='primary' />
                </div>
              ) : (
                <StationsMap
                  stations={mapStations}
                  loading={false}
                  initialLocationState={
                    mapStations.length > 0
                      ? {
                          latitude: mapStations[0].Latitude,
                          longitude: mapStations[0].Longitude
                        }
                      : undefined
                  }
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(WinetStationsPage);

