import React, { useContext } from 'react';
// import { Route as ApmRoute } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { Route as ReactRoute } from 'react-router-dom';
import { UserContext } from '../../store/StoreContexts';
import { RouteProps } from './RouteTypes';
import Login from '../login/Login';
import { observer } from 'mobx-react-lite';
import Layout from '../layout/Layout';
import Loading from '../layout/Loading';

/**
 * Component wrapping react-router-dom Route component to facilitate handling
 * authorization.
 *
 * Checks whether or not the route requires authorization and redirects
 * accordingly (to login if not authorized).
 */
function Route(props: RouteProps) {
  // Extract props we need for checking auth, pass the rest to ApmRoute.
  const { component, requiresAuth, requiresAdmin, ...otherProps } = props;

  const store = useContext(UserContext);

  // const loggedIn: boolean = store.isLggedIn();
  // If not logged in, cannot be admin.
  const isAdmin: boolean = store.isAdmin();

  // Determine whether the user can attempt to load a page, or if they should
  // be redirected to the login page. Note that even if an unauthenticated user
  // somehow loaded a page that 'requiresAuth', they cannot access sensitive
  // data, as they will not have the appropriate JWT.
  if (
    !requiresAuth ||
    (store.userState === 'loggedIn' &&
      requiresAuth &&
      (!requiresAdmin || isAdmin))
  )
    return (
      <ApmRoute
        {...otherProps}
        component={observer(() => (
          <>
            {store.loading ? <Loading isFixed></Loading> : <></>}
            <Layout hasNavBar={true}>{component}</Layout>
          </>
        ))}
      />
    );
  else if (store.userState === 'cookieExists')
    return (
      <ReactRoute {...otherProps}>
        <Loading isFixed></Loading>
        <Layout hasNavBar={true}>
          <></>
        </Layout>
      </ReactRoute>
    );
  return (
    <ReactRoute {...otherProps}>
      {store.loading ? <Loading isFixed></Loading> : <></>}
      <Layout hasNavBar={false}>
        <Login />
      </Layout>
    </ReactRoute>
  );
}

export default observer(Route);
