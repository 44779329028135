import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { HilltopStationsContext } from '../../../../../store/StoreContexts';
import {
  Station,
  StationValidationSchema
} from '../../../../../store/stations/StationTypes';
import { errorToast, successToast } from '../../../../utils/ToastContainer';
import { getEmptyFormStation } from '../../../stations/WeatherStationTypes';
import StationForm from '../../../stations/forms/StationForm';

const LinkHilltopSiteForm = () => {
  const hilltopStore = useContext(HilltopStationsContext);
  const hilltopStation = hilltopStore.selectedStation;
  const hilltopSiteName = hilltopStation?.site.name;

  // Sorts the hilltop stations by number after T prefix (e.g. T21 > T20)
  const sortHilltopStation = (a: Station, b: Station) => {
    return parseInt(b.Station_ID.substring(1, 3)) <
      parseInt(a.Station_ID.substring(1, 3))
      ? a
      : b;
  };

  // Calculates a suggested ID for the hilltop station by increasing the value of the last hilltop
  // station ID by one.
  const suggestedId = useMemo(() => {
    const hilltopStations = hilltopStore.stationStore.stations
      .slice()
      .filter((s) => /T[0-9][0-9]/.test(s.Station_ID));
    // Handle case when there are no Hilltop Stations.
    if (hilltopStations.length === 0) return 'T01';
    //
    const lastStation = hilltopStations.reduce(sortHilltopStation);
    const isSingleDigit = lastStation?.Station_ID.substring(1, 2) === '0';
    const lastStationNumber = isSingleDigit
      ? lastStation?.Station_ID.substring(2, 3)
      : lastStation?.Station_ID.substring(1, 2);
    const last = parseInt(lastStationNumber) + 1;
    // Returns the next suggested hilltop station ID if there are some left, otherwise an empty string.
    if (last >= 99) return '';
    if (last < 10) return `T0${last}`;
    return `T${last}`;
  }, [hilltopStore.stationStore.stations]);

  // There's a issue with setting this to type 'FormValues' so
  // is set to any.
  const initialValues: any = {
    ...getEmptyFormStation(),
    hilltop_station_agency: hilltopStore.getProviderName,
    hilltop_site_name: hilltopSiteName || '',
    hilltop_site_setup: 0,
    Station_ID: suggestedId,
    Logger_Name: hilltopStation!.site!.name,
    Logger_Type: 'Hilltop',
    Logger_Description: 'Temporary description',
    Latitude: hilltopStation!.site!.latitude || 0,
    Longitude: hilltopStation!.site!.longitude || 0,
    Altitude: 1,
    timezone: 'Pacific/Auckland' // Harvest stations are in NZ.
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={StationValidationSchema}
        onSubmit={async (values, { setFieldError }) => {
          // Await the create requests to ensure 422s are caught.
          try {
            await hilltopStore.createHilltopStation(values);
            hilltopStore.selectedStationIndex = undefined;
            successToast(`Hilltop station successfully created.`);
          } catch (error: any) {
            // Check for unprocesable entity
            if (error.message.includes(422)) {
              error.errors.then((data: any) => {
                for (var err in data.errors) {
                  setFieldError(err, data.errors[err][0]);
                }
              });
              errorToast(`Error creating hilltop station.`);
            } else throw error;
          }
        }}
      >
        {(formikProps) => (
          <>
            <StationForm
              formikProps={formikProps}
              removed={{ Active: true, Logger_Type: true, WMONumber: true }}
            />
            <Button
              onClick={() => {
                formikProps.handleSubmit();
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Formik>
    </>
  );
};

export default observer(LinkHilltopSiteForm);

