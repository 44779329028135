import { makeAutoObservable, runInAction } from 'mobx';
import { RegionStore } from '../regions/RegionStore';
import UserStore from '../user/UserStore';
import {
  createStation as createStationRequest,
  getStationNetworks as getStationNetworksRequest,
  getStation as getStationRequest,
  getStations as getStationsRequest,
  getStatuses as getStausesRequest,
  updateStation as updateStationRequest
} from './StationRequests';
import { Station, Status } from './StationTypes';

/**
 * Station store contains station data and methods for retrieving and updating
 * data.
 */
export default class StationStore {
  user: UserStore;
  regionStore: RegionStore;
  stations: Station[];
  statuses: Status[];
  currentStation: Station | undefined;

  loading: boolean = false;
  retryCount: number = 0;

  constructor(user: UserStore, regionStore: RegionStore) {
    makeAutoObservable(this);
    // Initialise to empty array.
    this.stations = [];
    this.currentStation = undefined;
    // Cannot be instantiated before user login - UserStore provided to
    // constructor.
    this.user = user;
    this.regionStore = regionStore;
    this.statuses = [];
  }

  /**
   * Gets all stations and populates the stations fields.
   *
   * If 401 encountered, refreshes login.
   */
  async requestStations() {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await getStationsRequest(this.user.user!.userToken)
      .then((data) => {
        runInAction(() => {
          this.stations = data.sort(
            (s0, s1) => s0.DisplayOrder - s1.DisplayOrder
          );
        });
      })
      .catch((err: Error) => {
        // Refresh if no longer logged in.
        if (err.message.includes('401')) {
          if (this.retryCount < 1) {
            runInAction(() => {
              this.retryCount++;
              this.user.refresh();
            });
          }
          // Logout if already attempted to refresh.
          runInAction(() => {
            this.retryCount = 0;
            this.user.logout();
          });
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  /**
   * Gets a single station and adds it to the stations field.
   *
   * If 401 encountered, refreshes login.
   * @param stationId Three character station identifier.
   */
  async requestStation(stationId: string) {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await getStationRequest(this.user.user!.userToken, stationId)
      .then((data) => {
        const index = this.stations.findIndex((station: Station) => {
          return station.Station_ID === stationId;
        });
        // If station already exists in array, update. Otherwise, push to end.
        if (index !== -1) {
          runInAction(() => {
            this.stations[index] = data;
          });
        } else {
          runInAction(() => {
            this.stations.push(data);
          });
        }
      })
      .catch((err: Error) => {
        // Refresh if no longer logged in.
        if (err.message.includes('401')) {
          this.user.refresh();
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  /**
   * Sets the current station to the station with the provided ID.
   * @param station Station to set as current.
   */
  setCurrentStation(stationId: string | undefined) {
    runInAction(() => {
      this.currentStation =
        stationId !== undefined
          ? this.stations.find((station) => station.Station_ID === stationId)
          : undefined;
    });
  }

  /**
   * Updates the station with id stationId in the local store and sends the
   * updated station to the API.
   * @param stationId ID of station to update.
   * @param station Updated station.
   */
  async updateStation(stationId: string, station: Station) {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await updateStationRequest(this.user.user!.userToken, stationId, station)
      .then((data) => {
        // Response will be ok, otherwise error thrown.
        runInAction(() => {
          this.stations[
            this.stations.findIndex((ws) => ws.Station_ID === stationId)
          ] = data;
          this.stations = [...this.stations];
        });
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  /**
   * Adds a new station to the store and sends the new station to the API.
   * @param station New station.
   */
  async createStation(station: Station) {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await createStationRequest(this.user.user!.userToken, station)
      .then((data) => {
        runInAction(() => {
          this.stations = [...this.stations, data].sort(
            // Resort with new station.
            (s0, s1) => s0.DisplayOrder - s1.DisplayOrder
          );
        });
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  /**
   * Adds a station to the store without sending it to the API. This is primarily
   * useful for harvest stations, or any other route where a station is created as
   * the result of another API call and must then be manually added to this store.
   * @param station New station.
   */
  addStation(station: Station) {
    this.stations = [...this.stations, station];
  }

  /**
   * Gets all stations and populates the stations fields.
   *
   * If 401 encountered, refreshes login.
   */
  async requestStationNetworks() {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await getStationNetworksRequest(this.user.user!.userToken)
      .then((data) => {
        runInAction(() => {
          this.stations = data.sort(
            (s0, s1) => s0.DisplayOrder - s1.DisplayOrder
          );
        });
      })
      .catch((err: Error) => {
        // Refresh if no longer logged in.
        if (err.message.includes('401')) {
          if (this.retryCount < 1) {
            runInAction(() => {
              this.retryCount++;
              this.user.refresh();
            });
          }
          // Logout if already attempted to refresh.
          runInAction(() => {
            this.retryCount = 0;
            this.user.logout();
          });
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }

  /**
   * Gets all types of statuses and puts them into a list
   *
   * If 401 encountered, refreshes login.
   */
  async requestStatuses() {
    if (this.user.userState !== 'loggedIn') return;
    runInAction(() => (this.loading = true));
    await getStausesRequest(this.user.user!.userToken)
      .then((data) => {
        runInAction(() => {
          this.statuses = data.sort((s0, s1) => s0.id - s1.id);
        });
      })
      .catch((err: Error) => {
        // Refresh if no longer logged in.
        if (err.message.includes('401')) {
          if (this.retryCount < 1) {
            runInAction(() => {
              this.retryCount++;
              this.user.refresh();
            });
          }
          // Logout if already attempted to refresh.
          runInAction(() => {
            this.retryCount = 0;
            this.user.logout();
          });
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  }
}

