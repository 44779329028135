import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Card, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import {
  HaloStationsContext,
  MetloadContext
} from '../../../store/StoreContexts';
import StationsMap from '../../maps/StationsMap';
import CenterSpinner from '../../utils/CenterSpinner';
import HaloStationManager from './HaloStationManager/HaloStationManager';
import HaloStationsTable from './HaloStationTable/HaloStationsTable';
import LinkHaloSiteForm from './LinkHaloSiteForm/LinkHaloSiteForm';

const HaloStationsPage = () => {
  const haloStore = useContext(HaloStationsContext);
  const metloadStore = useContext(MetloadContext);
  const stationStore = haloStore.stationStore;
  const regionStore = stationStore.regionStore;

  useEffect(() => {
    const init = async () => {
      if (haloStore.stationStore.stations.length === 0)
        await haloStore.stationStore.requestStations();
      await metloadStore.initializeData();
      await regionStore.fetchCountries();
    };
    init();
    // De-select station when the page is un-mounted.
    return () => {
      haloStore.deSelectStation();
    };
  }, [haloStore, metloadStore, regionStore]);

  useEffect(() => {
    let ignore = false;
    // Fetch halo stations.
    if (!ignore) {
      haloStore
        .loadStations()
        .then(() => haloStore.loadGroups())
        .finally(() => haloStore.getMatchedStationsGroups());
    }
    return () => {
      ignore = true;
    };
  }, [haloStore]);

  return haloStore.stationStore.loading ||
    metloadStore.loading ||
    haloStore.loadingInitial ? (
    <CenterSpinner />
  ) : (
    <Container>
      <Row className='mt-2'>
        <Col>
          <Row>
            <Col>
              <Card className='my-3 w-100 rounded-0'>
                <HaloStationsTable />
              </Card>
            </Col>
          </Row>
          {haloStore.selectedStation ? (
            haloStore.selectedStation.station &&
            haloStore.selectedStation.group ? (
              <Row>
                <Col>
                  <HaloStationManager />
                </Col>
              </Row>
            ) : (
              <Modal
                show={haloStore.selectedStation ? true : false}
                onHide={() => {
                  haloStore.deSelectStation();
                }}
              >
                <Modal.Header>Link Site</Modal.Header>
                <Modal.Body>
                  <LinkHaloSiteForm />
                </Modal.Body>
              </Modal>
            )
          ) : null}
          <Row>
            <Col>
              {haloStore.loadingHaloStations ? (
                <div className='text-center'>
                  <Spinner animation='border' variant='primary' />
                </div>
              ) : (
                <StationsMap
                  stations={haloStore.haloStations.map((hs) => {
                    const station = hs.station;
                    return {
                      Station_ID: station.Station_ID,
                      Logger_Name: station.Logger_Name,
                      Longitude: Number(station.Longitude),
                      Latitude: Number(station.Latitude),
                      Logger_Type: 'Halo'
                    };
                  })}
                  loading={false}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(HaloStationsPage);

