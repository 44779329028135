import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import Select from 'react-select';
import { ReportContext } from '../../../../store/StoreContexts';
import { ReportSelectorProps } from './ReportSelectorUtils';

const ReportSelector: React.FC<ReportSelectorProps> = (props) => {
  const { setFieldValue, handleBlur } = useFormikContext();
  const reportStore = useContext(ReportContext);
  const reports = reportStore.reportTypes.map((report) => {
    return {
      value: report.id,
      label: report.name
    };
  });

  return (
    <Select
      name={props.name}
      options={reports}
      onChange={(option: any) => {
        setFieldValue(props.name, option.value);
      }}
      onBlur={handleBlur}
      value={reports.find((option) => option.value === props.value)}
      className={props.isInvalid ? 'is-invalid' : ''}
      styles={
        props.isInvalid
          ? {
              control: (provided, _) => ({
                ...provided,
                borderColor: '#dc3545'
              })
            }
          : {}
      }
    ></Select>
  );
};

export default observer(ReportSelector);

