import { FieldArray, Form as FormikForm, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import { MetloadWithMeasurement } from '../../../../../store/hilltop_stations/models/HilltopStation';
import { HilltopStationsContext } from '../../../../../store/StoreContexts';
import LoadingOverlay from '../../../../utils/LoadingOverlay';
import AddMeasurementModal from '../AddMeasurementModel/AddMeasurementModal';
import TableRow from './TableRow/TableRow';
import { filterChangedMetloads } from './utils';

const MetloadsTable = () => {
  const hilltopStore = useContext(HilltopStationsContext);
  const [loading, setLoading] = useState<boolean>(false);

  return hilltopStore.hasSelectedStation() ? (
    <Formik
      enableReinitialize
      initialValues={{
        metloads: hilltopStore.selectedStation?.station
          ?.metloadsWithMeasurements
          ? hilltopStore.selectedStation?.station?.metloadsWithMeasurements
          : []
      }}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        const changedMetloads = filterChangedMetloads(
          hilltopStore,
          formikProps.values.metloads
        );
        return (
          <FormikForm>
            {formikProps.isSubmitting ? (
              <Spinner animation='border' variant='primary' />
            ) : null}
            <Table size='sm' bordered striped hover>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>Hilltop Measurement ID</th>
                  <th style={{ width: '22.5%' }}>Hilltop Measurement Name</th>
                  <th style={{ width: '22.5%' }}>Variable</th>
                  <th style={{ width: '20%' }}>Measurement</th>
                  <th style={{ width: '10%' }}>Multiplier</th>
                  <th style={{ width: '10%' }}>Offset</th>
                  <th style={{ width: '5%' }} />
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name='metloads'
                  render={() =>
                    loading ? (
                      <LoadingOverlay />
                    ) : hilltopStore.selectedStation?.station ? (
                      formikProps.values.metloads.map(
                        (metload: MetloadWithMeasurement, index) => {
                          return (
                            <TableRow
                              key={metload.id}
                              metload={metload}
                              formikProps={formikProps}
                              index={index}
                              setLoading={setLoading}
                            />
                          );
                        }
                      )
                    ) : null
                  }
                />
              </tbody>
            </Table>
            <div className='d-flex justify-content-center'>
              <AddMeasurementModal variant='outline-primary' className='mx-2' />
              <Button
                variant='primary'
                type='submit'
                className='mx-2'
                disabled={changedMetloads.length === 0}
              >
                Submit
              </Button>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  ) : null;
};

export default observer(MetloadsTable);

