import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import NetworksSelector from '../../user_access_arrays/forms/NetworksSelector';
import { StationFormProps } from '../WeatherStationTypes';
import StationSelector from './StationSelector';
import StatusSelector from './StatusSelector';

/**
 * This component creates a form for modifying station objects.
 *
 * See StationNetworksEditCard for use.
 *
 * @param props Station state and callback function for updating station.
 */
export default function StationNetworksForm(props: StationFormProps) {
  const {
    handleSubmit,
    values,
    touched,
    errors
  } = props.formikProps;
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group controlId='networks'></Form.Group>
          <Form.Label>Networks</Form.Label>
          <NetworksSelector
            name='Networks'
            value={values.network_id}
            isInvalid={touched.network_id && errors.network_id}
          />
        </Col>
      </Row>
    </Form>
  );
}

