import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Modal, Button } from 'react-bootstrap';
import { ReportContext } from '../../../../store/StoreContexts';
import { UnproccessableEntityError } from '../../../../store/stations/errors/UnprocessableEntityError';
import {
  ReportSchema,
  EditCurrentReport,
  Report
} from '../../../../store/reports/MdrUtils';
import EditReportForm from './EditReportForm';

type EditReportModalProps = {
  report: Report;
};

const EditReportModal: React.FC<EditReportModalProps> = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant='primary' size='sm' onClick={handleShow}>
        Edit
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Report ID: {props.report.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditReport
            report={props.report}
            id={props.report.id}
            onSuccess={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default observer(EditReportModal);

type EditReportProps = {
  report: Report;
  id: number;
  onSuccess: () => void;
};

const EditReport: React.FC<EditReportProps> = (props) => {
  const reportStore = useContext(ReportContext);

  const formInitialValue: EditCurrentReport = {
    id: props.report.id,
    subscription_id: reportStore.current?.id ?? 0,
    report_type_id: props.report.report_type_id,
    report_type_name:
      reportStore.reportTypes.find(
        (report) => report.id === props.report.report_type_id
      )?.name ?? '',
    station_id: props.report.station_id,
    forecast_id: props.report.forecast_id.toString(),
    suspended_at: props.report.suspended_at ?? '',
    cron_expression: props.report.cron_expression,
    settings: props.report.settings ?? '',
    created_at: props.report.created_at,
    updated_at: props.report.updated_at,
    deleted_at: props.report.deleted_at
  };

  return (
    <Formik
      initialValues={formInitialValue}
      enableReinitialize
      validateOnBlur
      validateOnMount
      validateOnChange
      validationSchema={ReportSchema}
      onSubmit={(values, { setFieldError }) => {
        reportStore
          .editReport(props.report.id, values)
          .then(() => {
            props.onSuccess();
          })
          .catch((error: UnproccessableEntityError) => {
            error.errors.then((data: any) => {
              for (var err in data.errors) {
                setFieldError(err, data.errors[err][0]);
              }
            });
          });
      }}
    >
      {(formikProps) => (
        <>
          <EditReportForm />
          <Modal.Footer>
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                formikProps.handleSubmit();
              }}
            >
              Edit
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
