import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { HarvestStationsContext } from '../../../../store/StoreContexts';
import {
  Station,
  StationValidationSchema
} from '../../../../store/stations/StationTypes';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import { getEmptyFormStation } from '../../stations/WeatherStationTypes';
import StationForm from '../../stations/forms/StationForm';

type LinkHarvestSiteFormProps = {
  harvest_site_id: number;
  selectedStation: Station | undefined;
  setSelectedStation: Function;
  // Whether the selected site already has a linked MetWatch station so
  // The MetWatch station is being duplicated.
  isDuplicating?: boolean;
};

const LinkHarvestSiteForm = ({
  harvest_site_id,
  selectedStation,
  setSelectedStation,
  isDuplicating = false
}: LinkHarvestSiteFormProps) => {
  const harvestStore = useContext(HarvestStationsContext);
  const harvestStation = harvestStore.selectedStation;
  // Sorts the harvest stations by number after H prefix (e.g. H21 > H20)
  const sortHarvestStation = (a: Station, b: Station) => {
    return parseInt(b.Station_ID.substring(1, 3)) <
      parseInt(a.Station_ID.substring(1, 3))
      ? a
      : b;
  };
  // Calculates a suggested ID for the harvest station by increasing the value of the last harvest
  // station ID by one.
  const suggestedId = useMemo(() => {
    const last =
      parseInt(
        harvestStore.stationStore.stations
          .slice()
          .filter((s) => /H[0-9][0-9]/.test(s.Station_ID))
          .reduce(sortHarvestStation)
          ?.Station_ID.substring(1, 3)
      ) + 1;
    // Returns the next suggested harvest station ID if there are some left, otherwise an empty string.
    return last < 99 ? `H${last}` : '';
  }, [harvestStore.stationStore]);

  /**
   * Determines the forms initial values. This depends on if the
   * selected site is already linked to a MetWatch station.
   */
  const initialValues = () => {
    const empty = { ...getEmptyFormStation() };
    if (isDuplicating) {
      return {
        ...empty,
        ...selectedStation,
        Station_ID: suggestedId,
        harvest_site_id
      };
    } else {
      return {
        ...empty,
        harvest_site_id,
        Station_ID: suggestedId,
        Logger_Name: harvestStation!.site!.primary_location!.location_name,
        Logger_Type: 'Harvest',
        Logger_Description: harvestStation!.site!.primary_location!
          .subregion_name,
        Latitude: -41.505143,
        Longitude: 174.494402,
        Altitude: 1,
        timezone: 'Pacific/Auckland' // Harvest stations are in NZ.
      };
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues()}
        validationSchema={StationValidationSchema}
        onSubmit={async (values, { setFieldError }) => {
          // Await the create requests to ensure 422s are caught.
          await harvestStore
            .createHarvestStation(values)
            .then((_) => {
              harvestStore.fetchMetloadWithHarvestTrace(undefined);
              successToast(`Harvest station successfully created.`);
            })
            .catch((error) => {
              // Check for unprocesable entity
              if (error.message.includes(422)) {
                error.errors.then((data: any) => {
                  for (var err in data.errors) {
                    setFieldError(err, data.errors[err][0]);
                  }
                });
                errorToast(`Error creating harvest station.`);
              } else throw error;
            });
        }}
      >
        {(formikProps) => (
          <>
            <StationForm
              formikProps={formikProps}
              removed={{ Active: true, Logger_Type: true, WMONumber: true }}
            />
            <Button
              onClick={() => {
                formikProps.handleSubmit();
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Formik>
    </>
  );
};

export default observer(LinkHarvestSiteForm);

