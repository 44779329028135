import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import React, {
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import { isBomFileId } from '../../../store/bom_stations/BomUtils';
import {
  BomStationsContext,
  MetloadContext
} from '../../../store/StoreContexts';
import { useQuery } from '../../../utils/requests/Query';
import StationsMap from '../../maps/StationsMap';
import CenterSpinner from '../../utils/CenterSpinner';
import BomStationManager from './BomStationManager/BomStationManager';
import BomStationsTable from './BomStationTable/BomStationsTable';
import LinkBomNetworkForm from './LinkBomNetworkForm/LinkBomNetworkForm';
import RegionSelect from './RegionSelect';

const BomStationsPage = () => {
  const bomStore = useContext(BomStationsContext);
  const metloadStore = useContext(MetloadContext);
  const stationStore = bomStore.stationStore;
  const regionStore = stationStore.regionStore;
  const query = useQuery();
  const selectedFileId = query.get('region') || '';

  // Set provider if it is a valid provider.
  if (isBomFileId(selectedFileId)) {
    bomStore.setSelectedFileId = selectedFileId;
  }

  const mapStations = (bomStore.selectedStation?.network
    ? [bomStore.selectedStation.network]
    : bomStore.bomStations
  ).map((ms) => {
    const station = ms.station;
    return {
      Station_ID: station.Station_ID,
      Logger_Name: station.Logger_Name,
      Longitude: Number(station.Longitude),
      Latitude: Number(station.Latitude),
      Logger_Type: 'BOM'
    };
  });

  useEffect(() => {
    const init = async () => {
      if (bomStore.stationStore.stations.length === 0) {
        await bomStore.stationStore.requestStations();
      }
      await Promise.all([
        await metloadStore.initializeData(),
        await regionStore.fetchCountries()
      ]);
    };
    init();
    // De-select station when the page is un-mounted.
    return () => {
      bomStore.deSelectStation();
    };
  }, [bomStore, metloadStore, regionStore]);

  useEffect(() => {
    let ignore = false;
    // Fetch bom stations.
    if (!ignore && selectedFileId) {
      bomStore
        .loadStations(selectedFileId)
        .then(() => bomStore.loadAPIStations(selectedFileId))
        .finally(() => bomStore.getMatchedBomStations());
    }
    return () => {
      ignore = true;
    };
  }, [bomStore, selectedFileId]);

  return bomStore.stationStore.loading ||
    metloadStore.loading ||
    bomStore.loadingInitial ? (
    <CenterSpinner />
  ) : (
    <Container>
      <Row className='mt-2'>
        <Col>
          <Row className='justify-content-md-center mt-4'>
            <Col md={6}>
              <RegionSelect />
            </Col>
          </Row>
          {bomStore.selectedFileId ? (
            <>
              <Row>
                <Col>
                  <Card className='my-3 w-100 rounded-0'>
                    <BomStationsTable />
                  </Card>
                </Col>
              </Row>
              {bomStore.selectedStation ? (
                bomStore.selectedStation.network &&
                bomStore.selectedStation.station ? (
                  <Row>
                    <Col>
                      <BomStationManager />
                    </Col>
                  </Row>
                ) : (
                  <Modal
                    show={bomStore.selectedStation ? true : false}
                    onHide={() => {
                      bomStore.deSelectStation();
                    }}
                  >
                    <Modal.Header>Link Network</Modal.Header>
                    <Modal.Body>
                      <LinkBomNetworkForm />
                    </Modal.Body>
                  </Modal>
                )
              ) : null}
              <Row className='mt-4'>
                <Col>
                  {bomStore.loadingBomStations ? (
                    <div className='text-center'>
                      <Spinner animation='border' variant='primary' />
                    </div>
                  ) : (
                    <StationsMap
                      stations={mapStations}
                      loading={false}
                      initialLocationState={
                        mapStations.length > 0
                          ? {
                              latitude: mapStations[0].Latitude,
                              longitude: mapStations[0].Longitude
                            }
                          : undefined
                      }
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default observer(BomStationsPage);

