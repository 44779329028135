import React, { useContext } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../store/StoreContexts';

/**
 * Displays the currently authenticated user's account details.
 */
export default function AccountDetails() {
  const store = useContext(UserContext);
  return (
    <>
      <div className='page-title'>Account</div>
      <Card>
        <Card.Header>Details</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Row>
                <Col 
                  xs={5} sm={5} md={5} lg={5} 
                  style={{textAlign: "left", fontWeight: "bold"}}>
                    Name:
                </Col>
                <Col style={{textAlign: "left"}}>{store.user?.userDetails.name}</Col>
              </Row>
              <Row>
                <Col 
                  xs={5} sm={5} md={5} lg={5} 
                  style={{textAlign: "left", fontWeight: "bold"}}>
                    Email:
                </Col>
                <Col style={{textAlign: "left"}}>{store.user?.userDetails.email}</Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col className='text-center'>
          <Link to='/'>
            <Button
              className='btn-secondary m-3'
              onClick={() => {
                store.logout();
              }}
            >
              Logout
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
}
