import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { TenantUsers } from '../../../store/tenant/TenantUsersUtils';
import { Column, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import GlobalFilter from '../../utils/table/GlobalFilter';
import Loading from '../../layout/Loading';
import { UserDetails } from '../../../store/user/UserTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';

type TenantUsersTableProps = {
  data: UserDetails[];
  loading: boolean;
  userDetails: UserDetails | undefined;
};

export const UsersTable: React.FC<TenantUsersTableProps> = (props) => {
  /**
   * Use of memo to help performance issues in the user table
   */
  const columns: Column<TenantUsers>[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: (row) => {
          if (row.id) {
            return row.id;
          } else if (row.client_id) {
            return row.client_id;
          }
        }
      },
      {
        Header: 'Name',
        accessor: (row) => {
          if (row.name !== undefined) {
            return row.name;
          } else {
            return '-';
          }
        }
      },
      {
        Header: 'Industry ID',
        accessor: (row): number | string => {
          return row.industry_id;
        }
      },
      {
        Header: 'Registration Date',
        accessor: (row) => {
          return row.registered ? row.registered : row.created_at?.slice(0, 10);
        }
      },
      {
        Header: 'Login Count',
        accessor: (row) => {
          if (row.logins) {
            return row.logins;
          } else {
            return row.login_count;
          }
        }
      },
      {
        Header: 'Last Login',
        accessor: (row) => {
          if (row.lastlogin) {
            return row.lastlogin;
          } else {
            return row.last_login;
          }
        }
      },
      {
        Header: 'Email',
        accessor: (row) => {
          return row.email;
        }
      },
      {
        Header: 'Password',
        accessor: (row) => {
          return row.password!;
        }
      }
    ],
    []
  );

  const history = useHistory();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: props.data
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      {/* This div creates a scroll box for the table. */}
      <div style={{ height: '300px', overflowY: 'scroll', marginRight: 0 }}>
        {props.loading ? (
          <Loading />
        ) : (
          <Table {...getTableProps()} size='sm' striped hover>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ borderTop: 0 }}
                    >
                      {column.render('Header') + ' '}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                function showUserDetails() {
                  let url = history.location.search;
                  url = url.split('&')[0] + '&id=' + row.values.ID.toString();
                  history.push(url);
                }
                return (
                  <tr
                    onClick={showUserDetails}
                    className={
                      props.userDetails?.id === row.values.ID
                        ? 'table-primary'
                        : ''
                    }
                   {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

