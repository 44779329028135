import apm from '../../rum';
import UserStore from '../../store/user/UserStore';
import { UnauthorizedError } from './RequestErrors';

/**
 * This function takes the usual parameters of a fetch call plus a reference to
 * UserStore.
 *
 * It will attempt to refresh the JWT token if a 401 response is encountered.
 * After refreshing, it will attempt the request again. By default it will only
 * attempt one refresh before failing. This will return the full response
 * regardless of whether or not any errors are encountered, in order to allow
 * it to be used wherever a vanilla fetch call can be used.
 *
 * Please note that function does not guarantee that the response will not be a
 * 401 Unauthorized. This function simply attempts to rectify ONE 401 response.
 * If bad credentials are provided, the subsequent 401 will be returned (within
 * a promise, of course).
 *
 * @param userStore MobX user store. Used to refresh the JWT on 401.
 * @param url URL to fetch.
 * @param request Optional request headers and body.
 */
export async function fetchWithRefresh(
  userStore: UserStore,
  url: string,
  request?: RequestInit | undefined
) {
  return fetch(url, request)
    .then((response) => {
      if (response.ok) return response;
      else if (response.status === 401)
        // This is the error this function is meant to handle.
        throw new UnauthorizedError('Unauthorized: ' + response.statusText);
      else {
        // Returns response even if an error (not 401) is encountered. This
        // makes the function behave as similarly to vanilla fetch() as
        // possible.
        if (process.env.NODE_ENV === 'development')
          console.error('Error in request: ' + response.statusText);
        return response;
      }
    })
    .catch((error) => {
      // Only handle 401 errors - everything else should be re-thrown.
      if (error instanceof UnauthorizedError) {
        // Attempt to refresh the JWT. If this fails, the user will be logged
        // out by UserStore.refresh().
        return userStore.refresh(true).then((res) => {
          if (res && userStore.userState === 'loggedIn') {
            // We must update the bearer token in the request before trying
            // the request again.
            request!.headers = {
              ...request?.headers,
              Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer
            };
            // Here we return the whole response again. At this point, we don't
            // care what the response is - it is the responsibility of the user
            // to deal with other errors. Note that this also does not guarantee
            // that the response will NOT be a 401.
            return fetch(url, request);
          }
        });
      } else {
        apm.captureError(error);
        throw error;
      }
    });
}

/**
 * This function takes another function (that ostensibly makes requests), plus
 * a reference to UserStore.
 *
 * It will attempt to refresh the JWT token if a 401 response is encountered.
 * After refreshing, it will attempt to call the provided function again. By
 * default it will only attempt one refresh before failing. This will return
 * the output of the given function.
 *
 * Please note that function does not guarantee that the response will not be a
 * 401 Unauthorized. This function simply attempts to rectify ONE 401 response.
 * If bad credentials are provided, the function will fail; this is up to the
 * user to account for.
 *
 * @param userStore MobX user store. Used to refresh the JWT on 401.
 * @param func Function to call.
 */
export async function runWithRefresh(userStore: UserStore, func: Function) {
  return func().catch(async (error: Error) => {
    // Only handle 401 errors - everything else should be re-thrown.
    if (error.message.includes('401')) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      // Attempt to refresh the JWT. If this fails, the user will be logged
      // out by UserStore.refresh().
      const res = await userStore.refresh();
      if (res) {
        // Here we return the whole response again. At this point, we don't
        // care what the response is - it is the responsibility of the user
        // to deal with other errors. Note that this also does not guarantee
        // that the response will NOT be a 401.
        return func();
      }
    } else {
      apm.captureError(error);
      throw error;
    }
  });
}
