import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

type GlobalFilterProps = {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
};

/**
 * Global filter for use with Formik tables.
 */
const GlobalFilter: React.FC<GlobalFilterProps> = (props) => {
  const { preGlobalFilteredRows, globalFilter, setGlobalFilter } = { ...props };
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <Form.Control
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
        style={{
          fontSize: '1rem',
          border: '0',
          width: '100%'
        }}
      />
    </span>
  );
};

export default GlobalFilter;
