import React from 'react';
import Select, { Props } from 'react-select';

export type Option = {
  label: string;
  value: string;
};

const TimezoneSelect = (props: Props<Option>) => {
  const options = Intl.supportedValuesOf('timeZone').map((timezone) => ({
    label: timezone,
    value: timezone
  }));

  return (
    <Select
      options={options}
      placeholder={'Select the stations timezone'}
      {...props}
    />
  );
};

export default TimezoneSelect;

