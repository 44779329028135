import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import {
  Access,
  accessArraySchema
} from '../../../../store/user_access_arrays/AccessArrayTypes';
import { UnproccessableEntityError } from '../../../../store/stations/errors/UnprocessableEntityError';
import { AccessArrayContext } from '../../../../store/StoreContexts';
import EditAccessArrayForm from './EditAccessArrayForm';

type EditAccessArrayProps = {
  accessArrayDetails: Access | undefined;
  setEditMode: Function;
};

const EditAccessArray: React.FC<EditAccessArrayProps> = (props) => {
  const accessStore = useContext(AccessArrayContext);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Exits edit mode on successful submission.
    if (submitted) props.setEditMode(false);
  }, [submitted]);

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      validateOnMount
      validateOnChange
      validationSchema={accessArraySchema}
      initialValues={props.accessArrayDetails!}
      onSubmit={(values, { setFieldError }) => {
        setTimeout(() => {
        accessStore
            .updateAccessArray(values, values.id)
            .then(() => setSubmitted(true))
            .catch((error) => {
              if (error instanceof UnproccessableEntityError) {
                error.errors.then((data: any) => {
                  Object.keys(data.errors).forEach((err: any) =>
                    setFieldError(err, data.errors[err][0])
                  );
                });
                setSubmitted(false);
              }
            });
        });
      }}
    >
      {(formikProps) => (
        <Card>
          <Card.Header>
            Access Array Details
            <ButtonGroup style={{ float: 'right', margin: -4 }}>
              <Button
                size='sm'
                type='submit'
                onClick={() => {
                  formikProps.handleSubmit();
                }}
              >
                Save
              </Button>
              <Button
                variant='secondary'
                size='sm'
                onClick={() => props.setEditMode(false)}
              >
                Discard
              </Button>
            </ButtonGroup>
          </Card.Header>
          <Card.Body>
            <EditAccessArrayForm {...formikProps} />
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

export default observer(EditAccessArray);
