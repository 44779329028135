import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { WeatherStationDetailsCardProps } from '../WeatherStationTypes';
import StationRegionDetails from './StationRegionDetails';

/**
 * This component wraps the StationRegionDetails component. This provides the
 * outer Card component and headers, as well as functionality for interacting
 * with the WeatherStationManager page.
 *
 * @param props Props for StationRegionDetails and editing state from manager.
 */
export default function StationRegionDetailsCard(
  props: Pick<WeatherStationDetailsCardProps, 'wsdProps'>
) {
  return (
    <Card className='m-2'>
      <Card.Header>
        <span>Region</span>
        <Button
          as={Link}
          to={'/countries-regions-manager'}
          variant='primary'
          size='sm'
          style={{ float: 'right', margin: -3 }}
        >
          Manage Regions
        </Button>
      </Card.Header>
      <StationRegionDetails station={props.wsdProps.station} />
    </Card>
  );
}

