import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { HilltopStationsContext } from '../../../../../store/StoreContexts';
import MeasurementsTable from '../MeasurementsTable/MeasurementsTable';
import MetloadsTable from '../MetloadsTable/MetloadsTable';

const HilltopStationManager = () => {
  const hilltopStore = useContext(HilltopStationsContext);

  return hilltopStore.hilltopSiteMeasurementsLoading ? (
    <Row>
      <Col>
        <Spinner animation='border' variant='primary' />
      </Col>
    </Row>
  ) : hilltopStore.hasSelectedStation() &&
    hilltopStore.selectedStation?.station?.metloadsWithMeasurements ? (
    <>
      <Row className='mt-3'>
        {hilltopStore.selectedStationSiteMeasurements ? (
          <>
            <Col xs={3}>
              <h4>Measurements</h4>
              <MeasurementsTable />
            </Col>
            <Col xs={9}>
              <h4>Metloads</h4>
              <MetloadsTable />
            </Col>
          </>
        ) : null}
      </Row>
    </>
  ) : null;
};

export default observer(HilltopStationManager);

