import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import {
  Alert,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import {
  HilltopStationsContext,
  MetloadContext
} from '../../../store/StoreContexts';
import { isHilltopProvider } from '../../../store/hilltop_stations/HilltopUtils';
import { useQuery } from '../../../utils/requests/Query';
import StationsMap from '../../maps/StationsMap';
import CenterSpinner from '../../utils/CenterSpinner';
import HilltopStationManager from './components/HilltopStationManager/HilltopStationManager';
import HilltopStationsTable from './components/HilltopStationsTable/HilltopStationsTable';
import LinkHilltopSiteForm from './components/LinkHilltopSiteForm/LinkHilltopSiteForm';
import ProviderSelect from './components/ProviderSelect';

const HilltopStationsPage = () => {
  const hilltopStore = useContext(HilltopStationsContext);
  const metloadStore = useContext(MetloadContext);
  const query = useQuery();
  const provider = query.get('provider') || '';

  // Set provider if it is a valid provider.
  if (isHilltopProvider(provider)) {
    hilltopStore.setProvider = provider;
  }

  useEffect(() => {
    const init = async () => {
      if (hilltopStore.stationStore.stations.length === 0)
        await hilltopStore.stationStore.requestStations();
      await metloadStore.initializeData();
    };
    init();

    // De-select station when the page is un-mounted.
    return () => {
      hilltopStore.selectedStationIndex = undefined;
    };
  }, [hilltopStore, metloadStore]);

  useEffect(() => {
    let ignore = false;
    // Fetch relevant stations
    if (hilltopStore.provider && !ignore) {
      hilltopStore.loadRelevantStations(hilltopStore.provider);
    }
    return () => {
      ignore = true;
    };
  }, [hilltopStore, hilltopStore.provider]);

  return hilltopStore.stationStore.loading || metloadStore.loading ? (
    <CenterSpinner />
  ) : (
    <Container className='w-100'>
      <Row>
        <Col>
          <Row className='justify-content-md-center mt-4'>
            <Col md={6}>
              <ProviderSelect />
            </Col>
          </Row>
          {hilltopStore.provider && (
            <Row className='mt-2'>
              <Col>
                <Row>
                  <Col>
                    <Card className='my-3 w-100 rounded-0'>
                      <HilltopStationsTable />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Alert variant='info'>
                      <strong>Note:</strong> Only stations providing at least
                      temperature, humidity and rainfall measurements are
                      listed.
                    </Alert>
                  </Col>
                </Row>
                {hilltopStore.selectedStation ? (
                  hilltopStore.selectedStation.station &&
                  hilltopStore.selectedStation.site ? (
                    <Row>
                      <Col>
                        <HilltopStationManager />
                      </Col>
                    </Row>
                  ) : (
                    <Modal
                      show={hilltopStore.selectedStation ? true : false}
                      onHide={() => {
                        hilltopStore.selectedStationIndex = undefined;
                      }}
                    >
                      <Modal.Header>Link Site</Modal.Header>
                      <Modal.Body>
                        <LinkHilltopSiteForm />
                      </Modal.Body>
                    </Modal>
                  )
                ) : null}
                <Row>
                  <Col>
                    {hilltopStore.hilltopRelevantStationsLoading ? (
                      <div className='text-center'>
                        <Spinner animation='border' variant='primary' />
                      </div>
                    ) : hilltopStore.hilltopRelevantStations.length > 0 ? (
                      <StationsMap
                        hilltopProviderName={hilltopStore.getProviderName}
                        stations={hilltopStore.hilltopRelevantStations.map(
                          (hrs) => {
                            return {
                              Station_ID: hrs.Station_ID,
                              Logger_Name: hrs.name,
                              Longitude: Number(hrs.longitude),
                              Latitude: Number(hrs.latitude),
                              Logger_Type: 'Hilltop'
                            };
                          }
                        )}
                        loading={false}
                      />
                    ) : (
                      !hilltopStore.hilltopRelevantStationsLoading && (
                        <p className='text-center'>
                          The selected provider has no stations.
                        </p>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default observer(HilltopStationsPage);

