import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import Loading from '../../layout/Loading';
import YesNoCheckIcon from '../../utils/YesNoCheckIcon';
import GlobalFilter from '../../utils/table/GlobalFilter';
import { WeatherStationTableProps } from './WeatherStationTypes';

require('./wsmanager.scss');

/**
 * This components renders a table listing all weather stations, with a filter
 * input box allowing the user to search by station ID and name with regex.
 *
 * Table is created with react-table and rendered with react-bootstrap table
 * elements.
 *
 * @param props WeatherStationTableProps, containing station data to construct
 * the table.
 */
function WeatherStationTable(props: WeatherStationTableProps) {
  // Data should be formatted as an array of objects.
  const [data, setData] = useState(props.data);

  useEffect(() => {
    // Ensures that table data is updated when props change.
    setData([...props.data]);
  }, [props.data]);

  const history = useHistory();

  // This defines the data to be displayed in the table. Header represents the
  // heading that will be displayed for a given column, while accessor defines
  // the field to access in the data object for the corresponding header.
  const columns = useMemo(
    () => [
      {
        Header: 'Order',
        accessor: 'DisplayOrder'
      },
      {
        Header: 'Station ID',
        accessor: 'Station_ID'
      },
      {
        Header: 'Station Name',
        accessor: 'Logger_Name'
      },
      {
        Header: 'Backup Station',
        accessor: 'Backup_Station_ID'
      },
      {
        Header: 'ET Station',
        accessor: 'ET_Station'
      },
      {
        Header: 'Region',
        accessor: 'Region',
        Cell: (props: any) => {
          return props.row.original.region?.name || '';
        }
      },
      {
        Header: 'Logger Type',
        accessor: 'Logger_Type'
      },
      {
        Header: 'Active',
        accessor: 'Active',
        Cell: (props: any) => (
          <YesNoCheckIcon value={props.cell?.value === 'Y'} />
        )
      },
      {
        Header: 'Status ID',
        accessor: 'status_id'
      },
      {
        Header: 'UTC',
        accessor: 'is_utc',
        Cell: (props: any) => (
          <YesNoCheckIcon value={props.cell?.value === 'Y'} />
        )
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  return (
    <Card className='tableContainer pageItem m-2'>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      {/* This div creates a scroll box for the table. */}
      <div style={{ height: '300px', overflowY: 'scroll', marginRight: 0 }}>
        {props.loading && data.length === 0 ? (
          <Loading />
        ) : (
          <Table {...getTableProps()} size='sm' striped hover>
            <thead className='tableHeader'>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header') + ' '}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);

                const showStationDetails = () => {
                  const url = '/?id=' + row.values.Station_ID;
                  history.push(url);
                };

                return (
                  <tr
                    onClick={showStationDetails}
                    className={
                      row.values.Station_ID === props.currentStation?.Station_ID
                        ? 'table-primary'
                        : ''
                    }
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </Card>
  );
}
export default observer(WeatherStationTable);

