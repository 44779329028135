import { HilltopStore } from "../../../../../../store/hilltop_stations/HilltopStore";
import UserStore from "../../../../../../store/user/UserStore";
import { fetchWithRefresh } from "../../../../../../utils/requests/RequestsHelpers";
import { successToast, errorToast } from "../../../../../utils/ToastContainer";

/**
	 * Sends an API request to remove a metload and updates the station
	 * in the harvestStationsStore
	 *
	 * @param {string} variableId
	 * @param {string} measurementTypeId
	 */
export const removeMeasurment = async (
	hilltopStore: HilltopStore,
	userStore: UserStore,
	variableId: string,
	measurementTypeId: string,
	hilltopMeasurementId: number,
) => {
	if (window.confirm('Are you sure you want to delete this measurement?')) {
		//setLoading(true);
		fetchWithRefresh(
			userStore,
			`${process.env.REACT_APP_METWATCH_API_URL}
			api/admin/weather-providers/hilltop/remove-measurement/
			${hilltopStore.selectedStation?.station?.id}/
			${hilltopMeasurementId}`,
			{
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${userStore.user?.userToken.jwt_bearer}`,
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					variable_id: variableId,
					measurement_type_id: measurementTypeId
				})
			}
		)
			.then((res) => {
				if (res?.ok) return res.json();
				else throw res?.statusText;
			})
			.then(async (data) => {
				// Returns the harvest station model so update the selected station
				// in the harvestStationsStore with the new data
				if (hilltopStore.selectedStation) {
					const station = { ...hilltopStore.selectedStation };
					station.station = data;
					await hilltopStore.getMetloadsMeasurements(station);
					hilltopStore.updateStation(station);
					successToast('Successfully deleted the measurement');
				}
			})
			.catch((err) => {
				if (process.env.NODE_ENV === 'development') console.error(err);
				errorToast('Something went wrong deleting the measurement');
			})
			.finally(() => {
				//setLoading(false);
			});
	}
};
