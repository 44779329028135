import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { StationContext } from '../../../../store/StoreContexts';
import { Region } from '../../../../store/regions/RegionsTypes';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import TimezoneSelect from '../../../utils/selects/TimezoneSelect';

interface Props {
  hideModal: () => void;
  region: Region;
}

const UpdateRegionForm = ({ hideModal, region }: Props) => {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        country_id: region.country_id,
        name: region.name,
        timezone: region.timezone,
        display_order: region.display_order
      }}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          await regionStore.updateRegion(region.id, values);
          successToast('Region updated successfully.', 'update-region-success');
          hideModal();
        } catch (error) {
          errorToast('Failed to update region.', 'update-region-error');
        }
        setLoading(false);
      }}
    >
      {(fProps) => (
        <Form onSubmit={fProps.handleSubmit}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              name='name'
              onChange={fProps.handleChange}
              value={fProps.values.name}
              isInvalid={!!fProps.errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='timezone'>
            <Form.Label>Timezone</Form.Label>
            <p className='mb-0' style={{ fontSize: 12 }}>
              Use{' '}
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://askgeo.com/', '_blank');
                }}
              >
                this tool
              </a>{' '}
              to help identify the station's timezone.
            </p>
            <TimezoneSelect
              defaultValue={{
                label: fProps.values.timezone,
                value: fProps.values.timezone
              }}
              onChange={(option: any) => {
                fProps.setFieldValue('timezone', option ? option.value : '');
              }}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.timezone}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='display_order'>
            <Form.Label>Display Order</Form.Label>
            <p className='mb-0' style={{ fontSize: 12 }}>
              Automatically set when a country is selected.
            </p>
            <Form.Control
              type='number'
              min={1}
              step={1}
              name='display_order'
              onChange={fProps.handleChange}
              value={fProps.values.display_order}
              isInvalid={!!fProps.errors.display_order}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.display_order}
            </Form.Control.Feedback>
          </Form.Group>
          <div className='d-flex justify-content-end'>
            <Button
              type='submit'
              className={
                Object.keys(fProps.errors).length > 0
                  ? 'btn-outline-danger btn-danger btn-sm m-1 disabled'
                  : 'btn-primary btn-sm m-1'
              }
            >
              {loading ? (
                <Spinner className='mx-1' animation={'border'} size='sm' />
              ) : (
                'Save'
              )}
            </Button>
            <Button
              type='button'
              className='btn btn-secondary btn-sm m-1'
              onClick={() => {
                hideModal();
              }}
            >
              Discard
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default observer(UpdateRegionForm);

