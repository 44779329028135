import { HilltopStore } from "../../../../../store/hilltop_stations/HilltopStore";
import { HilltopProvider } from "../../../../../store/hilltop_stations/HilltopUtils";

export const loadStationsSites = async (hilltopStore: HilltopStore, provider: HilltopProvider | undefined) => {
	await hilltopStore.loadStations(provider);
	await hilltopStore.loadSites(provider);

	hilltopStore.getMatchedStationsSites();

	return hilltopStore.hilltopMatchedStationSites
}
