import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { HaloStationsContext } from '../../../../store/StoreContexts';
import { MatchedStationGroup } from '../../../../store/halo_stations/HaloUtils';

export const useHaloStationsTable = (data: MatchedStationGroup[]) => {
  const haloStore = useContext(HaloStationsContext);

  // Filter 'data' to only contain MatchedStationGroup that has a group
  // that is contained in the HaloStore 'haloData.groups' array.
  const dataRelevantStations = useMemo(
    () =>
      data
        .filter((matched) => {
          const matchedGroup = matched.group;
          const stations = haloStore.haloData.groups;
          return stations.some((group) => group.id === matchedGroup.id);
        })
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.group.id === value.group.id)
        ),
    [data, haloStore.haloData.groups]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Halo Info',
        canSort: false,
        columns: [
          {
            Header: 'Group ID',
            accessor: 'group.id',
            Cell: (props: any) => {
              return (
                <a
                  href={
                    'https://halo.dashboard.co.nz/Group/' + props.cell?.value
                  }
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {props.cell?.value}
                </a>
              );
            }
          },
          {
            Header: 'Group Name',
            accessor: 'group.name',
            Cell: (props: any) => {
              return props.cell?.value;
            }
          }
        ]
      },
      {
        Header: 'HortPlus Info',
        columns: [
          {
            Header: 'Station ID',
            accessor: 'station.station_id',
            Cell: (props: any) => (
              <Link to={'/?id=' + props.cell?.value}>{props.cell?.value}</Link>
            )
          },
          {
            Header: 'Logger Name',
            accessor: 'station.station.Logger_Name'
          },
          {
            Header: 'Latitude',
            accessor: 'station.station.Latitude',
            Cell: (props: any) => {
              return props.cell?.value || '-';
            }
          },
          {
            Header: 'Longitude',
            accessor: 'station.station.Longitude',
            Cell: (props: any) => {
              return props.cell?.value || '-';
            }
          }
        ]
      },
      {
        Header: 'Misc',
        columns: [
          {
            Header: 'Status',
            Cell: (props: any) => {
              const original = props.cell.row.original;
              return original.group && original.station ? (
                original.station.setup === 1 ? (
                  <span className='text-success'>Setup Complete</span>
                ) : (
                  <span className='text-warning'>Setup Incomplete</span>
                )
              ) : original.group && !original.station ? (
                <span className='text-danger'>Unlinked Group</span>
              ) : !original.group && original.station ? (
                <span className='text-danger'>Invalid group_id</span>
              ) : (
                <span className='text-danger'>Invalid record</span>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const table = useTable(
    {
      columns,
      data: dataRelevantStations,
      initialState: { pageIndex: 0, pageSize: 50 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return table;
};

