import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import UserStore from '../user/UserStore';
import { User, UserDetails } from '../user/UserTypes';

/**
 * Returns all users, regardless of the tenant.
 * @param userStore Current admin user UserStore.
 */
export async function getAllUsers(
  userStore: UserStore
): Promise<Record<string, (UserDetails & { id?: number })[]>> {
  return await fetchWithRefresh(
    userStore,
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/allusers',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer,
        service: 'metwatch'
      }
    }
  ).then((response) => {
    if (response!.ok) return response!.json();
    else throw Error(response!.statusText);
  });
}
