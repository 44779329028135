import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { MatchedMetserviceStation } from '../../../../store/metservice_stations/MetserviceUtils';

export const useMetserviceStationsTable = (
  data: MatchedMetserviceStation[]
) => {
  const dataRelevantStations = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'Metservice Info',
        canSort: false,
        columns: [
          {
            Header: 'Station ID',
            accessor: 'station.id',
            Cell: (props: any) => {
              return props.cell?.value;
            }
          },
          {
            Header: 'Station Name',
            accessor: 'station.name',
            Cell: (props: any) => {
              return props.cell?.value || '-';
            }
          }
        ]
      },
      {
        Header: 'HortPlus Info',
        columns: [
          {
            Header: 'Station ID',
            accessor: 'site.station_id',
            Cell: (props: any) => (
              <Link to={'/?id=' + props.cell?.value}>{props.cell?.value}</Link>
            )
          },
          {
            Header: 'Logger Name',
            accessor: 'site.station.Logger_Name'
          },
          {
            Header: 'Latitude',
            accessor: 'site.station.Latitude',
            Cell: (props: any) => {
              return props.cell?.value || '-';
            }
          },
          {
            Header: 'Longitude',
            accessor: 'site.station.Longitude',
            Cell: (props: any) => {
              return props.cell?.value || '-';
            }
          }
        ]
      },
      {
        Header: 'Misc',
        columns: [
          {
            Header: 'Status',
            Cell: (props: any) => {
              const original = props.cell.row.original;
              return original.station && original.site ? (
                original.site.setup === 1 ? (
                  <span className='text-success'>Setup Complete</span>
                ) : (
                  <span className='text-warning'>Setup Incomplete</span>
                )
              ) : original.station && !original.site ? (
                <span className='text-danger'>Unlinked Station</span>
              ) : !original.station && original.site ? (
                <span className='text-danger'>Invalid wmo_id</span>
              ) : (
                <span className='text-danger'>Invalid record</span>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const table = useTable(
    {
      columns,
      data: dataRelevantStations,
      initialState: { pageIndex: 0, pageSize: 50 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return table;
};

