import * as Yup from 'yup';
/*
 * This file defines the types related to user details, authentication and
 * authorisation.
 */

/**
 * JWT token type. Includes valid flag.
 */
export interface UserAuthToken {
  valid: boolean;
  jwt_bearer: string | undefined;
  jwt_refresh: string | undefined;
}

/**
 * User details, as per GET user API specification.
 */
export interface UserDetails {
  client_id?: number;
  id?: number;
  name?: string;
  email: string;
  service: string | undefined; // TODO: Specific tenants here
  access_id: number | undefined;
  industry_id: number;
  registered: string | null;
  created_at: string | null;
  login_count: number;
  logins: number;
  last_login: string;
  lastlogin: string;
  onions_id?: number;
  far_id?: number;
  nzap_id?: number;
  zespri_id?: number;
  user_id?: number;
  ppi_id?: number;
  summer_id?: number;
  station_id?: string | undefined;
  nzwine_id?: number;
  pfr_id?: number;
  integrape_id?: number;
  login_type: string | undefined;
  password?: string;
}

/**
 * Wrapper User type.
 */
export interface User {
  userDetails: UserDetails;
  userToken: UserAuthToken;
  isAdmin: boolean;
}

export interface UserJwtResponse {
  jwt_token: string;
  refresh_token: string | undefined;
}

export interface userJwt {
  iss: string;
  aud: string;
  iat: number;
  nbf: number;
  exp: number;
  login_type: string;
  uid: number;
  access: JSON;
}

export const userSchema = Yup.object().shape({
  id: Yup.number().required(),
  email: Yup.string()
    .email('Email must be a valid email address')
    .nullable(),
});