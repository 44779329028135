import React from 'react';
import { Card, Button, ButtonGroup, ListGroup } from 'react-bootstrap';
import { AccessArrayDetailsCardProps } from '../AccessArrayTypes';
import EditAccessArray from '../forms/EditAccessArray';
import { DetailItem as AccessArrayDetailItem } from '../../../utils/DetailItem';



/**
 * This component wraps the AccessArrayDetails component. This provides the
 * outer Card component and headers, as well as functionality for interacting
 * with the AccessArrayManager page.
 *
 * @param props Props for WeatherStationDetails and editing state from manager.
 */
export default function AccessArrayDetailsCard(
  props: AccessArrayDetailsCardProps
) {
  
  const toHumanReadableDate = (date: Date) =>
    date.toDateString().split(' ').splice(0, 4).join(' ');

  const [editMode, setEditMode] = React.useState(false);
  return (
    <Card className='m-2'>
      {editMode ? (
        <EditAccessArray 
        accessArrayDetails={props.aadProps.currentAccessArray}
        setEditMode={setEditMode} />
      ):(
        <><Card.Header>
            Access Array Index Details
            <ButtonGroup style={{ float: 'right', margin: -4 }}>
              <Button size='sm' onClick={() => setEditMode(true)}>
                Edit
              </Button>
            </ButtonGroup>
          </Card.Header>
          <Card.Body>
            <Card.Title>{props.aadProps.currentAccessArray?.name}</Card.Title>
            <ListGroup variant='flush'>
              {props.aadProps.currentAccessArray ? (
                <><AccessArrayDetailItem
                  title='Access ID'
                  content={props.aadProps.currentAccessArray?.id} /><AccessArrayDetailItem
                    title='Name'
                    content={props.aadProps.currentAccessArray?.name} /><AccessArrayDetailItem
                    title='Description'
                    content={props.aadProps.currentAccessArray?.description} /><AccessArrayDetailItem
                    title='MW Access ID'
                    content={props.aadProps.currentAccessArray?.mw_access_id} /><AccessArrayDetailItem
                    title='MDR Access ID'
                    content={props.aadProps.currentAccessArray?.mdr_access_id} /><AccessArrayDetailItem
                    title='Created at'
                    content={props.aadProps.currentAccessArray?.created_at
                      ? toHumanReadableDate(new Date(props.aadProps.currentAccessArray?.created_at))
                      : ''} /><AccessArrayDetailItem
                    title='Updated at'
                    content={props.aadProps.currentAccessArray?.updated_at
                      ? toHumanReadableDate(new Date(props.aadProps.currentAccessArray?.updated_at))
                      : ''} /></>
              ):(
                <div>Please select access array to view details...</div>
              )}
            </ListGroup>
          </Card.Body></>
        )}
    </Card>
  );
}

