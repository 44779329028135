import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useSortBy, useTable, useGlobalFilter } from 'react-table';
import { Card, Table } from 'react-bootstrap';
import { AccessArrayTableProps } from './AccessArrayTypes';
import { useHistory } from 'react-router-dom';
import Loading from '../../layout/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import GlobalFilter from '../../utils/table/GlobalFilter';


function AccessArrayTable(props: AccessArrayTableProps) {
    // Data should be formatted as an array of objects.
    const history = useHistory();
    const columns = useMemo(
        () => [
          {
            Header: 'Access Array ID',
            accessor: 'id'
          },
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Description',
            accessor: 'description'
          },
          {
            Header: 'MW Access ID',
            accessor: 'mw_access_id'
          },
          {
            Header: 'MDR Access ID',
            accessor: 'mdr_access_id'
          }
        ],
        []
      );

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter
      } = useTable(
        {
          columns,
          data: props.data
        },
        useGlobalFilter, useSortBy
      );
      return (
        <Card className='tableContainer pageItem m-2'>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
          <div className='tableBox'>
        {props.loading ? (
          <Loading />
        ) : (
          <Table {...getTableProps()} size='sm' striped hover>
            <thead className='tableHeader'>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header') + ' '}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                const showAccessArrayDetails = () => {
                  const url = '/access-array-manager?id=' + row.values.id;
                  history.push(url);
                };
                return (
                  <tr
                    onClick={showAccessArrayDetails}
                    className={
                      row.values.id === props.currentAccessArray?.id
                        ? 'table-primary'
                        : ''
                    }
                    {...row.getRowProps()}
                    >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </Card>
  );
}
export default observer(AccessArrayTable);