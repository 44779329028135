import { makeAutoObservable, runInAction } from 'mobx';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import UserStore from '../user/UserStore';
import {
  AddCountryFields,
  AddRegionFields,
  Country,
  Region,
  UpdateCountryFields,
  UpdateRegionFields
} from './RegionsTypes';

export class RegionStore {
  userStore: UserStore;
  countries: Country[];

  constructor(userStore: UserStore) {
    makeAutoObservable(this);
    this.userStore = userStore;
    this.countries = [];
  }

  async fetchCountries() {
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/station/country?with[]=regions`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.userStore.user?.userToken.jwt_bearer
          }
        }
      );
      const data = (await response?.json()) as Country[];
      runInAction(() => {
        this.countries = data;
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
    }
  }

  async addCountry(fields: AddCountryFields) {
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/station/country`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.userStore.user?.userToken.jwt_bearer
          },
          body: JSON.stringify({ ...fields })
        }
      );
      const data = (await response?.json()) as Country;
      runInAction(() => {
        this.countries = [...this.countries, { ...data, regions: [] }];
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      throw new Error('Failed to add country.');
    }
  }

  async addRegion(fields: AddRegionFields) {
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/station/region`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.userStore.user?.userToken.jwt_bearer
          },
          body: JSON.stringify({ ...fields })
        }
      );
      const data = (await response?.json()) as Region;
      let countryIndex = this.countries.findIndex(
        (c) => c.id === data?.country_id
      );
      if (countryIndex === -1) throw new Error('This region has no country.');
      runInAction(() => {
        this.countries[countryIndex].regions = [
          ...this.countries[countryIndex].regions,
          data
        ];
      });
    } catch (error) {
      console.error(error);
      throw new Error('Failed to add region.');
    }
  }

  async updateCountry(countryId: number, fields: UpdateCountryFields) {
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/station/country/${countryId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.userStore.user?.userToken.jwt_bearer
          },
          body: JSON.stringify({ ...fields })
        }
      );
      const data = (await response?.json()) as Country;
      let countryIndex = this.countries.findIndex((c) => c.id === data?.id);
      if (countryIndex === -1)
        throw new Error('Could not find country to update.');
      runInAction(() => {
        this.countries[countryIndex] = {
          ...data,
          regions: this.countries[countryIndex].regions
        };
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.error(error);
      throw new Error('Failed to update country.');
    }
  }

  async updateRegion(regionId: number, fields: UpdateRegionFields) {
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/station/region/${regionId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.userStore.user?.userToken.jwt_bearer
          },
          body: JSON.stringify({ ...fields })
        }
      );
      const data = (await response?.json()) as Region;
      let countryIndex = this.countries.findIndex(
        (c) => c.id === data?.country_id
      );
      const regionIndex = this.countries[countryIndex].regions.findIndex(
        (r) => r.id === data?.id
      );
      if (countryIndex === -1) throw new Error('This region has no country.');
      if (regionIndex === -1)
        throw new Error('Could not find region to update.');
      runInAction(() => {
        this.countries[countryIndex].regions[regionIndex] = data;
      });
    } catch (error) {
      console.error(error);
      throw new Error('Failed to add region.');
    }
  }

  async deleteCountry(countryId: number) {
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/station/country/${countryId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.userStore.user?.userToken.jwt_bearer
          }
        }
      );
      if (response && response?.status >= 400) {
        throw new Error('Failed to delete country.');
      } else {
        const countryIndex = this.countries.findIndex(
          (c) => c.id === countryId
        );
        const newCountries = this.countries;
        newCountries.splice(countryIndex, 1);
        this.countries = newCountries;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteRegion(countryId: number, regionId: number) {
    try {
      const response = await fetchWithRefresh(
        this.userStore,
        `${process.env.REACT_APP_METWATCH_API_URL}api/admin/station/region/${regionId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.userStore.user?.userToken.jwt_bearer
          }
        }
      );
      if (response && response?.status >= 400) {
        throw new Error('Failed to delete region.');
      } else {
        const countryIndex = this.countries.findIndex(
          (c) => c.id === countryId
        );
        const regionIndex = this.countries[countryIndex].regions.findIndex(
          (r) => r.id === regionId
        );
        const newRegions = this.countries[countryIndex].regions;
        newRegions.splice(regionIndex, 1);
        this.countries[countryIndex].regions = newRegions;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getCountry(id: number) {
    return this.countries.find((country) => country.id === id) || null;
  }

  getRegions(country_id: number) {
    return this.getCountry(country_id)?.regions;
  }

  getRegion(country_id: number, region_id: number) {
    return this.getRegions(country_id)?.find(
      (region) => region.id === region_id
    );
  }

  findRegion(region_id: number) {
    return this.countries
      .flatMap((c) => c.regions)
      .find((r) => r.id === region_id);
  }
}

