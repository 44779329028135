import React, { useContext, useState } from 'react';
import { StationContext } from '../../../../store/StoreContexts';
import { observer } from 'mobx-react-lite';
import { Modal, Button } from 'react-bootstrap';
import { ReportContext } from '../../../../store/StoreContexts';
import { Report } from '../../../../store/reports/MdrUtils';

type DeleteReportModalProps = {
  report: Report;
};

const DeleteReportModal: React.FC<DeleteReportModalProps> = (props) => {
  const reportStore = useContext(ReportContext);
  const stationsStore = useContext(StationContext);
  const currentReport = reportStore.reportTypes.find(
    (report) => report.id === props.report.report_type_id
  );
  const reportStation = stationsStore.stations.find(
    (station) => station.Station_ID === props.report.station_id
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteReport = () => reportStore.deleteReport(props.report.id);

  return (
    <>
      <Button variant='outline-danger' size='sm' onClick={handleShow}>
        Delete
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='pl-3'> Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='col-md mb-4'>
            {' '}
            Are you sure you want to delete{' '}
            <strong>{currentReport?.name}</strong> for station{' '}
            <strong>{reportStation?.Logger_Name}</strong> (report ID{' '}
            {props.report.id})?
            <div className='d-flex justify-content-center mt-4'>
              {' '}
              <Button
                className='m-1'
                variant='outline-secondary'
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>
              <Button className='m-1' variant='danger' onClick={deleteReport}>
                Delete
              </Button>
            </div>
          </div>
        </Modal.Body>{' '}
      </Modal>
    </>
  );
};

export default observer(DeleteReportModal);
