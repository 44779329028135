import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tokml from '@maphubs/tokml';
import 'mapbox-gl/dist/mapbox-gl.css';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Map, Marker, Popup } from 'react-map-gl';
import { StationContext } from '../../store/StoreContexts';
import { Station } from '../../store/stations/StationTypes';
import { downloadTxtFile } from '../../utils/utils';
import CenterSpinner from '../utils/CenterSpinner';

type PopupInfo = {
  station: DisplayStation;
  provider?: string;
};

type DisplayStation = Pick<
  Station,
  'Station_ID' | 'Logger_Name' | 'Logger_Type' | 'Latitude' | 'Longitude'
>;

interface Props {
  hilltopProviderName?: string;
  stations: DisplayStation[];
  loading: boolean;
  initialLocationState?: {
    latitude: number;
    longitude: number;
  };
}

const StationsMap = ({
  hilltopProviderName,
  stations,
  loading,
  initialLocationState = {
    longitude: 173.81,
    latitude: -41.05
  }
}: Props) => {
  const stationStore = useContext(StationContext);
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);

  const selectedStation = stationStore.currentStation;

  const kml = useMemo(() => {
    const geojson = {
      type: 'FeatureCollection',
      features: stations.map((station) => {
        const properties: any = {
          type: station.Logger_Type || 'Unknown',
          name: station.Logger_Name
        };
        if (hilltopProviderName) properties.provider = hilltopProviderName;
        return {
          type: 'Feature',
          properties,
          geometry: {
            coordinates: [station.Longitude, station.Latitude],
            type: 'Point'
          }
        };
      })
    };
    return tokml(geojson);
  }, [hilltopProviderName, stations]);

  const markers = useMemo(() => {
    setPopupInfo(null);
    const sortedStations = [...stations];
    const selectedStationIndex = sortedStations.findIndex(
      (s) => s.Station_ID === selectedStation?.Station_ID
    );
    // Move selected station to the end of the array so the yellow marker appears
    // above all other markers.
    if (selectedStationIndex > -1) {
      const moveElement = sortedStations.splice(selectedStationIndex, 1)[0];
      sortedStations.push(moveElement);
    }
    return sortedStations.map((station, index) => (
      <Marker
        key={`marker-${index}`}
        longitude={station.Longitude}
        latitude={station.Latitude}
        anchor={'bottom'}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          setPopupInfo({ station, provider: hilltopProviderName });
        }}
      >
        <FontAwesomeIcon
          icon={faLocationDot as any}
          size='lg'
          color={
            station.Station_ID === selectedStation?.Station_ID
              ? 'yellow'
              : 'white'
          }
        />
      </Marker>
    ));
  }, [stations, selectedStation?.Station_ID, hilltopProviderName]);

  return (
    <>
      {loading ? (
        <Row>
          <Col className='text-center'>
            <CenterSpinner />
          </Col>
        </Row>
      ) : (
        <>
          <Map
            initialViewState={{
              ...initialLocationState,
              zoom: 4
            }}
            style={{ width: '100%', height: 450 }}
            mapStyle={`mapbox://styles/hortplus/cknpikbkn0jut17nwxfi4mbh8`}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          >
            {markers}
            {popupInfo && (
              <Popup
                anchor='top'
                longitude={popupInfo.station.Longitude}
                latitude={popupInfo.station.Latitude}
                onClose={() => setPopupInfo(null)}
              >
                <div>
                  {popupInfo.station.Logger_Type && (
                    <h6 style={{ fontWeight: 'bold' }}>
                      {popupInfo.station.Logger_Type}
                    </h6>
                  )}
                  {popupInfo.provider && (
                    <p style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                      {popupInfo.provider}
                    </p>
                  )}
                  <p style={{ marginBottom: '0px' }}>
                    {popupInfo.station.Logger_Name}
                  </p>
                </div>
              </Popup>
            )}
          </Map>
          <Row className='mt-2'>
            <Col className='text-center'>
              <Button onClick={() => downloadTxtFile(kml, 'stations.kml')}>
                Export to KML
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default observer(StationsMap);

