import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { BomStationsContext } from '../../../../store/StoreContexts';
import ElementsRow from './ElementsRow';

const ElementsTable = () => {
  const bomStore = useContext(BomStationsContext);
  const station = bomStore.selectedStation;
  const elements = bomStore.selectedStationElements;

  const hasData = () => {
    return station && elements && station.station && station.network;
  };

  return hasData() ? (
    <Table size='sm' bordered striped hover>
      <thead>
        <tr>
          <th style={{ fontWeight: 'bold' }}>Name</th>
        </tr>
      </thead>
      <tbody>
        {elements?.map((v) => {
          const name = v.type;
          const unit = v.units;
          return <ElementsRow key={name + unit} name={name} unit={unit} />;
        })}
      </tbody>
    </Table>
  ) : null;
};

export default observer(ElementsTable);

