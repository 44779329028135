import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { DetailItem as StationDetailItem } from '../../../utils/DetailItem';
import { WeatherStationDetailsProps } from '../WeatherStationTypes';

/**
 * Displays the details of a single weather station's status id & text override in a card body.
 *
 * Note that this component is returned within a Card.Body component - not a
 * Card component. This is because it is designed to be used within an existing
 * card, not on its own. See StationStatusDetailsCard for use.
 *
 * @param props Station data.
 */
export default function StationStatusDetails(
  props: WeatherStationDetailsProps
) {
  // Display default card if no station is selected.
  if (props.station === undefined)
    return <Card.Body>Select a station to see more...</Card.Body>;
  return (
    <Card.Body>
      <ListGroup variant='flush'>
      <StationDetailItem
          title='Status ID'
          content={props.station?.status_id}
        />
        <StationDetailItem
          title='Status Text Override'
          content={props.station?.status_text_override}
        />
      </ListGroup>
    </Card.Body>
  );
}

