import { Formik } from 'formik';
import { DateTime, Duration } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select-virtualized';
import {
  AllUsersContext,
  ReportContext
} from '../../../../store/StoreContexts';
import { MdrStore } from '../../../../store/reports/MdrStore';
import {
  NewSubscription,
  NewSubscriptionSchema
} from '../../../../store/reports/MdrUtils';
import { UnproccessableEntityError } from '../../../../store/stations/errors/UnprocessableEntityError';
import { UserDetails } from '../../../../store/user/UserTypes';
import ModalButton, {
  ToggleModalContext
} from '../../../utils/modal/ModalButton';
import NewSubscriptionForm from './NewSubscriptionForm';

/**
 * Renders a button which shows the modal to add a new subscription.
 */
const AddSubscriptionModal: React.FC = () => {
  const allUsers = useContext(AllUsersContext);
  const subscriptions = useContext(ReportContext);

  const emails = subscriptions.getUnsubscribedUsers().map((user) => {
    return {
      id: user.client_id,
      service: user.service,
      value: user.email,
      label: `${user.name} (${user.email}) - ${user.service}`,
      user: user
    };
  });

  const [selectedUser, setSelectedUser] = useState<UserDetails | undefined>(
    undefined
  );

  return (
    <ModalButton
      buttonContents='New Subscription'
      buttonProps={{ size: 'sm', className: 'mb-2 float-right' }}
      modalProps={{ size: 'lg', onHide: () => setSelectedUser(undefined) }}
    >
      <Modal.Header closeButton>
        <b>Subscribe a New User</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Label>Email Address</Form.Label>
          </Col>
          <Col>
            <Button disabled className='btn-sm mb-2' style={{ float: 'right' }}>
              Add a non-MetWatch user...
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              placeholder='Find a user...'
              onChange={(select: any) => {
                if (select)
                  setSelectedUser(
                    allUsers.findUser(select!.id, select!.service!)
                  );
              }}
              options={emails}
            />
          </Col>
        </Row>

        {selectedUser ? (
          <>
            <hr />
            <AddSubscription user={selectedUser} store={subscriptions} />
          </>
        ) : (
          <></>
        )}
      </Modal.Body>
    </ModalButton>
  );
};
export default observer(AddSubscriptionModal);

type AddSubscriptionProps = {
  user: UserDetails;
  store: MdrStore;
};

/**
 * Contains the Formik component for adding a new subscription.
 * @param props UserDetails to associate with the new sub and MdrStore reference.
 */
const AddSubscription: React.FC<AddSubscriptionProps> = (props) => {
  const toggleShow = useContext(ToggleModalContext);
  const generateSubscription = (
    user: UserDetails
  ): Partial<NewSubscription> => {
    return {
      suspended_at: null,
      user_type: user.service!,
      sub_type: '12 Month',
      start_date: DateTime.now().toFormat('yyyy-MM-dd'),
      stop_date: DateTime.now()
        .plus(Duration.fromObject({ year: 1 }))
        .toFormat('yyyy-MM-dd'),
      alternate_email: undefined,
      comments: '',
      cron_expression: 'Select cron expression',
      user_id: user.client_id!
    };
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        validateOnBlur
        validateOnChange
        validationSchema={NewSubscriptionSchema}
        initialValues={generateSubscription(props.user)}
        onSubmit={(values, { setFieldError }) => {
          props.store
            .createSubscription(values as NewSubscription, props.user)
            .then(() => {
              if (toggleShow) toggleShow();
            })
            .catch((error) => {
              if (error instanceof UnproccessableEntityError) {
                error.errors.then((data: any) => {
                  for (var err in data.errors) {
                    setFieldError(err, data.errors[err][0]);
                  }
                });
              }
            });
        }}
      >
        {(formikProps) => (
          <>
            <NewSubscriptionForm {...formikProps} user={props.user} />
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                formikProps.handleSubmit();
              }}
            >
              Add Subscription
            </Button>
          </>
        )}
      </Formik>
    </>
  );
};

