import React, { createContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

type ModalButtonProps = {
  buttonContents: React.ReactNode;

  buttonProps?: {
    [x: string]: any;
  };
  modalProps?: {
    [x: string]: any;
  };
};
export const ToggleModalContext = createContext<Function | undefined>(
  undefined
);
/**
 * Renders a button which shows/hides a modal when clicked.
 *
 * Regular button props can be passed into this component. The modal contents
 * should be injected as children.
 */
const ModalButton: React.FC<ModalButtonProps> = (props) => {
  const { children, buttonContents, buttonProps, modalProps } = props;
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <>
      <Button {...buttonProps} onClick={toggleShow}>
        {buttonContents}
      </Button>
      <Modal
        {...modalProps}
        show={show}
        onHide={
          // If an onHide function is passed in from the children, run this
          // before toggling show. Otherwise, just toggle.
          modalProps?.onHide
            ? () => {
                modalProps.onHide();
                toggleShow();
              }
            : toggleShow
        }
      >
        <ToggleModalContext.Provider value={toggleShow}>
          {children}
        </ToggleModalContext.Provider>
      </Modal>
    </>
  );
};
export default ModalButton;

