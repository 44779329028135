import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { MetrisStationsContext } from '../../../../store/StoreContexts';
import MetloadsTable from '../MetloadsTable/MetloadsTable';
import PointsTable from '../PointsTable/PointsTable';

const MetrisStationManager = () => {
  const metrisStore = useContext(MetrisStationsContext);
  const stationPoints = metrisStore.selectedStationPoints;
  const stationHasPoints = stationPoints && stationPoints.length > 0;

  return metrisStore.loadingStationPoints ? (
    <Row>
      <Col>
        <Spinner animation='border' variant='primary' />
      </Col>
    </Row>
  ) : metrisStore.hasSelectedStation() &&
    metrisStore.selectedStation?.network?.metloadWithPoints ? (
    <>
      <Row className='mt-3'>
        {stationHasPoints ? (
          <>
            <Col xs={3}>
              <h4>Points</h4>
              <PointsTable />
            </Col>
            <Col xs={9}>
              <h4>Metloads</h4>
              <MetloadsTable />
            </Col>
          </>
        ) : null}
      </Row>
    </>
  ) : null;
};

export default observer(MetrisStationManager);

