import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { HilltopStationsContext } from '../../../../../store/StoreContexts';

const MeasurementsTable = () => {
  const hilltopStore = useContext(HilltopStationsContext);

  const station = hilltopStore.selectedStation;
  const measurements = hilltopStore.selectedStationSiteMeasurements;

  const hasData = () => {
    return station && measurements && station.site && station.station;
  };

  return hasData() ? (
    <Table size='sm' bordered striped hover>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(measurements).flatMap((measurement, i) => (
          <tr key={i}>
            <td>{measurement.name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : null;
};

export default observer(MeasurementsTable);

