import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Modal, Button } from 'react-bootstrap';
import { ReportContext, UserContext } from '../../../../store/StoreContexts';
import { UnproccessableEntityError } from '../../../../store/stations/errors/UnprocessableEntityError';
import { MdrStore } from '../../../../store/reports/MdrStore';
import UserStore from '../../../../store/user/UserStore';
import { ReportSchema, NewReport } from '../../../../store/reports/MdrUtils';
import NewReportForm from './NewReportForm';

const AddReportModal: React.FC = () => {
  const reportStore = useContext(ReportContext);
  const userStore = useContext(UserContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant='primary' size='sm' onClick={handleShow}>
        Add Report
      </Button>

      <Modal show={show} onHide={handleClose} className='pr-0'>
        <Modal.Header closeButton>
          <Modal.Title>New Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddReport
            store={reportStore}
            userStore={userStore}
            onSuccess={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default observer(AddReportModal);

type AddReportProps = {
  store: MdrStore;
  userStore: UserStore;
  onSuccess: () => void;
};

const AddReport: React.FC<AddReportProps> = (props) => {
  const reportStore = useContext(ReportContext);

  const formInitialValue: NewReport = {
    subscription_id: props.store.current?.id ?? 0,
    report_type_id: null,
    station_id: '',
    forecast_id: '999',
    suspended_at: '',
    cron_expression: 'Select cron expression',
    settings: ''
  };

  return (
    <Formik
      initialValues={formInitialValue}
      enableReinitialize
      validateOnBlur
      validateOnMount
      validateOnChange
      validationSchema={ReportSchema}
      onSubmit={(values, { setFieldError }) => {
        reportStore
          .createReport(values)
          .then(() => {
            props.onSuccess();
          })
          .catch((error: UnproccessableEntityError) => {
            error.errors.then((data: any) => {
              for (var err in data.errors) {
                setFieldError(err, data.errors[err][0]);
              }
            });
          });
      }}
    >
      {(formikProps) => (
        <>
          <NewReportForm />
          <Modal.Footer>
            <Button
              style={{ float: 'right' }}
              onClick={() => {
                formikProps.handleSubmit();
              }}
            >
              Add Report
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
