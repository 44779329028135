import { UnproccessableEntityError } from '../stations/errors/UnprocessableEntityError';
import UserStore from '../user/UserStore';
import { UserAuthToken, UserJwtResponse, UserDetails } from '../user/UserTypes';

export type TenantUsers = UserDetails;

/**
 * Updates a single station record with the station in body.
 * @param userStore Current admin user UserStore.
 */
 export async function updateUser(
  token: UserAuthToken,
  tenant: string,
  user: UserDetails
) {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/tenants/' + tenant + '/users/' + user.id,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.jwt_bearer,
        service: tenant
      },
      body: JSON.stringify(user)
    }
  ).then((response) => {
    if (response.ok) return response.json();
    else if (response.status === 422)
      throw new UnproccessableEntityError(response.statusText, response.json());
    else throw Error(response.statusText);
  });
}


export async function getUserJwt(
  userStore: UserStore,
  tenant: string,
  id: number | undefined
): Promise<UserJwtResponse> {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/admin/token/jwt/generate/'+tenant+'/'+id,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + userStore.user?.userToken.jwt_bearer,
        service: 'metwatch'
      }
    }
  ).then((response) => {
    if (response.ok) 
    return response.json();
    else throw Error(response.statusText);
  })
}

export async function getUserIndustryJwt(
  industryJwt: string | undefined,
  tenant: string
): Promise<UserJwtResponse> {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/token/jwt/industry/user/generate',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + industryJwt,
        service: tenant
      }
    }
  ).then((response) => {
    if (response.ok) 
    return response.json();
    else throw Error(response.statusText);
  })
}