import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { StationContext } from '../../../../store/StoreContexts';
import { Country } from '../../../../store/regions/RegionsTypes';
import { errorToast, successToast } from '../../../utils/ToastContainer';

interface Props {
  hideModal: () => void;
  country: Country;
}

const UpdateCountryForm = ({ hideModal, country }: Props) => {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        name: country.name,
        code: country.code
      }}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          await regionStore.updateCountry(country.id, values);
          successToast(
            'Country updated successfully.',
            'update-country-success'
          );
          hideModal();
        } catch (error) {
          errorToast('Failed to update country.', 'update-country-error');
        }
        setLoading(false);
      }}
    >
      {(fProps) => (
        <Form onSubmit={fProps.handleSubmit}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              name='name'
              onChange={fProps.handleChange}
              value={fProps.values.name}
              isInvalid={!!fProps.errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='code'>
            <Form.Label>Code</Form.Label>
            <Form.Control
              type='text'
              name='code'
              onChange={fProps.handleChange}
              value={fProps.values.code}
              isInvalid={!!fProps.errors.code}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.code}
            </Form.Control.Feedback>
          </Form.Group>
          <div className='d-flex justify-content-end'>
            <Button
              type='submit'
              className={
                Object.keys(fProps.errors).length > 0
                  ? 'btn-outline-danger btn-danger btn-sm m-1 disabled'
                  : 'btn-primary btn-sm m-1'
              }
            >
              {loading ? (
                <Spinner className='mx-1' animation={'border'} size='sm' />
              ) : (
                'Save'
              )}
            </Button>
            <Button
              type='button'
              className='btn btn-secondary btn-sm m-1'
              onClick={() => {
                hideModal();
              }}
            >
              Discard
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default observer(UpdateCountryForm);

