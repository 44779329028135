import { makeAutoObservable, runInAction } from 'mobx';
import {
  GetUserSiteListResponse,
  HarvestApiGraphList,
  HarvestSite,
  MatchedHarvestStation,
  MetHarvestStation,
  MetloadWithHarvestTrace
} from '../../components/tools/harvest_stations/utils/HarvestTypes';
import { fetchWithRefresh } from '../../utils/requests/RequestsHelpers';
import StationStore from '../stations/StationStore';
import { Station } from '../stations/StationTypes';
import { UnproccessableEntityError } from '../stations/errors/UnprocessableEntityError';
import UserStore from '../user/UserStore';
import { createHarvestStationRequest } from './HarvestStationUtils';

export default class HarvestStationsStore {
  userStore: UserStore;
  stationStore: StationStore;

  loading: boolean = false;
  matchedHarvestStations: MatchedHarvestStation[] = [];

  loadingSelectedStation: boolean = false;
  selectedStationIndex?: number;
  selectedStationGraphList?: HarvestApiGraphList;

  constructor(userStore: UserStore, stationStore: StationStore) {
    makeAutoObservable(this);

    // Stores
    this.userStore = userStore;
    this.stationStore = stationStore;
  }

  async loadHarvestStations() {
    runInAction(() => {
      this.loading = true;
    });

    let sites: { [key: string]: HarvestSite } = {};
    let stations: { [key: string]: MetHarvestStation } = {};

    await fetchWithRefresh(
      this.userStore,
      `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/harvest/api/get-user-site-list`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
        }
      }
    )
      .then((res) => {
        if (res?.ok) return res.json();
        else throw res?.statusText;
      })
      .then((data: GetUserSiteListResponse) => {
        if (data.result !== 'success')
          throw Error('Harvest get_user_site_list errror');

        data.sites.forEach((site) => {
          sites[site.site_id] = {
            ...site,
            primary_location: site.site_options.locations.find(
              (location) => location.primary_location === 1
            )
          };
        });
      });

    await fetchWithRefresh(
      this.userStore,
      `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/harvest/harvest-stations`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`
        }
      }
    )
      .then((res) => {
        if (res?.ok) return res.json();
        else throw res?.statusText;
      })
      .then((data) => {
        data.forEach((station: any) => {
          stations[station['id']] = station;
        });
      });

    const newMatched: Array<MatchedHarvestStation> = [];

    Object.values(stations).forEach((station) => {
      const matchedSite = sites[station['site_id']];
      if (matchedSite)
        newMatched.push({
          station: station,
          site: matchedSite
        });
      else
        newMatched.push({
          station: station
        });
    });

    Object.values(sites).forEach((site) => {
      const matchedStation = Object.values(stations).find((value) => {
        return value.site_id === Number(site['site_id']);
      });
      if (!matchedStation)
        newMatched.push({
          site: site
        });
    });

    runInAction(() => {
      this.matchedHarvestStations = newMatched;
      this.loading = false;
    });
  }

  findStationIndex(station: MatchedHarvestStation): number | undefined {
    const i = this.matchedHarvestStations.findIndex((s) => {
      if (station.site && s.site) {
        if (station.station && s.station) {
          return (
            station.site.site_id === s.site.site_id &&
            station.station.station_id === s.station.station_id
          );
        }
        return station.site.site_id === s.site.site_id;
      }
      if (station.station && s.station) {
        return station.station.station_id === s.station.station_id;
      }
    });

    return i > -1 ? i : undefined;
  }

  async fetchMetloadWithHarvestTrace(station?: MatchedHarvestStation) {
    this.loadingSelectedStation = true;

    if (station) {
      try {
        // Fetch the stations metload data if haven't already.
        const currentStation = station.station;
        if (currentStation && !currentStation.metloadsWithHarvestTraces) {
          const res = await fetchWithRefresh(
            this.userStore,
            `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/harvest/metloads/${station.station?.id}`,
            {
              headers: {
                Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`,
                Accept: 'application/json'
              }
            }
          );
          if (res?.ok) {
            const data = (await res.json()) as MetloadWithHarvestTrace[];
            const currentStation = station.station;
            if (currentStation && !currentStation.metloadsWithHarvestTraces) {
              runInAction(() => {
                currentStation.metloadsWithHarvestTraces = data;
              });
            }
          }
        }
      } catch (error: any) {
        if (process.env.NODE_ENV === 'development') console.error(error);
      }

      try {
        const res = await fetchWithRefresh(
          this.userStore,
          `${process.env.REACT_APP_METWATCH_API_URL}api/admin/weather-providers/harvest/api/get-graph-list?site_id=${station?.site?.site_id}`,
          {
            headers: {
              Authorization: `Bearer ${this.userStore.user?.userToken.jwt_bearer}`,
              Accept: 'application/json'
            }
          }
        );
        if (res?.ok) {
          runInAction(async () => {
            this.selectedStationGraphList = await res.json();
          });
        }
      } catch (error: any) {
        if (process.env.NODE_ENV === 'development') console.error(error);
        this.selectedStationGraphList = undefined;
      } finally {
        runInAction(() => {
          this.selectedStationIndex = station
            ? this.findStationIndex(station)
            : undefined;
          this.loadingSelectedStation = false;
        });
      }
    } else {
      this.selectedStationIndex = undefined;
      this.selectedStationGraphList = undefined;
      this.loadingSelectedStation = false;
    }
  }

  get selectedStation(): MatchedHarvestStation | undefined {
    return this.selectedStationIndex !== undefined &&
      this.selectedStationIndex > -1
      ? this.matchedHarvestStations[this.selectedStationIndex]
      : undefined;
  }

  updateStation(station: MatchedHarvestStation) {
    const i = this.findStationIndex(station);

    if (i !== undefined) {
      const newMatchedHarvestStations = [...this.matchedHarvestStations];
      newMatchedHarvestStations[i] = station;
      this.matchedHarvestStations = newMatchedHarvestStations;
    }
  }

  async createHarvestStation(station: Station & { harvest_site_id: number }) {
    return createHarvestStationRequest(this.userStore, {
      ...station,
      harvest_site_setup: 0
    })
      .then((response) => {
        if (response?.ok) return response.json();
        else if (response?.status === 422)
          throw new UnproccessableEntityError(
            response.status + response.statusText,
            response.json()
          );
        else throw new Error('Server error: ' + response?.statusText);
      })
      .then((data: MetHarvestStation) => {
        runInAction(() => {
          this.stationStore.addStation(data.station);
          const alreadyMatchedIndex = this.matchedHarvestStations.findIndex(
            (s) => {
              return (
                s.site?.site_id.toString() ===
                  station.harvest_site_id.toString() &&
                s.station?.station_id === station.Station_ID
              );
            }
          );
          if (alreadyMatchedIndex > -1) {
            const newMatchedHarvestStations = [...this.matchedHarvestStations];
            newMatchedHarvestStations.push({
              site: this.matchedHarvestStations[alreadyMatchedIndex].site,
              station: data
            });
            this.matchedHarvestStations = newMatchedHarvestStations;
          } else {
            const firstMatchIndex = this.matchedHarvestStations.findIndex(
              (s) => {
                return (
                  s.site?.site_id.toString() ===
                  station.harvest_site_id.toString()
                );
              }
            );
            const matchedHarvestStation = this.matchedHarvestStations[
              firstMatchIndex
            ];
            if (matchedHarvestStation) {
              matchedHarvestStation.station = data;
              const newMatchedHarvestStations = [
                ...this.matchedHarvestStations
              ];
              newMatchedHarvestStations[
                firstMatchIndex
              ] = matchedHarvestStation;
              this.matchedHarvestStations = newMatchedHarvestStations;
            }
          }
        });
      });
  }
}

