import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { HarvestStationsContext } from '../../../../store/StoreContexts';
import { Col, Row } from 'react-bootstrap';
import HarvestTracesTable from './HarvestTracesTable';
import MetloadsTable from './MetloadsTable';

const HarvestStationManager = () => {
  const harvestStationsStore = useContext(HarvestStationsContext);

  return harvestStationsStore.selectedStation?.site &&
    harvestStationsStore.selectedStation?.station ? (
    <>
      <Row className='mt-3'>
        {harvestStationsStore.selectedStationGraphList ? (
          <>
            <Col xs={3}>
              <h4>Traces</h4>
              <HarvestTracesTable
                station={harvestStationsStore.selectedStation}
                graphList={harvestStationsStore.selectedStationGraphList}
              />
            </Col>
            <Col xs={9}>
              <h4>Measurements</h4>
              <MetloadsTable />
            </Col>
          </>
        ) : null}
      </Row>
    </>
  ) : (
    <>Invalid Station</>
  );
};

export default observer(HarvestStationManager);
