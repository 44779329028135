import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import Select, { GroupBase, Props } from 'react-select';
import { MetloadContext } from '../../../store/StoreContexts';

export type Option = {
  label: string;
  value: string;
};

const VariableSelect = (props: Props<Option>) => {
  const metloadStore = useContext(MetloadContext);
  const options: GroupBase<Option>[] = useMemo(() => {
    const variables = [...metloadStore.variables];
    const groups: GroupBase<Option>[] = [];
    let group: GroupBase<Option> | null = null;

    variables
      .sort((a, b) => {
        if (a.category.toUpperCase() < b.category.toUpperCase()) {
          return -1;
        }

        if (a.category.toUpperCase() > b.category.toUpperCase()) {
          return 1;
        }

        return 0;
      })
      .forEach((variable) => {
        // First || changed category
        if (group === null || group.label !== variable.category) {
          group = {
            label: variable.category,
            options: []
          };
          groups.push(group);
        }

        // Add varaible to the category group
        (group.options as Option[]).push({
          label: `${variable.Meteo_Var_Menu} (${variable.Units})`,
          value: variable.Meteo_Var_ID
        });
      });

    return groups;
  }, [metloadStore.variables]);

  return (
    <Select options={options} isLoading={metloadStore.loading} {...props} />
  );
};

export default observer(VariableSelect);

