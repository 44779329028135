import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

/**
 * Displays a green Font Awesome check for true, and a red Font Awesome cross
 * for false values.
 * @param props Boolean value to be displayed.
 */
export default function YesNoCheckIcon(props: { value: boolean }) {
  return (
    <FontAwesomeIcon
      icon={props.value ? faCheckCircle : faTimesCircle}
      color={props.value ? 'green' : 'red'}
    />
  );
}
