import React from 'react';
import { Card } from 'react-bootstrap';
import { WeatherStationDetailsCardProps } from '../WeatherStationTypes';
import StationNetworksDetails from './StationNetworksDetails';

/**
 * This component wraps the StationNetworksDetails component. This provides the
 * outer Card component and headers, as well as functionality for interacting
 * with the WeatherStationManager page.
 *
 * @param props Props for StationNetworksDetails and editing state from manager.
 */
export default function StationNetworksDetailsCard(
  props: WeatherStationDetailsCardProps
) {
  return (
    <Card className='m-2'>
      <Card.Header>
        <span>Networks</span>
        <button
          type='button'
          disabled
          className='btn btn-primary btn-sm'
          style={{ float: 'right', margin: -3 }}
          onClick={() => props.setEditMode(!props.editMode)}
        >
          Edit
        </button>
      </Card.Header>
      <StationNetworksDetails station={props.wsdProps.station} />
    </Card>
  );
}

