import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { UserAuthToken, UserDetails } from './UserTypes';

/**
 * Authenticates with the authentication server. Returns a Promise containing a
 * UserAuthToken object (JWT bearer and refresh tokens).
 *
 * @param email User email for authentication.
 * @param password User password for authentication.
 */
export async function authenticate(
  email: string,
  password: string
): Promise<UserAuthToken> {
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL + 'api/token/jwt/generate',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        service: 'metwatch'
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    }
  )
    .then((response) => {
      if (response.ok) return response.json();
      else throw Error(response.statusText);
    })
    .then((data) => {
      return {
        valid: true,
        jwt_bearer: data.jwt_token,
        jwt_refresh: data.refresh_token
      };
    });
}

/**
 * Gets the current authenticated user from the authentication server. Returns
 * a promise containing the user data.
 * @param token UserAuthToken object for auth server.
 */
export async function getUser(token: UserAuthToken): Promise<UserDetails> {
  return await fetch(process.env.REACT_APP_AUTH_API_URL + 'api/user', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.jwt_bearer
    }
  }).then((response) => {
    if (response.ok) return response.json();
    else throw Error(response.statusText);
  });
}

/**
 * Acquires a new JWT with the refresh token stored in cookies. Throws an error
 * if refresh unsuccessful.
 */
export async function refreshToken(): Promise<UserAuthToken> {
  const jwt_refresh_param = 'refresh_token=' + Cookies.get('jwt-refresh');
  return await fetch(
    process.env.REACT_APP_AUTH_API_URL +
    'api/token/jwt/refresh?' +
    jwt_refresh_param,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        service: 'metwatch'
      }
    }
  )
    .then((response) => {
      if (response.ok) return response.json();
      else throw Error(response.statusText);
    })
    .then((data) => {
      return {
        valid: true,
        jwt_bearer: data.jwt_token,
        jwt_refresh: data.refresh_token
      };
    });
}

/**
 * Attempts to determine whether or not the owner of the token provided has
 * administrator privileges. Returns true if user is admin, returns false
 * otherwise (including the case of a malformed token).
 * @param token UserAuthToken object.
 */
export function isUserAdmin(token: UserAuthToken): boolean {
  const bearer: any = jwtDecode(token.jwt_bearer!);
  try {
    return bearer['access']['authentication']['routes']['admin'];
  } catch {
    if (process.env.NODE_ENV === 'development')
      console.log('No admin field in JWT.');
    return false;
  }
}
