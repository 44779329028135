import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';

/**
 * Displays a single station detail key value pair inline.
 * @param props Title and content string.
 */
export function DetailItem(props: DetailItemProps) {
  return (
    <ListGroup.Item>
      <Row className='detailItem'>
        <Col className='title' sm={props.titleWidth}>{props.title}</Col>
        <Col className='content' sm={props.contentWidth}>{props.content}</Col>
      </Row>
    </ListGroup.Item>
  );
}

/**
 * Defines the props to be passed into the StationDetailItem component.
 * This component takes a pair of strings as title and content and displays
 * them inline.
 */
export interface DetailItemProps {
  title: string | JSX.Element;
  content?: React.ReactNode;
  titleWidth?: number;
  contentWidth?: number;
}
