import React from 'react';

interface Props {
  name: string;
  unit: string;
}

const SensorsRow = ({ name, unit }: Props) => {
  return (
    <tr style={{ position: 'relative' }}>
      <td>
        {name} ({unit})
      </td>
    </tr>
  );
};

export default SensorsRow;

