import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import React, {
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import {
  MetloadContext,
  MetrisStationsContext
} from '../../../store/StoreContexts';
import StationsMap from '../../maps/StationsMap';
import CenterSpinner from '../../utils/CenterSpinner';
import LinkMetrisNetworkForm from './LinkMetrisNetworkForm/LinkMetrisNetworkForm';
import MetrisStationManager from './MetrisStationManager/MetrisStationManager';
import MetrisStationsTable from './MetrisStationTable/MetrisStationsTable';

const MetrisStationsPage = () => {
  const metrisStore = useContext(MetrisStationsContext);
  const metloadStore = useContext(MetloadContext);
  const stationStore = metrisStore.stationStore;
  const regionStore = stationStore.regionStore;

  const mapStations = (metrisStore.selectedStation?.network
    ? [metrisStore.selectedStation.network]
    : metrisStore.metrisStations
  ).map((ms) => {
    const station = ms.station;
    return {
      Station_ID: station.Station_ID,
      Logger_Name: station.Logger_Name,
      Longitude: Number(station.Longitude),
      Latitude: Number(station.Latitude),
      Logger_Type: 'Metris'
    };
  });

  useEffect(() => {
    const init = async () => {
      if (metrisStore.stationStore.stations.length === 0) {
        await metrisStore.stationStore.requestStations();
      }
      await Promise.all([
        await metloadStore.initializeData(),
        await regionStore.fetchCountries()
      ]);
    };
    init();
    // De-select station when the page is un-mounted.
    return () => {
      metrisStore.deSelectStation();
    };
  }, [metrisStore, metloadStore, regionStore]);

  useEffect(() => {
    let ignore = false;
    // Fetch metris stations.
    if (!ignore) {
      metrisStore
        .loadStations()
        .then(() => metrisStore.loadAPIStations())
        .finally(() => metrisStore.getMatchedMetrisStations());
    }
    return () => {
      ignore = true;
    };
  }, [metrisStore]);

  return metrisStore.stationStore.loading ||
    metloadStore.loading ||
    metrisStore.loadingInitial ? (
    <CenterSpinner />
  ) : (
    <Container>
      <Row className='mt-2'>
        <Col>
          <Row>
            <Col>
              <Card className='my-3 w-100 rounded-0'>
                <MetrisStationsTable />
              </Card>
            </Col>
          </Row>
          {metrisStore.selectedStation ? (
            metrisStore.selectedStation.network &&
            metrisStore.selectedStation.station ? (
              <Row>
                <Col>
                  <MetrisStationManager />
                </Col>
              </Row>
            ) : (
              <Modal
                show={metrisStore.selectedStation ? true : false}
                onHide={() => {
                  metrisStore.deSelectStation();
                }}
              >
                <Modal.Header>Link Network</Modal.Header>
                <Modal.Body>
                  <LinkMetrisNetworkForm />
                </Modal.Body>
              </Modal>
            )
          ) : null}
          <Row className='mt-4'>
            <Col>
              {metrisStore.loadingMetrisStations ? (
                <div className='text-center'>
                  <Spinner animation='border' variant='primary' />
                </div>
              ) : (
                <StationsMap
                  stations={mapStations}
                  loading={false}
                  initialLocationState={
                    mapStations.length > 0
                      ? {
                          latitude: mapStations[0].Latitude,
                          longitude: mapStations[0].Longitude
                        }
                      : undefined
                  }
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(MetrisStationsPage);

