import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { WinetStationsContext } from '../../../../store/StoreContexts';
import SensorsRow from './SensorsRow';

const SensorsTable = () => {
  const winetStore = useContext(WinetStationsContext);
  const station = winetStore.selectedStation;
  const sensors = winetStore.selectedStationSensors;

  const hasData = () => {
    return station && sensors && station.station && station.network;
  };

  return hasData() ? (
    <Table size='sm' bordered striped hover>
      <thead>
        <tr>
          <th style={{ fontWeight: 'bold' }}>Name</th>
        </tr>
      </thead>
      <tbody>
        {sensors?.map((v) => {
          const name = v.displayName;
          const unit = v.sensorUM;
          return <SensorsRow key={name + unit} name={name} unit={unit} />;
        })}
      </tbody>
    </Table>
  ) : null;
};

export default observer(SensorsTable);

