import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { AllUsersContext } from '../../../store/StoreContexts';
import { UsersTable } from './UsersTable';
import { toJS } from 'mobx';
import TenantSelect from '../tenants/TenantSelect';
import TenantUserDetails from '../tenants/TenantUserDetails';
import { useQuery } from '../../../utils/requests/Query';
import EditUser from '../tenants/EditUser';



const UserManager: React.FC = () => {
  const allUsersStore = useContext(AllUsersContext);
  const [editMode, setEditMode] = useState(false);

  const location = useLocation();
  const tenantName = new URLSearchParams(location.search).get('tenant');

  const query = useQuery();

  useEffect(() => {
    allUsersStore.setCurrentUser(
      query.get('id') !== null ? (parseInt(query.get('id')!)) : undefined,
      query.get('tenant') !== null ? (query.get('tenant') as string) : undefined
    );
  }, [query]);

  return (
    <>
      <div className='page-title pb-0'>User Manager</div>
      <br></br>
      <TenantSelect />
      <Button
        onClick={() => console.log('Click!')}
        className='float-right mb-2 m-1 btn-sm'
      >
        Add User
      </Button>
      {tenantName === null ? null : (
        <Card className='tableContainer pageItem m-2'>
          <UsersTable
            data={toJS(allUsersStore.allUsers.filter(user => user.service === tenantName))}
            loading={allUsersStore.loading}
            userDetails={allUsersStore.current}
          />
        </Card>
      )}
      <Row>
        {allUsersStore.current ? (
           editMode ? (
            <Col className='col-12 col-md-4'>
              <EditUser
                userDetails={allUsersStore.current}
                setEditMode={setEditMode}
              />
            </Col>
          ) : (
            <Col className='col-12 col-md-8'>
              <TenantUserDetails
                userInfo={allUsersStore.current}
                setEditMode={setEditMode}
              />
            </Col>
          )
        ) : (
          <Col>
            <Card>
              <Card.Header>User Details</Card.Header>
              <Card.Body>Please select user to view details...</Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default observer(UserManager);

