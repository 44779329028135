import { FormikValues } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * This component creates a form for modifying subscriptions.
 * @param props Formikbag
 */
const EditAccessArrayForm: React.FC<FormikValues> = (props) => {
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors
  } = props;

  return (
    <>
      <Form>
        {/* Editable Fields */}
        <Form.Group controlId='id'>
          <Form.Label>Access ID</Form.Label>
          <Form.Control
            disabled
            type='text'
            name='id'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.id}
            isInvalid={touched.id && errors.id}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.id}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            name='name'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            isInvalid={touched.name && errors.name}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='description'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as='textarea'
            style={{ height: '100px' }}
            name='description'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            isInvalid={touched.description && errors.description}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.description}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='mw_access_id'>
          <Form.Label>MW Access ID</Form.Label>
          <Form.Control
            type='text'
            name='mw_access_id'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mw_access_id}
            isInvalid={touched.mw_access_id && errors.mw_access_id}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.mw_access_id}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='mdr_access_id'>
          <Form.Label>MDR Access ID</Form.Label>
          <Form.Control
            type='text'
            name='mdr_access_id'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mdr_access_id}
            isInvalid={touched.mdr_access_id && errors.mdr_access_id}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.mdr_access_id}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};

export default EditAccessArrayForm;
