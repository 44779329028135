import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { StationContext } from '../../../store/StoreContexts';
import { Country } from '../../../store/regions/RegionsTypes';
import { useQuery } from '../../../utils/requests/Query';
import Loading from '../../layout/Loading';
import GlobalFilter from '../../utils/table/GlobalFilter';
import { TableRowEdit } from '../../utils/types';
import RegionsTable from './RegionsTable';
import DeleteCountryModal from './delete_country/DeleteCountryModal';
import UpdateCountryModal from './update_country/UpdateCountryModal';

type TableData = Array<Country & TableRowEdit>;

interface Props {
  countries: Country[];
}

const CountriesTable = ({ countries }: Props) => {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;
  const history = useHistory();
  const query = useQuery();
  const countryId = query.get('country');
  const queryCountry =
    countries.find((c) => c.id === (countryId ? Number(countryId) : null)) ||
    null;
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    queryCountry
  );

  const data: TableData = useMemo(
    () =>
      countries.map((country) => {
        return {
          ...country,
          update: <UpdateCountryModal country={country} />,
          delete: <DeleteCountryModal country={country} />
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(countries)]
  );

  // This defines the data to be displayed in the table. Header represents the
  // heading that will be displayed for a given column, while accessor defines
  // the field to access in the data object for the corresponding header.
  const columns = useMemo(
    () => [
      {
        Header: 'Country',
        accessor: 'name'
      },
      {
        Header: 'Code',
        accessor: 'code'
      },
      {
        disableSortBy: true,
        Header: '',
        accessor: 'update'
      },
      {
        disableSortBy: true,
        Header: '',
        accessor: 'delete'
      }
    ],
    []
  ) as any;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  return (
    <>
      <Card className='tableContainer pageItem mx-2 mt-1 pb-0 mb-0'>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {/* This div creates a scroll box for the table. */}
        <div
          style={{
            minHeight: '150px',
            maxHeight: '300px',
            overflowY: 'scroll',
            marginRight: 0
          }}
        >
          {data.length === 0 ? (
            <Loading />
          ) : (
            <Table {...getTableProps()} size='sm' striped hover>
              <thead className='tableHeader'>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header') + ' '}
                        {column.canSort ? (
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortDown} />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} />
                            )}
                          </span>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => {
                        setSelectedCountry(
                          regionStore.getCountry(row.original.id)
                        );
                        query.set('country', row.original.id.toString());
                        history.push({
                          search: query.toString()
                        });
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </Card>
      {selectedCountry ? (
        <div className='mt-1'>
          <RegionsTable regions={selectedCountry.regions} />
        </div>
      ) : null}
    </>
  );
};

export default observer(CountriesTable);

