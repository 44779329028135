import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { WinetStationsContext } from '../../../../store/StoreContexts';
import MetloadsTable from '../MetloadsTable/MetloadsTable';
import SensorsTable from '../SensorsTable/SensorsTable';

const WinetStationManager = () => {
  const winetStore = useContext(WinetStationsContext);
  const stationSensors = winetStore.selectedStationSensors;
  const stationHasSensors = stationSensors && stationSensors.length > 0;

  return winetStore.loadingStationSensors ? (
    <Row>
      <Col>
        <Spinner animation='border' variant='primary' />
      </Col>
    </Row>
  ) : winetStore.hasSelectedStation() &&
    winetStore.selectedStation?.network?.metloadWithSensors ? (
    <>
      <Row className='mt-3'>
        {stationHasSensors ? (
          <>
            <Col xs={3}>
              <h4>Sensors</h4>
              <SensorsTable />
            </Col>
            <Col xs={9}>
              <h4>Metloads</h4>
              <MetloadsTable />
            </Col>
          </>
        ) : null}
      </Row>
    </>
  ) : null;
};

export default observer(WinetStationManager);

