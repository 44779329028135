import { FormikValues } from 'formik';
import React, { useContext } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { AllUsersContext } from '../../../store/StoreContexts';

/**
 * This component creates a form for modifying subscriptions.
 * @param props Formikbag
 */
const EditTenantUserForm: React.FC<FormikValues> = (props) => {
  const { handleChange, handleBlur, values, touched, errors } = props;

  const allUsersStore = useContext(AllUsersContext);

  console.log(allUsersStore.current?.login_type);

  return (
    <>
      <Form>
        {allUsersStore.current?.login_type === 'emailpass' ? (
          <>
            <Row>
              <Col className='col-12 col-md-6'>
                <Form.Group controlId='id'>
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    disabled
                    type='text'
                    name='id'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.id}
                    isInvalid={touched.id && errors.id}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.id}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            {/* Editable Fields */}
            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='text'
                name='email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.stop_date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='login_count'>
              <Form.Label>Login Count</Form.Label>
              <Form.Control
                type='text'
                name='login_count'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.login_count}
                isInvalid={touched.login_count && errors.login_count}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.stop_date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='comments'>
              <Form.Label>User Comments</Form.Label>
              <Form.Control
                as='textarea'
                style={{ height: '100px' }}
                name='comments'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comments}
                isInvalid={touched.comments && errors.comments}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.comments}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        ) : (
          <>
            <Row>
              <Col className='col-12 col-md-6'>
                <Form.Group controlId='id'>
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    disabled
                    type='text'
                    name='id'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.id}
                    isInvalid={touched.id && errors.id}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.id}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <hr />
            {/* Editable Fields */}
            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='text'
                name='email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.stop_date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='comments'>
              <Form.Label>User Comments</Form.Label>
              <Form.Control
                as='textarea'
                style={{ height: '100px' }}
                name='comments'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comments}
                isInvalid={touched.comments && errors.comments}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.comments}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}
      </Form>
    </>
  );
};

export default EditTenantUserForm;

