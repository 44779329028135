import { DateTime } from 'luxon';
import * as Yup from 'yup';

export interface accessArray {
  access: Access;
  metwatch_access: MetWatchAccessArray;

  [propName: string]: any;
}

export interface Access {
  id: number;
  name: string;
  description: string;
  mw_access_id: number;
  mdr_access_id: number;
  created_at: Date;
  updated_at: Date;

  // Allows any other fields to be passed in without encapsulating in an object.
  [propName: string]: any;
}

export interface MetWatchAccessArray {
  id: number;
  name: String;
  description: String;
  forecast_hours: number;
  route_id: number;
  parent_id: number;
  networks_access?: Array<MetWatchNetwork>;
  providers_access?: Array<ForecastProviders>;
  routes_access?: MetWatchAccessRoutes;
  parent?: MetWatchAccessArray;


  // Allows any other fields to be passed in without encapsulating in an object.
  [propName: string]: any;
}

export interface MetWatchNetwork {
  network_id: number;
  label: String;
  description: String;
  customer?: String;
  client_id: number;
  created: DateTime;
  modified: DateTime;
  deleted?: DateTime;

  // Allows any other fields to be passed in without encapsulating in an object.
  [propName: string]: any;
}

export interface ForecastProviders {
  id: number;
  created_at: String;
  updated_at: String;
  name: String;
  // Allows any other fields to be passed in without encapsulating in an object.
  [propName: string]: any;
}

export interface MetWatchAccessRoutes {
  id: number;
  created_at: String;
  updated_at: String;
  name: String;
  description: String;
  route_array: JSON;

  // Allows any other fields to be passed in without encapsulating in an object.
  [propName: string]: any;
}

export const accessArraySchema = Yup.object().shape({
  id: Yup.number().required(),
  name: Yup.string().required(),
  description: Yup.string().required(),
  mw_access_id: Yup.number().required(),
  mdr_access_id: Yup.number().nullable()
})

export const metwatchAccessArraySchema = Yup.object().shape({
  id: Yup.number().required(),
  name: Yup.string().required(),
  description: Yup.string().required(),
  route_id: Yup.number().nullable(),
  parent_id: Yup.number().nullable()
})