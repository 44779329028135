import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { MetrisStationsContext } from '../../../../store/StoreContexts';
import {
  Station,
  StationValidationSchema
} from '../../../../store/stations/StationTypes';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import { getEmptyFormStation } from '../../stations/WeatherStationTypes';
import StationForm from '../../stations/forms/StationForm';

const LinkMetrisNetworkForm = () => {
  const metrisStore = useContext(MetrisStationsContext);
  const metrisStation = metrisStore.selectedStation;
  const metrisNodeId = metrisStation?.station.nodeId.toString();
  const metrisOwnerId = metrisStation?.station.ownerId.toString();

  // Sorts the metris stations by number after M prefix (e.g. C21 > C20)
  const sortMetrisStation = (a: Station, b: Station) => {
    return parseInt(b.Station_ID.substring(1, 3)) <
      parseInt(a.Station_ID.substring(1, 3))
      ? a
      : b;
  };

  // Calculates a suggested ID for the metris station by increasing the value of the last metris
  // station ID by one.
  const suggestedId = useMemo(() => {
    const providerLetter = 'C';
    const metrisStations = metrisStore.stationStore.stations
      .slice()
      .filter((s) => /C[0-9][0-9]/.test(s.Station_ID));
    // Handle case when there are no Metris Stations.
    if (metrisStations.length === 0) return 'C';
    //
    const lastStation = metrisStations.reduce(sortMetrisStation);
    const isSingleDigit = lastStation?.Station_ID.substring(1, 2) === '0';
    const lastStationNumber = isSingleDigit
      ? lastStation?.Station_ID.substring(2, 3)
      : lastStation?.Station_ID.substring(1, 2);
    const last = parseInt(lastStationNumber) + 1;
    // Returns the next suggested Metris station ID if there are some left, otherwise an empty string.
    if (last >= 99) return '';
    if (last < 10) return `${providerLetter}0${last}`;
    return `${providerLetter}${last}`;
  }, [metrisStore.stationStore.stations]);

  // There's a issue with setting this to type 'FormValues' so
  // is set to any.
  const initialValues: any = {
    ...getEmptyFormStation(),
    metris_node_id: metrisNodeId || '',
    metris_owner_id: metrisOwnerId || '',
    metris_site_setup: 0,
    Station_ID: suggestedId,
    Logger_Name: metrisStation!.station!.name,
    Logger_Type: 'Metris',
    Logger_Description: `Metris - ${metrisStation!.station!.name} - ${
      metrisStation?.station.nodeId
    }`,
    Latitude: metrisStation?.station.latitude,
    Longitude: metrisStation?.station.longitude,
    Altitude: 0,
    timezone: 'Pacific/Auckland', // Metris stations are in NZ.
    is_utc: 'N'
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={StationValidationSchema}
        onSubmit={async (values, { setFieldError }) => {
          // Await the create requests to ensure 422s are caught.
          try {
            await metrisStore.setupMetrisStation(values);
            metrisStore.deSelectStation();
            successToast(`Metris station successfully created.`);
          } catch (error: any) {
            // Check for unprocesable entity
            if (error.message.includes(422)) {
              error.errors.then((data: any) => {
                for (var err in data.errors) {
                  setFieldError(err, data.errors[err][0]);
                }
              });
              errorToast(`Error creating Metris station.`);
            } else throw error;
          }
        }}
      >
        {(formikProps) => (
          <>
            <StationForm
              formikProps={formikProps}
              removed={{ Active: true, Logger_Type: true, WMONumber: true }}
            />
            <Button
              onClick={() => {
                formikProps.handleSubmit();
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Formik>
    </>
  );
};

export default observer(LinkMetrisNetworkForm);

