import { makeAutoObservable, runInAction } from 'mobx';
import { getSubscription } from '../reports/MdrUtils';
import UserStore from '../user/UserStore';
import { User, UserDetails, userJwt } from '../user/UserTypes';
import { getAllUsers } from './AllUsersUtils';
import { updateUser, getUserJwt, getUserIndustryJwt } from '../tenant/TenantUsersUtils';
import jwt_decode from 'jwt-decode';


/**
 * AllUsersStore contains all users in the authentication API. This will have
 * all users regardless of the currently authenticated administrator user's
 * tenant.
 */
export class AllUsersStore {
  user: UserStore;
  allUsers: UserDetails[];

  loading: boolean = false;
  current: UserDetails | undefined;

  userJwt: userJwt | undefined;
  jwt: string | undefined;
  aud: string | undefined;
  tenant: string | undefined;
  login_type: string | undefined;

  constructor(userStore: UserStore) {
    makeAutoObservable(this);
    this.user = userStore;
    this.allUsers = [];
    this.current = undefined;
    this.userJwt = undefined;
    this.jwt = undefined;
    this.aud = undefined;
    this.tenant = undefined;
    this.login_type = undefined;
  }


  /**
   * @returns all users regardless of tenant
   */
  async requestUsers() {
    if (this.user.userState !== 'loggedIn') return;
    if (this.allUsers.length > 0) return;

    const findIndustryId = (user: any) => {

      if (user.nzap_id !== undefined) {
        this.login_type = "industryjwt"
        return user.nzap_id;
      } else if (user.onions_id !== undefined) {
        this.login_type = "industryjwt"
        return user.onions_id;
      } else if (user.ppi_id !== undefined) {
        this.login_type = "industryjwt"
        return user.ppi_id;
      } else if (user.summer_id !== undefined) {
        this.login_type = "industryjwt"
        return user.summer_id;
      } else if (user.user_id !== undefined) {
        this.login_type = "industryjwt"
        return user.user_id;
      } else if (user.far_id !== undefined) {
        this.login_type = "industryjwt"
        return user.far_id;
      } else if (user.zespri_id !== undefined) {
        this.login_type = "industryjwt"
        return user.zespri_id;
      } else if (user.integrape_id !== undefined) {
        this.login_type = "industryjwt"
        return user.integrape_id;
      } else if (user.nzwine_id !== undefined) {
        this.login_type = "industryjwt"
        return user.nzwine_id;
      } else {
        this.login_type = "emailpass"
        return '-';
      }
    }

    runInAction(() => (this.loading = true));
    await getAllUsers(this.user).then((data) => {
      runInAction(() => {
        this.loading = false
        Object.keys(data).forEach((service) => {
          data[service].forEach((user) => {
            this.allUsers.push({
              ...user,
              service: service,
              client_id: user.id ? user.id : user.client_id,
              industry_id: findIndustryId(user),
              login_type: this.login_type!
            });
          });
        });
      });
    });
  }

  /**
   * Finds a user across all tenants. Tenant must be provided, as ID alone is
   * not enough to ensure uniqueness (may be duplicate IDs across tenants).
   * @param id User ID
   * @param tenant User tenant
   */
  findUser(id: number, tenant: string | undefined): UserDetails | undefined {
    return this.allUsers.find(
      (user) => user.client_id === id && user.service === tenant
    );
  }

  /**
   * Sets the currently selected user for user manager
   * @param id id of the user
   * @param tenant tenant string of the user
   */
  setCurrentUser(id: number | undefined, tenant: string | undefined) {
    runInAction(() => {
      this.current = id
        ? this.findUser(id, tenant)
        : undefined;
    });
  }

  /**
   * Allows a user to be edited, taking the updates from the user edit card
   * and sends those changes to the auth api
   * @param id id to find user to update
   * @param user user object of the user to update
   * @param tenant tenant string of the user to update
   */
  async updateUser(
    id: number,
    user: UserDetails,
    tenant: string
  ) {
    await updateUser(
      this.user.user!.userToken,
      tenant,
      user
    ).then((data) => {
      const index = this.allUsers.findIndex((sub) => sub.id === id);
      runInAction(() => {
        this.allUsers[index] = {
          ...this.allUsers[index],
          ...data
        };
        this.current = this.allUsers[index];
      });
    });
  }

  /** 
   * Runs the two requests neccessary to get the jwt token for authenticating a login as a particular user
   * 
   * @param tenant Industry tenant of the user
   * @param id Internal Hortplus user id - not industry id
   * @returns jwt token for authenticating a login as that user
   */
  async requestLogInAsUser(tenant: string, id: number | undefined) {
    if (this.user.userState !== 'loggedIn') return;
    var token = undefined;

    await getUserJwt(this.user, tenant, id).then((data) => {
      runInAction(() => {
        this.jwt = data.jwt_token;
        this.userJwt = jwt_decode(data.jwt_token);
        this.aud = this.userJwt?.aud;
        this.tenant = this.userJwt?.login_type?.split('_')[0];
      });
    });

    await getUserIndustryJwt(this.jwt, tenant).then((data) => {
      runInAction(() => {
        token = data.jwt_token;
      });
    });
    return token;
  }
}

