import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../store/StoreContexts';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

require('./login.scss');

/**
 * Login page. This page is not routed to, instead, it is loaded from the Route
 * component when the user attempts to access a restricted page without logging
 * in.
 */
function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const store = useContext(UserContext);

  return (
    <div className='login-page'>
      <div className='page-title'>Login</div>

      <Form
        className='login'
        onSubmit={(event) => {
          event.preventDefault();
          store.login(email, password, remember);
        }}
      >
        <Form.Group controlId='email-input'>
          <Form.Label>Email Address</Form.Label>
          <input
            type='email'
            placeholder='Email'
            id='email-input'
            className='form-control'
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId='password-input'>
          <Form.Label>Password</Form.Label>
          <input
            placeholder='Password'
            type='password'
            id='password-input'
            className='form-control'
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId='form-check'>
          <Form.Check
            type='checkbox'
            label='Remember me'
            onClick={() => setRemember(!remember)}
          />
        </Form.Group>
        <div className='button-group'>
          <button type='submit' className='btn btn-primary button'>
            Login
          </button>
        </div>
      </Form>
    </div>
  );
}

export default observer(Login);
