import { Formik } from 'formik';
import React from 'react';
import { Button, ButtonGroup, Card, Spinner } from 'react-bootstrap';
import { StationValidationSchema } from '../../../../store/stations/StationTypes';
import { UnproccessableEntityError } from '../../../../store/stations/errors/UnprocessableEntityError';
import { StationEditCardProps } from '../WeatherStationTypes';
import StationForm from './StationForm';

/**
 * This component wraps the StationForm component. This provides the
 * outer Card component and headers, as well as functionality for interacting
 * with the WeatherStationManager page.
 *
 * @param props Props for WeatherStationEdit and editing state from manager.
 */
export default function StationEditCard(props: StationEditCardProps) {
  return (
    <Card className='m-2'>
      <Formik
        enableReinitialize
        validateOnBlur
        validateOnMount
        validateOnChange
        initialValues={props.station}
        validationSchema={StationValidationSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setTimeout(() => {
            // onSubmit will return any 422 errors from API.
            props
              .onSubmit(values.Station_ID, values)
              .catch((error: UnproccessableEntityError) => {
                // Finally, deal with errors returned from API. These have priority
                // over client side validation.
                error.errors.then((data: any) => {
                  for (var err in data.errors) {
                    console.log(data.errors);
                    setFieldError(err, data.errors[err][0]);
                  }
                });
              });

            setSubmitting(false);
          }, 400);
        }}
      >
        {(formikProps) => (
          <>
            <Card.Header>
              <span>Edit Station</span>
              <ButtonGroup
                style={{ float: 'right', margin: -3 }}
                aria-label='Station edit controls.'
              >
                <Button
                  type='submit'
                  className='btn btn-primary btn-sm'
                  onClick={formikProps.submitForm}
                  disabled={formikProps.isSubmitting}
                >
                  {formikProps.isSubmitting ? (
                    <Spinner animation={'border'} size='sm' />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  onClick={props.onDiscard}
                >
                  Discard
                </Button>
              </ButtonGroup>
            </Card.Header>
            <Card.Body>
              <StationForm formikProps={formikProps}></StationForm>
            </Card.Body>
          </>
        )}
      </Formik>
    </Card>
  );
}

