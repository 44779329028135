import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import AddRegionForm from './AddRegionForm';

const AddRegionModal = () => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <>
      <Button className='float-right' size='sm' onClick={toggleShow}>
        <FontAwesomeIcon icon={faPlus} /> Add Region
      </Button>
      <Modal show={show} onHide={toggleShow}>
        <Modal.Header closeButton>
          <b>Add Region</b>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <AddRegionForm hideModal={() => setShow(false)} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default observer(AddRegionModal);

