import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { HilltopStationsContext } from '../../../../store/StoreContexts';
import {
  HilltopProvider,
  providerMap
} from '../../../../store/hilltop_stations/HilltopUtils';
import { useQuery } from '../../../../utils/requests/Query';

const ProviderSelect = () => {
  const hilltopStore = useContext(HilltopStationsContext);
  const [provider, setProvider] = useState<HilltopProvider | undefined>(
    hilltopStore.provider
  );
  const query = useQuery();
  const history = useHistory();

  const options: {
    value: HilltopProvider;
    label: string;
  }[] = Array.from(providerMap, ([value, label]) => ({ value, label }));

  const defaultValue = options.filter((option) => option.value === provider);

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Select
              placeholder='Select a Provider'
              defaultValue={defaultValue}
              options={options}
              onChange={(option) => {
                setProvider(option!.value);
              }}
            />
          </Col>
          <Col xs={2} sm={4} md={2} className='pl-0'>
            <Button
              onClick={(e) => {
                e.preventDefault();
                hilltopStore.setProvider = provider;
                if (provider) {
                  query.set('provider', provider);
                  history.push({
                    search: query.toString()
                  });
                }
              }}
            >
              Select
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default observer(ProviderSelect);

