import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import Select from 'react-select';
import { AccessArrayContext } from '../../../../store/StoreContexts';
import { NetworkSelectorProps } from '../AccessArrayTypes';

/**
 * Network selector component. Sets value to the network ID and displays network
 * name and ID in dropdown.
 */
const NetworkSelector: React.FC<NetworkSelectorProps> = (props) => {
  const { setFieldValue, handleBlur } = useFormikContext();
  const accessStore = useContext(AccessArrayContext);

  const options = accessStore.userAccessArray?.metwatch_access.networks_access!.map(
    (networks: { network_id: any; label: any }) => {
      return {
        value: networks.network_id,
        label: `${networks.label}(${networks.network_id})`
      };
    }
  );
  return (
    <Select
      name={props.name}
      options={options}
      isMulti
      onChange={(opt) => {
        // We cannot pass in the regular onChange handler from formik - instead
        // we must directly call setFieldValue on the appropriate field.
        setFieldValue(
          props.name,
          opt?.map((opt) => opt.value)
        );
      }}
      onBlur={handleBlur}
      value={options?.find((opt: { value: any }) => opt.value === props.value)}
      className={props.isInvalid ? 'is-invalid' : ''}
      styles={
        props.isInvalid
          ? {
              control: (provided, _) => ({
                ...provided,
                borderColor: '#dc3545'
              })
            }
          : {}
      }
    />
  );
};

export default observer(NetworkSelector);

