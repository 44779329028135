import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { MetrisStationsContext } from '../../../../store/StoreContexts';
import PointsRow from './PointsRow';

const PointsTable = () => {
  const metrisStore = useContext(MetrisStationsContext);
  const station = metrisStore.selectedStation;
  const points = metrisStore.selectedStationPoints;

  const hasData = () => {
    return station && points && station.station && station.network;
  };

  return hasData() ? (
    <Table size='sm' bordered striped hover>
      <thead>
        <tr>
          <th style={{ fontWeight: 'bold' }}>Name</th>
        </tr>
      </thead>
      <tbody>
        {points?.map((v) => {
          const name = v.name;
          const unit = v.units;
          return <PointsRow key={name + unit} name={name} unit={unit} />;
        })}
      </tbody>
    </Table>
  ) : null;
};

export default observer(PointsTable);

