import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

interface LoadingOverlayProps {
  hideOpacity?: boolean;
  color?: string;
  opacity?: number;
  style?: any;
}

const LoadingOverlay = (props: LoadingOverlayProps) => {
  const style: any = {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    ...props.style
  };

  if (!props.hideOpacity) {
    style.backgroundColor = props.color ? props.color : 'white';
    style.opacity = props.opacity ? props.opacity : 0.8;
  }

  return (
    <div style={style}>
      <Spinner animation='border' variant='primary' />
    </div>
  );
};

LoadingOverlay.propTypes = {
  hideOpacity: PropTypes.bool
};

export default LoadingOverlay;
