import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { StationContext } from '../../../../store/StoreContexts';
import { useQuery } from '../../../../utils/requests/Query';
import { errorToast, successToast } from '../../../utils/ToastContainer';
import TimezoneSelect from '../../../utils/selects/TimezoneSelect';

interface Props {
  hideModal: () => void;
}

const AddRegionForm = ({ hideModal }: Props) => {
  const stationStore = useContext(StationContext);
  const regionStore = stationStore.regionStore;
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const countryId = query.get('country');
  const countryRegions = regionStore.getCountry(Number(countryId))?.regions;

  return (
    <Formik
      initialValues={{
        country_id: regionStore.getCountry(Number(countryId))?.id || 0,
        name: '',
        timezone: '',
        display_order:
          countryRegions && countryRegions?.length > 0
            ? countryRegions[countryRegions.length - 1]?.display_order + 1
            : 1
      }}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          await regionStore.addRegion(values);
          successToast('Region added successfully.', 'add-region-success');
          hideModal();
        } catch (error) {
          console.error(error);
          errorToast('Failed to add region.', 'add-region-error');
        }
        setLoading(false);
      }}
    >
      {(fProps) => (
        <Form onSubmit={fProps.handleSubmit}>
          <Form.Group controlId='country'>
            <Form.Label>Country</Form.Label>
            <Select
              defaultValue={
                fProps.values.country_id
                  ? {
                      label: regionStore.getCountry(
                        fProps.values.country_id || -1
                      )?.name,
                      value: Number(fProps.values.country_id)
                    }
                  : null
              }
              options={regionStore.countries.map((c) => ({
                label: c.name,
                value: c.id
              }))}
              onChange={(option) => {
                fProps.setFieldValue('country_id', option ? option.value : '');
                const countryRegions = regionStore.countries.find(
                  (c) => c.id === option?.value
                )?.regions;
                if (!countryRegions) return;
                if (countryRegions.length === 0) {
                  fProps.setFieldValue('display_order', 1);
                } else {
                  fProps.setFieldValue(
                    'display_order',
                    countryRegions[countryRegions.length - 1].display_order + 1
                  );
                }
              }}
            />
          </Form.Group>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              name='name'
              onChange={fProps.handleChange}
              value={fProps.values.name}
              isInvalid={!!fProps.errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='timezone'>
            <Form.Label>Timezone</Form.Label>
            <p className='mb-0' style={{ fontSize: 12 }}>
              Use{' '}
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://askgeo.com/', '_blank');
                }}
              >
                this tool
              </a>{' '}
              to help identify the station's timezone.
            </p>
            <TimezoneSelect
              defaultValue={{
                label: fProps.values.timezone,
                value: fProps.values.timezone
              }}
              onChange={(option: any) => {
                fProps.setFieldValue('timezone', option ? option.value : '');
              }}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.timezone}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='display_order'>
            <Form.Label>Display Order</Form.Label>
            <p className='mb-0' style={{ fontSize: 12 }}>
              Automatically set when a country is selected.
            </p>
            <Form.Control
              type='number'
              min={1}
              step={1}
              name='display_order'
              onChange={fProps.handleChange}
              value={fProps.values.display_order}
              isInvalid={!!fProps.errors.display_order}
            />
            <Form.Control.Feedback type='invalid'>
              {fProps.errors.display_order}
            </Form.Control.Feedback>
          </Form.Group>
          <div className='d-flex justify-content-end'>
            <Button
              type='submit'
              className={
                Object.keys(fProps.errors).length > 0
                  ? 'btn-outline-danger btn-danger btn-sm m-1 disabled'
                  : 'btn-primary btn-sm m-1'
              }
            >
              {loading ? (
                <Spinner className='mx-1' animation={'border'} size='sm' />
              ) : (
                'Save'
              )}
            </Button>
            <Button
              type='button'
              className='btn btn-secondary btn-sm m-1'
              onClick={() => {
                hideModal();
              }}
            >
              Discard
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default observer(AddRegionForm);

